import React, { useEffect, useState } from 'react'

import { Filter, SearchIcon, SearchIconWhite } from '../../assets/images';

import { getAllPanelList, getPanelSettingDetailsById, GetPanelFilterData } from '../../services/configurationServices';

import { Pagination, Tab, Tabs } from 'react-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import AlertTemplateMessage from '../Configuration/AlertTemplate/AlertTemplateMessage';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import PartitionComponent from './PartitionComponent';
import { armPanelCommand, connectPanel, disarmPanelCommand, resetPanelCommand } from '../../services/alertAuthService';
import OutputComponent from './OutputComponent';
import Swal from 'sweetalert2';
import { SuccessToast } from '../../CustomHooks/SuccessToast';

const partitionArr = [
  {
    id: 1,
    text: "Partition One",
    isChecked: false,
  },
  {
    id: 2,
    text: "Partition Two",
    isChecked: false,
  },
  {
    id: 3,
    text: "Partition Three",
    isChecked: false,
  },
  {
    id: 4,
    text: "Partition Four",
    isChecked: false,
  },
  {
    id: 5,
    text: "Partition Five",
    isChecked: false,
  },
  {
    id: 6,
    text: "Partition Six",
    isChecked: false,
  },
  {
    id: 7,
    text: "Partition Seven",
    isChecked: false,
  },
  {
    id: 8,
    text: "Partition Eight",
    isChecked: false,
  },
  {
    id: 9,
    text: "Partition Nine",
    isChecked: false,
  },
  {
    id: 10,
    text: "Partition Ten",
    isChecked: false,
  }
]

const outputDeviceArr = [
  {
    id: 1,
    text: "Output One",
    isChecked: false,
  },
  {
    id: 2,
    text: "Output Two",
    isChecked: false,
  },
  {
    id: 3,
    text: "Output Three",
    isChecked: false,
  },
  {
    id: 4,
    text: "Output Four",
    isChecked: false,
  },
  {
    id: 5,
    text: "Output Five",
    isChecked: false,
  },
  {
    id: 6,
    text: "Output Six",
    isChecked: false,
  },
  {
    id: 7,
    text: "Output Seven",
    isChecked: false,
  },
  {
    id: 8,
    text: "Output Eight",
    isChecked: false,
  },
  {
    id: 9,
    text: "Output Nine",
    isChecked: false,
  },
  {
    id: 10,
    text: "Output Ten",
    isChecked: false,
  }
]

const OUTPUT = "OUTPUT";
const INPUT = "INPUT";

export default function PanelSetting({ isDarkTheme, onSearchChange }) {

  //region useState start
  const [panelList, setPanelList] = useState([])

  const [panelDevicekey, setPanelDeviceKey] = useState('inputdevice');
  const [inputDeviceKey, setInputDeviceKey] = useState('partitions');

  const [panelModalFilter, setPanelModalFilter] = useState(false);
  const [activeTab, setActiveTab] = useState("Panel");

  const [partitionData, setPartitionData] = useState(partitionArr);
  const [outputDeviceData, setOutputDeviceData] = useState(outputDeviceArr);
  const [partitionAll, setPartitionAll] = useState(false)
  const [ouputDeviceAll, setOutputDeviceAll] = useState(false)
  const [panelConnected, setPanelConnected] = useState(false);
  const [password, setPassword] = useState("");
  const [panelListSearch, setPanelListSearch] = useState("")
  const [nextClickOne, prevClickOne, pageCounterOne, currentPageNoOne, limitOne, totalPagesOne, setCurrentPageNoOne, setTotalPagesOne, maxPageAllowedOne, setPageCounterOne] = useDataAccessPagination(10, 1, 10, 1);
  const [timer, setTimer] = useState(null);
  const [panelSearchTimer, setPanelSearchTimer] = useState(null);
  const [customerCount, setCustomerCount] = useState(0);
  const [siteCount, setSiteCount] = useState(0);
  const [customerSearch, setCustomerSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [masterCustomerList, setmasterCustomerList] = useState([]);
  const [masterSiteList, setmasterSiteList] = useState([]);
  const [filtercustomerList, setFilterCustomerList] = useState([]);
  const [filterSiteList, setFilterSiteList] = useState([]);
  const [customerSelectAll, setCustomerSelectAll] = useState(false);
  const [siteSelectAll, setSiteSelectAll] = useState(false);
  const [panelfilterCount, setPanelFilterCount] = useState(0);
  const [panelFilterText, setPanelfilterText] = useState("");
  const [panelSetting, setPanelSetting] = useState(null);
  const [fullArmDisable, setFullArmDisable] = useState(false)
  const [fullDisArmDisable, setFullDisArmDisable] = useState(false)
  const [fullResetDisable, setFullResetDisable] = useState(false)
  const [partitionMessage, setPartitionMessage] = useState("Status");
  const [partitionStatus, setPartitionStatus] = useState(false);
  const [outputMessage, setOutputMessage] = useState("Status");
  const [outputStatus, setOutputStatus] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [allDisable, setAllDisable] = useState(false);

  //region useState end

  //region useEffect start

  useEffect(() => {
    console.log("filtercheck", panelModalFilter)
    console.log("filtercheck", panelfilterCount)
    if (panelModalFilter && panelfilterCount == 0) {
      GetTemplateDataToFilter("all", "")
      setCustomerSelectAll(false);
      setSiteSelectAll(false);
      setCustomerSearch("");
      setSiteSearch("");
    }
  }, [panelModalFilter]);

  useEffect(() => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getPanelList(1, panelListSearch, panelFilterText)
      setCurrentPageNoOne(1)
    }, 500)
    setTimer(newTimer);
  }, [panelListSearch]);

  useEffect(() => {
    getPanelList(currentPageNoOne, panelListSearch, panelFilterText)
  }, [currentPageNoOne]);

  useEffect(() => {
    getLocalIPAddress()
      .then(ip => {
        console.log("getLocalIP", ip)
      })
      .catch(error => console.log(`getLocalIP ${error}`));

  }, []);

  const getLocalIPAddress = async () => {
    const rtcConn = new RTCPeerConnection();
    rtcConn.createDataChannel('');
    const offer = await rtcConn.createOffer();
    await rtcConn.setLocalDescription(offer);

    return new Promise((resolve, reject) => {
      rtcConn.onicecandidate = (event) => {
        if (event.candidate) {
          const candidate = event.candidate.candidate;
          const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;
          const ipAddress = candidate.match(ipRegex);
          if (ipAddress) {
            resolve(ipAddress[0]);
            rtcConn.close();
          }
        } else {
          reject('No ICE candidates found');
        }
      };
    });
  };

  //region useEffect end

  //region functions start
  const selectItemHandler = (id, type) => {
    if (type == INPUT) {
      let temp = partitionData.map((item) => {
        let obj;
        if (item.id == id) {
          obj = {
            ...item,
            isChecked: !item.isChecked
          }
        } else {
          obj = {
            ...item
          }
        }
        return obj;
      })
      setPartitionData(temp);
    } else {
      let temp = outputDeviceData.map((item) => {
        let obj;
        if (item.id == id) {
          obj = {
            ...item,
            isChecked: !item.isChecked
          }
        } else {
          obj = {
            ...item
          }
        }
        return obj;
      })
      setOutputDeviceData(temp);
    }
  }

  const selectAllHandler = (type) => {
    if (type == INPUT) {
      setPartitionAll(!partitionAll)
      let temp = partitionData.map((item) => {
        let obj = {
          ...item,
          isChecked: !partitionAll
        }
        return obj
      })
      setPartitionData(temp);
    } else {
      setOutputDeviceAll(!ouputDeviceAll)
      let temp = outputDeviceData.map((item) => {
        let obj = {
          ...item,
          isChecked: !ouputDeviceAll
        }
        return obj
      })
      setOutputDeviceData(temp);
    }
  }

  const connectToPanel = () => {
    if (password == "") {
      ErrorToast("Please enter password!")
      return;
    }
    // Check the password with the password returned from the API to check if they are the same.
    if (password == panelSetting.CamPassword) {
      //Make a local api call to client component.

      // setPanelConnected(true);
      //       SuccessToast("Panel connected successfully.")

      setButtonDisable(true);

      connectPanel(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName)
        .then((res) => {
          console.log("connectPanel", res)
          if (res.res == "Connected") {
            setPanelConnected(true);
            SuccessToast("Panel connected successfully.")
            setButtonDisable(false);
            setPanelDeviceKey('inputdevice')
          } else {
            ErrorToast("Panel is not available.")
            setButtonDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast("Panel is not available.")
          setButtonDisable(false);
        })
    } else {
      ErrorToast("Password is incorrect");
      setButtonDisable(false);
    }
  }

  const disConnectPanel = () => {
    if (password == "") {
      ErrorToast("Please enter password!")
      return;
    }
    if (password == panelSetting.CamPassword) {
      setPanelConnected(false);
      SuccessToast("Panel disconnected successfully.")
      setPanelDeviceKey('inputdevice')
    }
  }

  const getPanelList = (pageNumber, searchText, filterText) => {
    try {
      getAllPanelList(pageNumber, searchText, filterText)
        .then((res) => {
          console.log("getAllPanelList", res);
          if (res.Status == "success") {
            if (res.Data.LstPanel.length > 0) {
              setPanelList(res.Data.LstPanel)
              setPageCounterOne(currentPageNoOne)
              setCurrentPageNoOne(currentPageNoOne)
              setTotalPagesOne(parseInt(res.Data.TotalLength))
              console.log("getAllPanelList",);
            }
            else if (searchText != "") {
              setPanelList([])
            } else {
              setPanelList(null)
            }
          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  const getPanelbyId = (deviceId) => {
    if (deviceId == panelSetting?.DeviceId) {
      return;
    }
    if (panelConnected) {
      Swal.fire({
        title: 'Operation in Progress.',
        text: "You have started the operation for a panel, do you want to discard any changes and continue to the other panel.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            getPanelSettingDetailsById(deviceId)
              .then((res) => {
                console.log("getPanelSettingDetailsById", res);
                if (res.Status == "success") {
                  console.log("getPanelSettingDetailsById",);
                  setPanelSetting(res.Data[0]);
                  setPassword("")
                  setPanelConnected(false)
                  setPartitionMessage("Status");
                  setOutputMessage("Status");
                  setOutputStatus(false);
                  setPartitionStatus(false);
                }
              })
          } catch (error) {
            console.log(error);
          }
        }
      })
    } else {
      try {
        getPanelSettingDetailsById(deviceId)
          .then((res) => {
            console.log("getPanelSettingDetailsById", res);
            if (res.Status == "success") {
              console.log("getPanelSettingDetailsById",);
              setPanelSetting(res.Data[0]);
              setPassword("")
              setPanelConnected(false)
              setPartitionMessage("Status");
              setOutputMessage("Status");
              setOutputStatus(false);
              setPartitionStatus(false);
            }
          })
      } catch (error) {
        console.log(error);
      }
    }
  }

  const customerIsChecked = (arr) => {
    const customer = arr.map((item, index) => {
      let obj = {
        ...item,
        isChecked: false
      }
      return obj;
    })
    setmasterCustomerList(customer);
    setFilterCustomerList(customer);
    setCustomerCount(customer.length);
  }

  const siteIsChecked = (arr) => {
    const site = arr.map((item, index) => {
      let obj = {
        ...item,
        isChecked: false
      }
      return obj;
    })
    setmasterSiteList(site);
    setFilterSiteList(site);
    setSiteCount(site.length)
  }

  const GetTemplateDataToFilter = (pType, pSelectedIds) => {
    try {
      console.log("checking", GetTemplateDataToFilter)

      GetPanelFilterData(pType, pSelectedIds).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            if (pType == "customer") {
            } else if (pType == "site") {

            } else {
              customerIsChecked(resp.Data.LstClient);
              siteIsChecked(resp.Data.LstSite);

            }
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const applyFilterAll = () => {
    let count = 0;
    let isCustomerChecked = masterCustomerList.some((item) => item.isChecked);
    let isSiteChecked = masterSiteList.some((item) => item.isChecked);
    if (isCustomerChecked) {
      count++;
    }
    if (isSiteChecked) {
      count++;
    }
    setPanelFilterCount(count);

    let customer = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
    let clientIds = customer.join(',');

    let site = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
    let siteIds = site.join(',');

    const fileterTextstring = `customer:${clientIds};site:${siteIds}`;
    setPanelfilterText(fileterTextstring);
    getPanelList(1, panelListSearch, fileterTextstring)
    setPanelModalFilter(false)
  }

  const handleCustomerSelectAll = () => {
    const tempArr = filtercustomerList.map((item, index) => {
      let obj = {
        ...item,
        isChecked: !customerSelectAll
      }
      return obj;
    })

    let customerOne = masterCustomerList.map((item) => {
      let objcustomer;
      if (customerSelectAll) {
        if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
          objcustomer = {
            ...item
          }
        } else {
          objcustomer = {
            ...item,
            isChecked: false
          }
        }
      } else {
        if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
          objcustomer = {
            ...item,
          }
        } else {
          objcustomer = {
            ...item,
            isChecked: true
          }
        }
      }
      return objcustomer;
    })

    setCustomerSelectAll(!customerSelectAll);
    setFilterCustomerList(tempArr);
    setmasterCustomerList(customerOne);
    let customerArr = customerOne.filter((item) => item.isChecked).map((i) => i.Item1);
    let clientIds = customerArr.join(',');
    if (customerArr.length != 0) {
      GetPanelFilterData("customer", clientIds).then((resp) => {
        if (resp.Status == "success") {
          siteIsChecked(resp.Data.LstSite);
          setSiteSelectAll(false);
        }
      })
    }

  };

  const customerCheckHandler = (e, id) => {
    let arr = filtercustomerList.map((item) => {
      let obj;
      if (item.Item1 == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })

    let masterCustomer = masterCustomerList.map((item) => {
      let obj;
      if (item.Item1 == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })

    let customerArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
    let clientIds = customerArr.join(',');
    if (customerArr.length != 0) {
      GetPanelFilterData("customer", clientIds).then((resp) => {
        if (resp.Status == "success") {
          siteIsChecked(resp.Data.LstSite);
          setSiteSelectAll(false);
        }
      })
    }
    else {
      GetPanelFilterData("all", "").then((resp) => {
        if (resp.Status == "success") {
          siteIsChecked(resp.Data.LstSite);
          setSiteSelectAll(false);
        }
      })
    }
    setmasterCustomerList(masterCustomer);
    setFilterCustomerList(arr);

  }

  const handleSiteSelectAll = () => {

    const tempArr = filterSiteList.map((item, index) => {
      let obj = {
        ...item,
        isChecked: !siteSelectAll
      }
      return obj;
    })
    let siteOne = masterSiteList.map((item) => {
      let objsite;
      if (siteSelectAll) {
        if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
          objsite = {
            ...item
          }
        } else {
          objsite = {
            ...item,
            isChecked: false
          }
        }
      } else {
        if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
          objsite = {
            ...item
          }
        } else {
          objsite = {
            ...item,
            isChecked: true
          }
        }
      }
      return objsite;
    })
    setSiteSelectAll(!siteSelectAll);
    setFilterSiteList(tempArr);
    setmasterSiteList(siteOne);
  };

  const siteCheckHandler = (e, id) => {
    let arr = filterSiteList.map((item) => {
      let obj;
      if (item.Item1 == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })
    let masterSite = masterSiteList.map((item) => {
      let obj;
      if (item.Item1 == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })
    setmasterSiteList(masterSite);
    setFilterSiteList(arr);
  }

  const filterClearAll = () => {
    GetTemplateDataToFilter("all", "")
    setCustomerSearch("")
    setSiteSearch("")
    setCustomerSelectAll(false);
    setSiteSelectAll(false);
    setPanelfilterText("");
    const tempcustomer = masterCustomerList.map((item, index) => {
      let obj = {
        ...item,
        isChecked: false
      }
      return obj;
    })
    setmasterCustomerList(tempcustomer);
    setFilterCustomerList(tempcustomer);

    const tempsite = masterSiteList.map((item, index) => {
      let obj = {
        ...item,
        isChecked: false
      }
      return obj;
    })
    setmasterSiteList(tempsite);
    setFilterSiteList(tempsite);
    setPanelFilterCount(0)

    getPanelList(1, panelListSearch, "")
  }

  const searchPanelFilterHandler = (e, type) => {
    let searchText = e.target.value;
    if (type == "Customer") {
      setCustomerSearch(searchText);
      let arr = masterCustomerList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
      let isChecked = true;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isChecked == true) {
          continue;
        } else {
          isChecked = false;
          break;
        }
      }
      setCustomerSelectAll(isChecked);
      setFilterCustomerList(arr);
      setCustomerCount(arr.length);
    } else if (type == "Site") {
      setSiteSearch(searchText);
      let arr = masterSiteList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
      let isChecked = true;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isChecked == true) {
          continue;
        } else {
          isChecked = false;
          break;
        }
      }
      setSiteSelectAll(isChecked);
      setFilterSiteList(arr);
      setSiteCount(arr.length);
    }
  }

  const PaginationComponentOne = (currentPageNo) => {
    let numbers = [];
    if (maxPageAllowedOne - 2 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1) || currentPageNo === (maxPageAllowedOne - 2))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowedOne - 2}
          active={maxPageAllowedOne - 2 == currentPageNo}
          onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 2)}
        >
          {maxPageAllowedOne - 2}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowedOne - 1}
          active={maxPageAllowedOne - 1 == currentPageNo}
          onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
        >
          {maxPageAllowedOne - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowedOne}
          active={maxPageAllowedOne == currentPageNo}
          onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
        >
          {maxPageAllowedOne}
        </Pagination.Item>)
    }
    else if (maxPageAllowedOne - 1 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowedOne - 1}
          active={maxPageAllowedOne - 1 == currentPageNo}
          onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
        >
          {maxPageAllowedOne - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowedOne}
          active={maxPageAllowedOne == currentPageNo}
          onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
        >
          {maxPageAllowedOne}
        </Pagination.Item>)
    }
    else {
      for (let i = currentPageNo; i < currentPageNo + 3; i++) {
        if (i <= maxPageAllowedOne) {
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNoOne(i)}
            >
              {i}
            </Pagination.Item>
          )
        }
      }
    }
    return numbers;
  }

  const fullArm = (panelSetting) => {
    setFullArmDisable(true);
    setAllDisable(true);
    setPartitionMessage("Full arm command sent")
    setPartitionStatus(true);
    armPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, null)
      .then((res) => {
        setFullArmDisable(false);
        setAllDisable(false);
        setPartitionMessage("Full arm command sent - Completed")
        // setPartitionStatus(true);
        console.log(res);
      })
      .catch(err => {
        setFullArmDisable(false);
        setAllDisable(false);
        setPartitionMessage("Full arm command sent - Completed")
        // setPartitionStatus(true);
        console.log(err);
      })
  }

  const fullDisArm = (panelSetting) => {
    setFullDisArmDisable(true);
    setAllDisable(true);
    setPartitionMessage("Full disarm command sent")
    setPartitionStatus(true);
    disarmPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, null)
      .then((res) => {
        console.log(res);
        setFullDisArmDisable(false);
        setAllDisable(false);
        setPartitionMessage("Full disarm command sent - Completed")
        // setPartitionStatus(true);
      }).catch(err => {
        console.log(err);
        setFullDisArmDisable(false);
        setAllDisable(false);
        setPartitionMessage("Full disarm command sent - Completed")
        // setPartitionStatus(true);
      })
  }

  const reset = (panelSetting) => {
    setFullResetDisable(true);
    setPartitionMessage("Reset command sent")
    setPartitionStatus(true);
    setAllDisable(true);
    resetPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, null)
      .then((res) => {
        console.log(res);
        setFullResetDisable(false);
        setAllDisable(false);
        setPartitionMessage("Reset command sent - Completed")
        // setPartitionStatus(true);
      })
      .catch(err => {
        console.log(err);
        setFullResetDisable(false);
        setAllDisable(false);
        setPartitionMessage("Reset command sent - Completed")
        // setPartitionStatus(true);
      })
  }

  const activateOutput = (id) => {

  }

  const deactivateOutput = (id) => {

  }

  //region functions end
  return (
    <>
      <div className='MainContentMargin'>
        <div
          className="row col-lg-12"
          style={{ margin: "0 auto", padding: "0px" }}
        >
          <div className="col-lg-12 dashboardHeadingInnerDiv">
            <div className="NameHeading1">
              <p>Panel Control</p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-2">
          <div className="SiteMainContainer">
            <div className="SiteLeftContainer">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="m-2 HeaderSiteList withFilterBox">
                  <input
                    id="Search_txt"
                    className="SearchViewInput1"
                    type="text"
                    value={panelListSearch}
                    onChange={(e) => setPanelListSearch(e.target.value)}

                    placeholder="Search Panel"
                  />
                  <div className="SearchTextDivConfigTool">
                    {isDarkTheme ? (
                      <img
                        className="EyeIcon"
                        src={SearchIcon}
                        alt="search icon"
                        onClick={() => {
                          getPanelList(currentPageNoOne, panelListSearch, panelFilterText)
                        }}
                      />
                    ) : (
                      <img
                        className="EyeIcon"
                        src={SearchIconWhite}
                        height="15"
                        alt="search icon"
                        onClick={() => {
                          getPanelList(currentPageNoOne, panelListSearch, panelFilterText)
                        }}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <button className="AlertFilter"
                    onClick={() => { setPanelModalFilter(true) }}
                  >
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>
                          filter
                        </Tooltip>
                      }
                    >
                      <>
                        <div className="badge badge-pill badge-danger" style={{ top: "2px", right: "4px", minWidth: "25px" }}>
                          <p className='filteractivetext'>
                            {panelfilterCount}
                          </p>
                        </div>
                        <img src={Filter} style={{ height: "15px", float: "left" }} className="camera-icon-height" alt='Alert filter icon' />
                      </>
                    </OverlayTrigger>
                  </button>
                </div>
              </div>

              <Modal
                size='lg'
                show={panelModalFilter}
                onHide={() => setPanelModalFilter(false)}
                className='panelFilterModalClass'
                backdrop="static"
              >
                <Modal.Header closeButton style={{ borderBottom: "none" }}>
                  <Modal.Title className='pb-0'>
                    {
                      <span>Filter {activeTab} List</span>
                    }
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className=''>
                    <div className=''>
                      <div className="grid-container filterContainer grid-container--fit mb-0 ">
                        <div className="NotificationTextBox mt-0">
                          <p className="SiteNameText mb-0">
                            Customer Name
                          </p>
                          <div className='filterinnerboxdiv'>
                            <div>
                              <div className="SelectionListWithSelectAll">
                                <input
                                  className="SearchViewInput1"
                                  type="search"
                                  placeholder="Search"
                                  value={customerSearch}
                                  onChange={(e) => searchPanelFilterHandler(e, "Customer")}
                                />
                                {
                                  isDarkTheme ?
                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                    <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                }
                              </div>
                            </div>
                            {
                              customerCount > 0
                                ?
                                <div className='dataAccessListItem mt-2'
                                >
                                  <input type="checkbox"
                                    checked={customerSelectAll}
                                    onChange={handleCustomerSelectAll}
                                  />
                                  <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                    onClick={handleCustomerSelectAll}
                                  >
                                    Select all <span>{customerCount}</span>
                                  </p>
                                </div>
                                : null
                            }
                            <div className='HgtFilterBoxList'>

                              {
                                filtercustomerList.map((item) => (
                                  <div key={item.Item1} className="dataAccessListItem"
                                  >
                                    <input
                                      checked={item.isChecked}
                                      type="checkbox"
                                      onChange={(e) => customerCheckHandler(e, item.Item1)}
                                    />
                                    <p className="AllTextwithValue"
                                      onClick={(e) => customerCheckHandler(e, item.Item1)}>
                                      {item.Item2}</p>
                                  </div>
                                ))
                              }
                            </div>
                          </div>


                        </div>
                        <div className="NotificationTextBox mt-0">
                          <p className="SiteNameText mb-0">
                            Site
                          </p>

                          <div className='filterinnerboxdiv'>
                            <div>
                              <div className="SelectionListWithSelectAll">
                                <input
                                  className="SearchViewInput1"
                                  type="search"
                                  placeholder="Search"
                                  value={siteSearch}
                                  onChange={(e) => searchPanelFilterHandler(e, "Site")}
                                />
                                {
                                  isDarkTheme ?
                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                    <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                }
                              </div>
                            </div>
                            {
                              siteCount > 0
                                ?
                                <div className='dataAccessListItem mt-2'>
                                  <input type="checkbox"
                                    checked={siteSelectAll}
                                    onChange={handleSiteSelectAll}
                                  />
                                  <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                    onClick={handleSiteSelectAll}
                                  >
                                    Select all <span>{siteCount}</span>
                                  </p>
                                </div>
                                : null
                            }
                            <div className='HgtFilterBoxList'>

                              {
                                filterSiteList.map((item) => (
                                  <div key={item.Item1} className="dataAccessListItem">
                                    <input checked={item.isChecked}
                                      type="checkbox"
                                      onChange={(e) => siteCheckHandler(e, item.Item1)}
                                    />
                                    <p className="AllTextwithValue"
                                      onClick={(e) => siteCheckHandler(e, item.Item1)}>
                                      {item.Item2}</p>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }} className='filterbuttonsdiv'>
                      <button className='DataAccessEdit' style={{ width: "100px" }}
                        onClick={applyFilterAll}
                      >
                        <span className='ViewUpdate'>Apply</span>
                      </button>

                      <button className='DataAccessEdit' style={{ width: "100px" }}
                        onClick={filterClearAll}
                      >
                        <span className='ViewUpdate'>Reset</span>

                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <div className="NotificationList PanelList">
                {
                  panelList &&
                  (panelList.length > 0 ? (
                    panelList.map((item) => {
                      return (
                        <div className={panelSetting?.DeviceId === item.DeviceId ? "OneList SelectedList" : "OneList"}
                          key={item.DeviceId}
                          onClick={(e) => {
                            getPanelbyId(item.DeviceId)
                          }}
                        >
                          <p className="ListofText">{item.CameraName}
                            <span className="SiteNameText"> -{item.SiteName}</span>
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data-found">
                      <p>No Panel Found.</p>
                    </div>
                  )
                  )
                }

                {!panelList && (
                  <div className="no-data-found">No Panel Available.</div>
                )}
              </div>
              {
                totalPagesOne === 0 || totalPagesOne <= limitOne
                  ? null
                  : <div className="PaginationDiv mb-2 mt-2 mr-2">
                    <h5 className='PageNumbers'>
                      {
                        totalPagesOne == 0 ? 0
                          :
                          (currentPageNoOne - 1) * parseInt(limitOne) + parseInt(1)} - {(currentPageNoOne * limitOne) > (totalPagesOne)
                            ? (totalPagesOne)
                            : currentPageNoOne * limitOne}
                    </h5>
                    <span>of</span>
                    <h5 className="PageNumbers">{totalPagesOne}</h5>
                    <Pagination>
                      <Pagination.Prev onClick={prevClickOne} disabled={currentPageNoOne == 1} />
                      {
                        PaginationComponentOne(currentPageNoOne)
                      }
                      <Pagination.Next onClick={nextClickOne} disabled={currentPageNoOne == maxPageAllowedOne} />
                    </Pagination>
                  </div>
              }
            </div>
            {
              panelSetting != null
                ?
                <div className="SiteRightContainer" style={{ padding: "15px" }}>
                  <div className='PanelHeading'><p>{panelSetting?.CameraName}</p></div>
                  <div className="PanelControlDiv">
                    <div>
                      <div className="PanelDetailMain">
                        <div className="PanelDetailDiv">
                          <p>Customer Name</p>
                          <p>{panelSetting?.ClientName}</p>
                        </div>
                        <div className="PanelDetailDiv">
                          <p>Site Name</p>
                          <p>{panelSetting?.SiteName}</p>
                        </div>
                        <div className="PanelDetailDiv">
                          <p>Branch Id</p>
                          <p>{panelSetting?.BranchId}</p>
                        </div>
                        <div className="PanelDetailDiv">
                          <p>Port</p>
                          <p>{panelSetting?.Port}</p>
                        </div>
                      </div>
                      <div className="PanelDetailMain mt-2">
                        <div className="PanelDetailDiv">
                          <p>Brand Name</p>
                          <p>{panelSetting?.BrandName}</p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                          <div className="inputMainContainer w40dot75rem">
                            <div className="inputTypeLabelContainer">
                              <label className='formFieldLabels'>IP Address</label>
                              <input
                                className="inputType"
                                placeholder="192.168.100.100"
                                disabled={true}
                                value={panelSetting?.PrivateIPAddress}
                              />
                            </div>
                          </div>
                          <div className="inputMainContainer w40dot75rem ml-2">
                            <div className="inputTypeLabelContainer">
                              <label className='formFieldLabels'>Password</label>
                              <input
                                className="inputType"
                                placeholder="********"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div>
                            <button className='DataAccessEdit connectButton ml-2' disabled={buttonDisable || allDisable} style={{ width: "100px" }} onClick={!panelConnected ? connectToPanel : disConnectPanel}>
                              {
                                panelConnected
                                  ?
                                  <span className='ViewUpdate'>Disconnect</span>
                                  :
                                  <span className='ViewUpdate'>Connect</span>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className='hoeizontalLine'></hr>
                    {
                      panelConnected
                        ?
                        <div>
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={panelDevicekey}
                            onSelect={(k) => setPanelDeviceKey(k)}
                          >
                            <Tab disabled={allDisable} eventKey="inputdevice" title="Input Devices" className='PanelDeviceTabBody'>
                              <div style={{ padding: "20px" }}>
                                <div>
                                  <button className='DataAccessEdit' disabled={allDisable} style={{ width: "100px" }} onClick={() => fullArm(panelSetting)}>
                                    <span className='ViewUpdate'>Full Arm</span>
                                  </button>

                                  <button className='DataAccessEdit' disabled={allDisable} style={{ width: "100px" }} onClick={() => fullDisArm(panelSetting)}>
                                    <span className='ViewUpdate'>Full DisArm</span>
                                  </button>

                                  <button className='DataAccessEdit' disabled={allDisable} style={{ width: "100px" }} onClick={() => reset(panelSetting)}>
                                    <span className='ViewUpdate'>Reset</span>
                                  </button>
                                </div>
                                <div className='mt-3'>
                                  <Tabs
                                    id="controlled-tab-example"
                                    activeKey={inputDeviceKey}
                                    onSelect={(k) => setInputDeviceKey(k)}
                                  >
                                    <Tab disabled={allDisable} eventKey="partitions" title="Partitions" className='InputDeviceSubTabsBody'>
                                      <div>
                                        {/* <label className='checkboxLabel'><input type="checkbox" checked={partitionAll} onChange={() => {
                                  selectAllHandler(INPUT)
                                }} />Select All Panels</label> */}
                                        <section class="main-section">
                                          {
                                            partitionData.map((item) => (
                                              <PartitionComponent allDisable={allDisable} setAllDisable={setAllDisable} item={item} key={item.id} panelSetting={panelSetting} setPartitionMessage={setPartitionMessage} setPartitionStatus={setPartitionStatus} />
                                            ))
                                          }
                                        </section>

                                        <div style={{ textAlign: "center", marginBottom: "5px" }}>
                                          {/* <button className='DataAccessEdit' style={{ width: "100px" }}>
                                            <span className='ViewUpdate'>Arm</span>
                                          </button>

                                          <button className='DataAccessEdit' style={{ width: "100px" }}>
                                            <span className='ViewUpdate'>DisArm</span>
                                          </button>

                                          <button className='DataAccessEdit' style={{ width: "100px" }}>
                                            <span className='ViewUpdate'>Reset</span>
                                          </button> */}
                                        </div>

                                        {
                                          partitionStatus
                                            ?
                                            <p className="Success_Message">
                                              <span>
                                                {partitionMessage}
                                              </span>
                                            </p>
                                            :
                                            <p className="Error_Message">
                                              <span>
                                                {partitionMessage}
                                              </span>
                                            </p>
                                        }

                                      </div>
                                    </Tab>
                                    {/* <Tab eventKey="zones" title="Zones" className='InputDeviceSubTabsBody'>
                            <div>
                            <label className='checkboxLabel'><input type="checkbox" />Select All Zones</label>
                            <section class="main-section">
                                <div class="zoneCheckboxs" style={{background: "#FF0000"}}>
                                  <input type="checkbox" id="zone1" />
                                  <label className='' for="zone1">Zone001</label>
                                </div>
                                <div class="zoneCheckboxs" style={{background: "#1CC606"}}>
                                  <input type="checkbox" id="zone2" />
                                  <label for="zone2">Zone002</label>
                                </div>
                                <div class="zoneCheckboxs" style={{background: "#9D9D9D"}}>
                                  <input type="checkbox" id="zone3" />
                                  <label for="zone3">Zone003</label>
                                </div>
                              </section>
                              <div style={{textAlign: "center"}}>
                                <button className='DataAccessEdit' style={{ width: "100px" }}>
                                  <span className='ViewUpdate'>Relay</span>
                                </button>
                              </div>
                            </div>
                            </Tab> */}
                                  </Tabs>
                                </div>
                              </div>
                            </Tab>
                            <Tab disabled={allDisable} eventKey="outputdevices" title="Output Devices" className='InputDeviceSubTabsBody PanelDeviceTabBody'>
                              <div style={{ paddingBottom: "10px" }}>
                                {/* <label className='checkboxLabel'><input type="checkbox" checked={ouputDeviceAll} onChange={() => selectAllHandler(OUTPUT)} />Select All Zones</label> */}
                                {/* <section class="outputdevices-section">
                                  <div class="card">
                                    <div className='cardCheckbox'>
                                      <input type="checkbox" />
                                    </div>
                                      <p style={{textAlign: "center"}}>Device One</p>
                                  </div>
                                  <div class="card">
                                    <div className='cardCheckbox'>
                                      <input type="checkbox" />
                                    </div>
                                      <p style={{textAlign: "center"}}>Device Two</p>
                                  </div>
                                  <div class="card">
                                    <div className='cardCheckbox'>
                                      <input type="checkbox" />
                                    </div>
                                      <p style={{textAlign: "center"}}>Device Three</p>
                                  </div>
                                  <div class="card">
                                    <div className='cardCheckbox'>
                                      <input type="checkbox" />
                                    </div>
                                      <p style={{textAlign: "center"}}>Device Four</p>
                                  </div>
                                  <div class="card">
                                    <div className='cardCheckbox'>
                                      <input type="checkbox" />
                                    </div>
                                      <p style={{textAlign: "center"}}>Device One</p>
                                  </div>
                                </section> */}
                                <section class="outputdevices-section">
                                  {
                                    outputDeviceData.map((item) => (
                                      <OutputComponent item={item} key={item.id} panelSetting={panelSetting} setOutputMessage={setOutputMessage} setOutputStatus={setOutputStatus} allDisable={allDisable} setAllDisable={setAllDisable} />
                                    ))
                                  }
                                </section>
                                <div style={{ textAlign: "center", marginBottom: "5px" }}>

                                  {/* <button className='DataAccessEdit' style={{ width: "100px" }}>
                                    <span className='ViewUpdate'>Siren On</span>
                                  </button>

                                  <button className='DataAccessEdit' style={{ width: "100px" }}>
                                    <span className='ViewUpdate'>Siren Off</span>
                                  </button> */}

                                </div>

                                {
                                  outputStatus
                                    ?
                                    <p className="Success_Message">
                                      <span>
                                        {outputMessage}
                                      </span>
                                    </p>
                                    :
                                    <p className="Error_Message">
                                      <span>
                                        {outputMessage}
                                      </span>
                                    </p>
                                }

                                {/* <p className="Success_Message"><span> Error </span></p> */}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                        :
                        <AlertTemplateMessage message={"Kindly enter the password and connect to the panel."} />
                    }
                  </div>
                </div>
                :
                <div className="SiteRightContainer" style={{ padding: "15px" }}>
                  <AlertTemplateMessage message={"Select a panel to control it."} />
                </ div>
            }

          </div>
        </div>
      </div>
    </>
  )
}
