import React, { useEffect, useState, useContext } from 'react'

import { Link, useHistory, useLocation } from "react-router-dom"
import Modal from "react-bootstrap/Modal";
import { dashboard, DashboardUnselected, HMUnSelected, HMSelected, MonitoringHubLogo, AlertPrePostSelected, AlertPrePostDefault, PanelControl, PanelControlWhite } from '../../assets/images'
import alertUnselected from '../../assets/images/sidebar-logo/alertUnselected.svg'
import alertSelected from '../../assets/images/sidebar-logo/alertSelected.svg'
import cameraSelected from '../../assets/images/sidebar-logo/camera-selected.svg'
import cameraUnselected from '../../assets/images/sidebar-logo/camera-unselected.svg'
import reportSelected from '../../assets/images/sidebar-logo/reports-selected.svg'
import reportUnselected from '../../assets/images/sidebar-logo/reports-unselected.svg'
import configSelected from '../../assets/images/sidebar-logo/config-selected.svg'
import configSelectedWhite from '../../assets/images/sidebar-logo/config-selected-white.svg'

import { DrawerContext } from "../../Context/DrawerContext";
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { CheckForPrivilege } from '../../pages/Profile/PrivilegeExtension'
import { CLIENT_COMPONENET_LINK, CLOUD_STREAMING, VERSION, IS_DASHBOARD_TAB_HIDDEN, IS_ALERTS_TAB_HIDDEN, IS_CAMERAS_TAB_HIDDEN, IS_HEALTH_MONITORING_TAB_HIDDEN, IS_REPORTS_TAB_HIDDEN, IS_PANELCONTROL_TAB_HIDDEN } from '../../config'
import { MenuMasterListAPI } from '../../services/dashboardService';
import { ABOUTTERMS, DASHBOARD, HOMEPAGE } from '../../navigation/CONSTANTS';


export default function Sidebar({
    selectedTab,
    setSelectedTab,
    selectedSubMenu,
    setSelectedSubMenu,
    isSuperUser,
    isDarkTheme,
}) {

    const location = useLocation()
    let history = useHistory()
    //useState Start Block
    const [displayModal, setDisplayModal] = useState(false);
    const [alertTab] = useState(CheckForPrivilege("ALERT"))
    const [camera] = useState(CheckForPrivilege("CAMERALIST_VIEW"))
    const [menuDataList, setMenuDataList] = useState(null)
    //useState End Block

    const { collapsed, setCollapsed } = useContext(DrawerContext);
    const { isNotificationAlertIdClicked, setIsNotificationAlertIdClicked } = useContext(ConversationStateContext)

    //useEffect Start Block
    useEffect(() => {
        const currentPathname = location.pathname;

        if (location.pathname === DASHBOARD || location.pathname === HOMEPAGE) {
            setSelectedTab('dashboard')
            getMenuMasterList()
            if (CLOUD_STREAMING == '0') {
                runRovarUtilUrl("rovarrutil://")
            }
        } else if (location.pathname === "/myalerts" || location.pathname === "/allalerts") {
            setSelectedSubMenu(location.pathname.replace("/", ""))
            setSelectedTab('alerts')
            getMenuMasterList()
        } else if (location.pathname.includes("alertdetails") || location.pathname.includes("chatflow") || location.pathname.includes("alertreport")) {
            setSelectedTab('alerts')
            if (location.pathname.includes("alertreport")) {
                setSelectedSubMenu('myalerts')
            } else if (location.pathname.includes("chatflow")) {
                setSelectedSubMenu('allalerts')
            }
        }
        else if (location.pathname === "/cameras/true" || location.pathname === "/cameras/false" || location.pathname === "/playback") {
            setSelectedTab('camera')
            getMenuMasterList()
            // if (CLOUD_STREAMING == '0') {
            //          runRovarUtilUrl("rovarrutil://")
            //      }
         } else if(location.pathname === "/panelcontrol") {
                setSelectedTab('Panel Control')
            
        } else if (location.pathname === "/alertactivity" || location.pathname === "/eventactivity" || location.pathname ==="/panelactivity") {
            setSelectedSubMenu(location.pathname.replace("/", ""))
            setSelectedTab('reports')
            getMenuMasterList()
        } else if (location.pathname === "/configuration") {
            // setSelectedTab('reports')
        } else if (location.pathname == "/") {
            history.push(HOMEPAGE)
            getMenuMasterList()
        } else if (location.pathname === "/healthmonitoring") {
            setSelectedTab('healthmonitoring')
            getMenuMasterList()
        }
        else if (location.pathname === currentPathname) {
            getMenuMasterList()
            setSelectedTab(currentPathname)
        }

    }, [location.pathname])

    useEffect(() => {
        if (isNotificationAlertIdClicked) {
            setSelectedTab('alerts')
        }
        return () => setIsNotificationAlertIdClicked(false)
    }, [isNotificationAlertIdClicked])

    //useEffect Block End

    const getMenuMasterList = () => {
        try {
            MenuMasterListAPI().then((resp) => {
                if (resp !== undefined) {
                    if (resp.Status == "success") {
                        setMenuDataList(resp.Data)
                    } else {
                        setMenuDataList(null)
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    // getMenuMasterList()
    const runRovarUtilUrl = (url) => {
        const requestOptions = { method: "GET" };
        // Linking.canOpenURL(url).then(supported => {
        //     if (!supported) {
        //       console.log('Can\'t handle url: ' + url);
        //     } else {
        //       return Linking.openURL(url);
        //     }
        //   }).catch(err => console.error('An error occurred', err))        

        window.open(url, "_blank", "noreferrer")

        fetch('http://localhost:3000/list', requestOptions)
            .then((resp) => { return resp.json(); })
            .then(function (data) {

                return data;
            }).catch(function (error) {
                setDisplayModal(true);
                console.error("runRovarUtilUrl () Exception: ", error);
            });
    }

    return (
        <>
            <div className={collapsed ? 'sidebar-minimized' : ''}>
                <div className="sidebar left updatedSidebar">
                    <nav className="sidebar-nav">
                        <div className="menu-list">
                            <ul id="menu-content" className="navList borderLineList" >
                                <li
                                    style={{ display: IS_DASHBOARD_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => {
                                        setSelectedTab('dashboard')
                                        history.push(HOMEPAGE)
                                    }}
                                    className={selectedTab == "dashboard" ? "highlight nav-item singleItem" : "nav-item singleItem"}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedTab == "dashboard" ? dashboard : dashboard} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "dashboard" ? "nav-label" : "nav-label"}`}>Home Page</span>
                                                </>
                                                :
                                                <>
                                                    <img src={selectedTab == "dashboard" ? dashboard : DashboardUnselected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "dashboard" ? "nav-label" : "nav-label"}`}>Home Page</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {alertTab &&
                                    <li
                                        style={{ display: IS_ALERTS_TAB_HIDDEN ? 'none' : 'block' }}
                                        onClick={() => {
                                            setSelectedTab('alerts');
                                            history.push('/allalerts')
                                        }} className={selectedTab == "alerts" ? "nav-item nav-dropdown open" : "nav-item nav-dropdown"}>
                                        <a className="nav-link nav-dropdown-toggle">
                                            {
                                                !isDarkTheme ?
                                                    <>
                                                        <img src={selectedTab == "alerts" ? alertSelected : alertSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 ${selectedTab == "alerts" ? "nav-label" : "nav-label"}`}>Alerts</span>
                                                    </> :
                                                    <>
                                                        <img src={selectedTab == "alerts" ? alertSelected : alertUnselected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 ${selectedTab == "alerts" ? "nav-label" : "nav-label"}`}>Alerts</span>
                                                    </>
                                            }
                                        </a>
                                    </li>
                                }
                                {
                                    <div
                                        style={{ display: selectedTab == "alerts" ? "block" : "none" }}
                                        className={`collapse-menu ${selectedTab != "alerts" ? "hide" : ""}`}>
                                        <ul className={`nav-dropdown-items`} id="products">

                                            <li className={`${selectedSubMenu == 'allalerts' ? 'active-sub-menu active' : ''}`} onClick={() => {
                                                setSelectedSubMenu('allalerts')
                                                setSelectedTab('alerts')
                                            }}>
                                                <Link className="nav-link1" to="/allalerts"><span>All Alerts</span></Link>

                                            </li>
                                            <li className={`${selectedSubMenu == 'myalerts' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                setSelectedSubMenu('myalerts')
                                                setSelectedTab('alerts')
                                            }}>
                                                <Link className="nav-link1" to="/myalerts"><span>Closed Alerts</span></Link>
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {camera &&
                                    <li
                                        style={{ display: IS_CAMERAS_TAB_HIDDEN ? 'none' : 'block' }}
                                        onClick={() => {
                                            setSelectedTab('camera')
                                            history.push('/cameras/true')
                                            // if (CLOUD_STREAMING == '0') {
                                            //     runRovarUtilUrl("rovarrutil://")
                                            // }
                                            // console.log("Location",location)
                                        }
                                        }
                                        className={selectedTab == "camera" ? "highlight nav-item singleItem" : "nav-item singleItem"}>
                                        <a className='nav-link'>
                                            {
                                                !isDarkTheme ?
                                                    <>
                                                        <img src={selectedTab == "camera" ? cameraSelected : cameraSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 ${selectedTab == "camera" ? "nav-label" : "nav-label"}`}>Cameras</span>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedTab == "camera" ? cameraSelected : cameraUnselected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 ${selectedTab == "camera" ? "nav-label" : "nav-label"}`}>Cameras</span>
                                                    </>
                                            }
                                        </a>
                                    </li>
                                }
                                <li
                                    style={{ display: IS_HEALTH_MONITORING_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => {
                                        setSelectedTab('healthmonitoring')
                                        history.push('/healthmonitoring')
                                    }}
                                    className={selectedTab == "healthmonitoring" ? "highlight nav-item singleItem" : "nav-item singleItem"}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedTab == "healthmonitoring" ? HMSelected : HMSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "healthmonitoring" ? "nav-label" : "nav-label"}`}>Health Monitoring</span>
                                                </>
                                                :
                                                <>
                                                    <img src={selectedTab == "healthmonitoring" ? HMSelected : HMUnSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "healthmonitoring" ? "nav-label" : "nav-label"}`}>Health Monitoring</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {
                                    isSuperUser
                                    ? 
                                    <li
                                        style={{ display: IS_PANELCONTROL_TAB_HIDDEN ? 'none' : 'block' }}
                                        onClick={() => { setSelectedTab('Panel Control'); 
                                            history.push('/panelcontrol')
                                        }}
                                        className={` ${selectedTab == "Panel Control" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                        <a className='nav-link'>
                                            {
                                                !isDarkTheme ?
                                                    <>
                                                        <img src={selectedTab == "Panel Control" ? PanelControlWhite : PanelControlWhite} alt="Logo" height={18} />
                                                        <span className={`pl-3 ${selectedTab == "Panel Control" ? "nav-label" : "nav-label"}`}>Panel Control</span>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedTab == "Panel Control" ? PanelControlWhite : PanelControl} alt="Logo" height={18} />
                                                        <span className={`pl-3 ${selectedTab == "Panel Control" ? "nav-label" : "nav-label"}`}>Panel Control</span>
                                                    </>
                                            }
                                        </a>
                                    </li>
                                    : null
                                }
                                <li
                                    style={{ display: IS_REPORTS_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => {
                                        setSelectedTab('reports')
                                        setSelectedSubMenu("panelactivity")
                                        history.push('/panelactivity')
                                    }}
                                    className={selectedTab == "reports" ? "nav-item nav-dropdown open" : "nav-item nav-dropdown"}>
                                    <a className='nav-link nav-dropdown-toggle'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedTab == "reports" ? reportSelected : reportSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "reports" ? "nav-label" : "nav-label"}`}>Reports</span>
                                                </>
                                                :
                                                <>
                                                    <img src={selectedTab == "reports" ? reportSelected : reportUnselected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedTab == "reports" ? "nav-label" : "nav-label"}`}>Reports</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {
                                    <div
                                        style={{ display: selectedTab == "reports" ? "block" : "none" }}
                                        className={`collapse-menu ${selectedTab != "reports" ? "hide" : ""}`}>
                                        <ul className={`nav-dropdown-items`} id="products">
                                         <li className={`${selectedSubMenu == "panelactivity" ? "active-sub-menu" : ""}`} onClick={() => {
                                                setSelectedSubMenu("panelactivity")
                                                setSelectedTab("reports")
                                            }}>
                                                <Link className="nav-link1" to="/panelactivity"><span>Event Report</span></Link>
                                            </li> 
                                            <li className={`${selectedSubMenu == "alertactivity" ? "active-sub-menu active" : ""}`} onClick={() => {
                                                setSelectedSubMenu("alertactivity")
                                                setSelectedTab("reports")
                                            }}>
                                                <Link className="nav-link1" to="/alertactivity"><span>Alert Report</span></Link>
                                            </li>
                                            <li className={`${selectedSubMenu == "eventactivity" ? "active-sub-menu" : ""}`} onClick={() => {
                                                setSelectedSubMenu("eventactivity")
                                                setSelectedTab("reports")
                                            }}>
                                                <Link className="nav-link1" to="/eventactivity"><span>Health Report</span></Link>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                }
                                {
                                    isSuperUser
                                        ? <li onClick={() => {
                                            history.push('/configuration')
                                        }}
                                            className={selectedTab == "sidebarActive" ? "highlight nav-item singleItem navPage" : "nav-item singleItem navPage"}>
                                            <a className='nav-link'>
                                                {
                                                    !isDarkTheme ?
                                                        <>
                                                            <img src={selectedTab == "" ? configSelectedWhite : configSelectedWhite} alt="Logo" height={17} width={17} />
                                                            <span className={`pl-3 ${selectedTab == "" ? "nav-label" : "nav-label"}`}>Configuration Tool</span>
                                                        </>
                                                        :
                                                        <>
                                                            <img src={selectedTab == "" ? configSelectedWhite : configSelected} alt="Logo" height={17} width={17} />
                                                            <span className={`pl-3 ${selectedTab == "" ? "nav-label" : "nav-label"}`}>Configuration Tool</span>
                                                        </>
                                                }
                                            </a>
                                        </li>
                                        : null
                                }

                                {
                                    menuDataList &&
                                    (menuDataList.length > 0 ?
                                        menuDataList.map((menuItem) => {
                                            return (
                                                <li
                                                    onClick={() => {
                                                        history.push(`/page/${menuItem.MenuId}`)
                                                        sessionStorage.setItem("customURL", menuItem.Menuurl)

                                                    }}
                                                    key={menuItem.MenuId}
                                                    className={selectedTab == `/page/${menuItem.MenuId}` ? "nav-item nav-dropdown navPage noDrop open" : "nav-item nav-dropdown navPage"}

                                                >
                                                    <a className="nav-link"  // href={menuItem.Menuurl}
                                                    >
                                                        <img
                                                            src={selectedTab == `/page/${menuItem.MenuId}` ? `/static/media/${menuItem.Iconname.split(".")[0] + "_selected." + menuItem.Iconname.split(".")[1]}` : `/static/media/${menuItem.Iconname}`}
                                                            alt="Logo" height={17} width={17}
                                                        />
                                                        <span className={`pl-3 ${selectedTab == "" ? "nav-label" : "nav-label"}`}>{menuItem.MenuName}</span>
                                                    </a>
                                                </li>
                                            )
                                        }
                                        )
                                        :
                                        "")
                                }
                            </ul>
                        </div>
                        <div className='versionTextDiv'>
                            <button className='termsText' onClick={(e) => { history.push(ABOUTTERMS) }}>T&C</button>
                            <p className='maxVersion'>Version: {VERSION}</p>
                            <p className='minVersion'>V- {VERSION}</p>
                        </div>
                    </nav>
                </div>
            </div>
            <Modal
                size='lg'
                show={displayModal}
                onHide={() => setDisplayModal(false)}
                className='downloadUtilityMainclass'
                backdrop="static"
            >
                <Modal.Header closeButton style={{ borderBottom: "none" }}>
                    {/* <Modal.Title>
                    <p className="ModelHeaderName">
                    Download Utility
                    </p>
                </Modal.Title> */}
                    {/* <div className='ModalBodyDiv'>
                <MonitoringHubLogo className="UtilityMHLogo" />
                </div> */}
                </Modal.Header>

                <Modal.Body>
                    <div className='ModalBodyDiv'>
                        <MonitoringHubLogo className="UtilityMHLogo" />
                        <div className='text-center' style={{ marginTop: "60px" }}>
                            <p>
                                UTILITY DOWNLOAD
                            </p>
                            <p className='UtilityText'>
                                To direct stream video, download and install <br />
                                MonitoringHub Utility
                            </p>
                            <a
                                href={CLIENT_COMPONENET_LINK}
                                download>
                                <button
                                    className="DataAccessEdit mt-1"
                                    style={{ width: "450px", height: "45px" }}
                                >
                                    <span className="ViewUpdate ml-1" style={{ fontSize: "0.975rem" }}>Download</span>
                                </button>
                            </a>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
