import React from 'react'

const AssignFRsToSiteGroup = ({
    usersToBind,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    siteGroups,
    users,
    siteGroupOnClick,
    selectedSiteGroup
}) => {

    const handleChange = index => event => {
        // handle FR level start
        const selUser = usersToBind.find(x => x.userID == event.target.id)
        if (selUser) {
            if (event.target.checked == true) {
                selUser.isSelected = true
                selUser.contactLevel = Object.entries(selectedUsers).filter(x => x[1] == true).length + 1
            }
            else {
                const maxIndex = usersToBind.length
                if (selUser.contactLevel != maxIndex) {
                    for (let i = 0; i < usersToBind.length; i++) {
                        const fruser = usersToBind[i];
                        if (fruser && fruser.userID != selUser.userID && fruser.contactLevel != null) {
                            if (fruser.contactLevel > selUser.contactLevel) {
                                var currentLength = fruser.contactLevel - 1
                                fruser.contactLevel = currentLength
                            }
                        }
                    }
                }
                selUser.isSelected = false
                selUser.contactLevel = null
            }
        }
        // handle FR level end
        setSelectedUsers({ ...selectedUsers, [event.target.id]: event.target.checked })
    }

    return <>
        <div className="LeftAddSide">
            <div className='LeftAddSideHeight'>
                <div className="ManageSite userTemplate-FRAssignment-container">
                    <h1 className="MainHeaderName mb-0">2. Assign Field Responder to Site Group</h1>
                    <div className="ManageSiteDiv">
                        <div className="DevicesHeader">
                            <p className="mb-0" style={{ width: "40%" }}>Site Group<br /></p>
                            <p className="mb-0">Field Responder List<br /></p>
                        </div>

                        {/* --- */}

                        <div className="site-group">
                            <div className="site-group-list">
                                {
                                    siteGroups &&
                                    siteGroups.map(item => {
                                        return <div
                                            id={item.siteGroupID}
                                            key={item.siteGroupID}
                                            onClick={() => siteGroupOnClick(item)}
                                            className={`cameraList user-list-item ${selectedSiteGroup && item.siteGroupID == selectedSiteGroup?.siteGroupID && "selected-user"}`}
                                        >
                                            <p className="mb-0">{item.groupTitle}</p>
                                            <div style={{ float: "right" }}>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        <div className="SiteList" style={{ borderBottom: "1px solid #e6e6e6" }}>
                            <div className='sitegrp-sitelist' style={{ textAlign: "center" }}>
                                <form >
                                    {
                                        usersToBind ? usersToBind.map((value, index, array) => {
                                            return <div className="selection-list-group cameraList"
                                                key={value.userID}
                                            >
                                                <input
                                                    id={value.userID}
                                                    className="checkbox_custom"
                                                    type="checkbox"
                                                    key={value.userID}
                                                    onChange={handleChange(index)}
                                                    name={value.firstName}
                                                    defaultChecked={value.isSelected == true ? true : false}
                                                />
                                                <p className="ListofText">{value.firstName}
                                                    <span className="SiteNameText">{value.isSelected && ` - Level-${value.contactLevel}`}</span>
                                                </p>
                                            </div>
                                        }) : <div className='NoFRMsg'>
                                            No FRs added to this site group.
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>

                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                            <button className="NextMoveButton assignFieldResponder-AddSelected-btn" onClick={() => onAddSelectedUsers()}>
                                <span className="ViewUpdate">Add Selected</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AssignFRsToSiteGroup
