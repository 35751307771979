import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import 'reactjs-popup/dist/index.css';
import "rsuite/dist/rsuite.min.css";
import 'react-calendar/dist/Calendar.css';

import { Save } from '../../assets/images'
import { MosaicNoStream, PlayBackWhite, SiteIcon, BranchIcon, MosaicSnapshot, MosaicTwowayStart, MosaicTwowayStop, MosaicHooterOn, MosaicHooterOff, MosaicFullscreen, MosaicExitFullscreen, Video_Export } from '../../assets/images'

import { HooterOnOffClick, TwoWayCommunicationRequest } from '../../services/alertAuthService'
import { addDownload } from '../../services/downloadService'

import ReactPlayer from 'react-player'
import { addMinutes, subMinutes, differenceInMinutes } from 'date-fns'
import screenfull from 'screenfull'
import captureVideoFrame from "capture-video-frame"
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Modal, Tooltip } from 'react-bootstrap'

import PopupCard from './PopupCard'
import WebRTCPlayer from './WebRTCPlayer'

import { useVideo } from '../../CustomHooks/useVideo'
import { WarningToast } from '../../CustomHooks/WarningToast';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { ConvertDate } from '../../utils/utils'
import { CAMERA_SINGLE_PLAYBACK } from '../../navigation/CONSTANTS'
import { CLOUD_STREAMING, NO_STREAM_INTERVAL_TIMER, IS_WEBRTC, MULTI_TIME_ZONE } from '../../config'

const CameraCard = ({ item,
  chat,
  time,
  isMultiPlay,
  isCheckBoxVisible,
  isChecked,
  onCheckBoxOnClick,
  exportStartTime,
  exportEndTime,
  selectedTimeZone
}) => {
  let history = useHistory()

  //#region useState start
  const [isCameraCardHooterOn, setIsCameraCardHooterOn] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isFullscreenClick, setIsFullscreenClick] = useState(false);
  const videoContainerRef = useRef(null);
  const videoContainerParentRef = useRef(null);
  const [blankDate, setBlankDate] = useState("");
  const [blankDateTwo, setBlankDateTwo] = useState("");
  const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
  const [playUrl, setPlayUrl] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const [bufferCheckState, setBufferCheckState] = useState(false);
  const [bufferViewState, setBufferViewState] = useState(true);
  const [date, setDate] = useState(subMinutes(new Date(), 15));
  const [dateTwo, setdateTwo] = useState(new Date());
  const [dateString, setDateString] = useState("");
  const [dateStringTwo, setDateStringTwo] = useState("");
  const [timeString, setTimeString] = useState("");
  const [timeStringTwo, setTimeStringTwo] = useState("");
  const [timeStringMain, setTimeStringMain] = useState("+15 min");
  const [timeStringMainTwo, setTimeStringMainTwo] = useState("+15 min");
  const [differenceTime, setDifferenceTime] = useState(15);
  const [differenceTimeTwo, setDifferenceTimeTwo] = useState(15);
  const [popOpen, setPopOpen] = useState(false);
  const [mode, setMode] = useState("");
  //#endregion useState end

  //#region useEffect start
  useEffect(() => {
    let c = count + 1;
    setCount(c);
    //

    if (IS_WEBRTC == 0) {
      if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
      {
        // let baseUrl = "http://localhost:8080"
        let baseUrl = "http://localhost:3000"
        let tempbaseUrl = "http://localhost:3000"
        let rtspurl = item.RTSPURL
        //get cam guid
        let camguid = item.StreamName
        //do post req to base url
        let actbaseUrl = baseUrl + "/start"
        let IsTrancoding = false
        // if (item.istranscdng == 1)
        if (item.isTranscoded == 1)
          IsTrancoding = true
        fetch(actbaseUrl, {
          method: 'POST',
          body: JSON.stringify({
            uri: rtspurl,
            alias: camguid,
            isencode: IsTrancoding
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((res) => res.json())
          .then((data) => {
            baseUrl = baseUrl + data['uri']
            setPlayUrl(baseUrl)
            //item.
          })
          .catch((err) => {
            console.log("check data error", err.message);
          });
        return () => {
          let stopbaseUrl = tempbaseUrl + "/stop";
          let isPresent = sessionStorage.getItem('mosaicWindowKey')
          if (isPresent == "mosaicWindow") {
            return;
          }
          fetch(stopbaseUrl, {
            method: 'POST',
            body: JSON.stringify({
              alias: camguid,
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          })
            .then((res) => res.json())
            .then((data) => {
            })
            .catch(err => {
              console.log(err.message)
            });
        }
        //url as rtspurl and alis as camguid
      } else {
        setPlayUrl(item.StreamingURL)
      }
    } else {
      let rtspurl = item.RTSPURL
      let camguid = item.StreamName
      let isTranscode = item.isTranscoded


    }

    //check here for mode of streaming

    //get resp

    //make URL to play to react player
    // let  = baseUrl + ""//retun rul

  }, [time]);

  useEffect(() => {
    // let d = new Date();
    let d = subMinutes(new Date(), 15);
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateString(temp_date);
  }, []);

  useEffect(() => {
    // let d = new Date();
    let d = new Date();
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateStringTwo(temp_date);
  }, []);

  useEffect(() => {
    const handlePageChange = () => {
      TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
    };
    const unlisten = history.listen(handlePageChange);
    return () => {
      unlisten();
    }
  }, [history])

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {

      })
    }
  }, [screenfull]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    function exitHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        setIsFullscreenClick(false);
        let c = count + 1;
        setCount(c);
      } else {
      }
    }
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
    }
  }, [isFullscreenClick]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!bufferRef.current) {
        setBufferViewState(false)
      }
    }, NO_STREAM_INTERVAL_TIMER);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (bufferViewState == false) {
        setBufferViewState(true)
      }
    }, 60000 * 2);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [bufferViewState]);
  //#endregion useEffect end

  const bufferRef = useRef(bufferCheckState)

  //#region functions start
  const dateChange = (v, e) => {
    let d = ConvertDate(v);
    let temp = d.slice(0, 10).replace(/-/g, '_');
    setDateString(temp);
    setDateStringTwo(temp);
    setDate(v);
    setdateTwo(v);
  }
  const getDataFromChildTwo = (data) => {
    let timeStringMainTwo = "";

    if (data === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = data;
    }
    let temp = dateStringTwo + '_' + timeStringMainTwo;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      if (mode == "Playback") {
        WarningToast("Selected Time is less than 15 min !")
      }

      timeStringMainTwo = difference + " min ";
      setDifferenceTimeTwo(parseInt(difference))
      setTimeStringMainTwo(timeStringMain);
    } else {
      setDifferenceTimeTwo(15);
      setTimeStringMainTwo("15 min");
    }

    setTimeStringTwo(data);
  }

  const getDataFromChild = (data) => {
    let timeStringMain = "";

    if (data === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = data;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      if (mode == "Playback") {
        WarningToast("Selected Time is less than 15 min !")
      }
      timeStringMain = difference + " min ";
      setDifferenceTime(parseInt(difference))
      setTimeStringMain(timeStringMain);
    } else {
      setDifferenceTime(15);
      setTimeStringMain("15 min");
    }

    setTimeString(data);
  }

  const showErrorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const onSubmitBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

    let ED = addMinutes(SD, differenceTime);
    let toDateTime = ConvertDate(ED);

    let currentTime = new Date();
    if (differenceTime == 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    }
    if (SD > currentTime && differenceTime > 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    } else {
      let tempState = {
        startTime: temp,
        endTime: toDateTime.replace(/[ :-]/g, '_'),
        deviceId: item.ParentCameraID,
        cameraName: item.CameraName,
        siteName: item.SiteName,
        dateTimeOne: SD,
        dateTimeTwo: ED,
        streamName: item.StreamName,
        isTranscoded: item.isTranscoded
      }

      history.push({
        pathname: CAMERA_SINGLE_PLAYBACK,
        state: {
          startTime: temp,
          endTime: toDateTime.replace(/[ :-]/g, '_'),
          deviceId: item.ParentCameraID,
          cameraName: item.CameraName,
          siteName: item.SiteName,
          dateTimeOne: SD,
          dateTimeTwo: ED,
          streamName: item.StreamName,
          isTranscoded: item.isPlaybackTranscoded,
          item: item
        }
      });
    }
  }

  const onExportBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let startD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    let SD = formatDate(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

    let timeStringMainTwo = "";
    if (timeStringTwo === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = timeStringTwo;
    }
    let tempTwo = dateStringTwo + '_' + timeStringMainTwo;
    let arrTwo = tempTwo.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    //console.log("Date", ED); 
    let endD = new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5])
    let ED = formatDate(new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5]));
    let currentTime = new Date();

    let diff = differenceInMinutes(endD, startD);
    let current = new Date();

    if (startD > current) {
      showErrorToast('Invalid timings, start date time is greater than current time.')
      return;
    }

    if (endD > current) {
      showErrorToast('Invalid timings, end date time is greater than current time.')
      return;
    }

    if (diff > 15) {
      showErrorToast('Max limit for downloading of video is 15 mins')
      return;
    } else if (diff < 0) {
      showErrorToast('Invalid timings')
      return;
    } else if (diff == 15 && SD.slice(-2) != ED.slice(-2)) {
      showErrorToast('Max limit for downloading of video is 15 mins')
      return;
    }
    if (differenceTimeTwo == 0) {
      showErrorToast('The selected time is greater from the current time!')
      return;
    }
    //const difference = differenceInMinutes(currentTime, SD);
    if (SD > currentTime && differenceTimeTwo > 0) {
      showErrorToast('The selected time is greater from the current time!')
      return;
    } else {
      // Here will need to call the API to initiate download for the video with the parameters of its start time and end time.
      let user = JSON.parse(localStorage.getItem('user'))
      let currentDate = formatDate(new Date());
      let obj = {
        VideoExportLogID: "1",
        RequestedBy: user.UserUniqueID,
        RequestedByName: user.FullName,
        ExportRequestedOn: currentDate,
        DeviceID: item.ParentCameraID.toString(),
        DeviceName: item.CameraName,
        ExportStatus: "0",
        ExportStatusDateTime: "",
        ExportStatusLog: "",
        DownloadLink: "",
        DownloadStatus: "",
        DownloadDateTime: "",
        DeleteFlag: "0",
        ExportFromDateTime: SD,
        ExportToDateTime: ED
      }
      addDownload(obj)
        .then((res) => {
          if (res.Status == "success") {
            SuccessToast(`The download request has been raised successfully. `)
          }
        }).catch(err => console.log(err));
    }
  }
  const HooterOnOffClickReq = (pDeviceId, pMode) => {
    HooterOnOffClick(pDeviceId, pMode)
      .then((res) => {
        if (res.Status == "success") {
          if (pMode == 'on') {
            setIsCameraCardHooterOn(true)
          } else {
            setIsCameraCardHooterOn(false)
          }
        }
      }).catch(err => console.log(err));
  }

  const TwoWayCommunicationReq = async (privateIPAddress, CommunicationPort
    , action, user, password, BrandName) => {
    try {
      if (privateIPAddress && CommunicationPort
        && user && password) {
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {
            return resp
          })
      } else {
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {

            return resp
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  function TwoWayAudioClick(audio, chat, isCloseBtn) {
    var resp = "";
    var resp1 = "";
    if (audio && audio == 'start') {
      setIsSoundOn(false)
      resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      // 
      resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
        , 'start', chat.camUserName, chat.camPassword, chat.BrandName
      )
    }
    if (audio && audio == 'stop') {
      setIsSoundOn(true)

      resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
        , 'stop', chat.camUserName, chat.camPassword, chat.BrandName
      )
    }
  }

  const handleFullScreen = () => {
    screenfull.toggle(videoContainerParentRef.current)
    setIsFullscreenClick(!screenfull.isFullscreen);
    let c = count + 1;
    setCount(c);
  };

  /* captureImage */
  const handleCaptureFrame = (params) => {
    const video = videoContainerRef.current.getInternalPlayer();

    if (typeof video === 'string') {
      video = console.log("rupesh checks", video);
    }
    const frame = captureVideoFrame(params)
    setCapturedImage(frame.dataUri)
    var fileUrl = frame.dataUri;

    // const popupWindow = window.open('', '_blank', 'width=540,height=360');
    const popupWindow = window.open('', '_blank', 'width=auto, height=auto');
    popupWindow.document.open();
    popupWindow.document.write(`
  <html>
  <head>
  <title>Snapshot</title>
  
  <style>
  body, html {
    margin: 0; padding: 0; background: transparent; font-family: Segoe UI;
  }
  .maincontainer {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .snapshottitlediv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #bdbcbc; 
    flex: 0 1 auto;
    padding: 0px 10px;
  }
  .DataAccessEdit {
    width: 32px;
    height: 32px;
    padding: 8px 7.6px 7.6px 8px;
    border-radius: 5px;
    background-color: #0b70d1;
    line-height: 10px;
    margin-left: 5px;
    border: 1px solid #0b70d1;
  }
  p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 0px 5px;
  }
  .snapshotimagediv {
    flex: 1 1 auto;
    display: grid;
    align-items: center;
  }
  .snapshotimagediv img {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    display: flex;
  }
  </style>
  </head>
  <body>
  <div class="maincontainer">
    <div class="snapshottitlediv">
    <p>${item.CameraName} Snapshot</p>
    <button class="DataAccessEdit" data-title="Download" title="Save" onclick="capturedFrameDownloadClick('${fileUrl}')">
    <img src=${Save} alt="download icon" data-title="Save" />

    </button>
    </div>
    <div class="snapshotimagediv">
    <img src="${fileUrl}" alt="Screenshot" />
    </div>
  </div>
  </body>
  <script>
  function capturedFrameDownloadClick(imageData){
    var fileUrl = imageData;
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob, { type: "image" });
        link.download = "test.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });              
  }
  </script>
  </html>`);
    popupWindow.document.close();
  };
  /* captureImage */

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  //#endregion functions end
  return (
    <div className="CameraDiv1 CameraGridNewUI"
      key={item.StreamName}
    >
      {
        IS_WEBRTC == 0
          ?
          <>
            {
              item.RTSPURL ?//item.StreamingURL ?
                (
                  bufferViewState ?
                    <>
                      <div
                        ref={videoContainerParentRef}
                        className='cameraHoverMainDiv'
                      >
                        <ReactPlayer
                          key={count}
                          ref={videoContainerRef}
                          itemID={"video-" + item.StreamName}
                          url={isMultiPlay ? item.playbackURL : playUrl}//item.StreamingURL
                          playing={true}
                          style={{ borderRadius: "7px 7px 0px 0px", width: "100%", height: "100%" }}
                          controls
                          autoPlay
                          pip={true} // for picture in picture
                          stopOnUnmount={false} // for picture in picture
                          className={
                            isFullscreenClick && isMultiPlay ? "player-responsive-fullScreen"
                              : !isFullscreenClick && isMultiPlay ? "multiplaybackplayer"
                                : isFullscreenClick && !isMultiPlay ? "player-responsive-fullScreen"
                                  : "player-responsive1"}
                          onBuffer={(e) => videoStartBuffering(e, item.CameraName)}
                          onBufferEnd={(e) => {
                            videoStopBuffering(e, item.CameraName);
                            bufferRef.current = true
                          }}
                          onError={(e) => videoErrorHandler(e, item.CameraName)}
                          width='320px'
                          height='180px'
                          config={{
                            file: {
                              attributes: {
                                crossorigin: 'anonymous',
                                disablePictureInPicture: isMultiPlay ? false : true, // hide and display pip
                                controlsList: isMultiPlay ? "noplaybackrate" : "noplaybackrate nodownload",
                                onContextMenu: (e) => e.preventDefault(),
                              },
                              tracks: [],
                              forceVideo: true,
                              forceAudio: true,
                              http: {
                                headers: getHeaders(),
                              },
                            },
                          }}
                        />
                        <div className='cameraButtonsMainDiv' style={{ width: "100%" }}>
                          <div className='mosaicbuttons'>
                            {
                              !isFullscreenClick && !isMultiPlay
                                ?
                                <button
                                  className="MosaicButtonDiv"
                                  onClick={e => {
                                    setPopOpen(true);
                                    setMode("Playback");
                                  }}>
                                  <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                      <Tooltip>
                                        Playback
                                      </Tooltip>
                                    }
                                  >
                                    <img src={PlayBackWhite} className="camera-icon-height" alt='playback white icon' style={{ padding: "2px", height: "24px", }} />
                                  </OverlayTrigger>
                                </button>
                                : null
                            }

                            <button data-title='SnapShot' className='MosaicButtonDiv'>
                              <img style={{ padding: "2px", height: "24px", }}
                                src={MosaicSnapshot}
                                alt="MosaicSnapshot"
                                onClick={
                                  () => {
                                    handleCaptureFrame(videoContainerRef.current.getInternalPlayer())
                                    if (isFullscreenClick) {
                                      setIsFullscreenClick(false);
                                      let c = count + 1;
                                      setCount(c);
                                    }
                                  }
                                }
                              />
                            </button>
                            {
                              item.ShowTwoWay
                                ? (
                                  isSoundOn ?
                                    <button data-title='TwowayStart' className='MosaicButtonDiv'>
                                      <img style={{ padding: "2px" }}
                                        src={MosaicTwowayStart}
                                        onClick={() => TwoWayAudioClick('start', item)}
                                        alt="MosaicTwowayStart"
                                      />
                                    </button>
                                    :
                                    <button data-title='TwowayStop' className='MosaicButtonDiv'>
                                      <img style={{ padding: "2px" }}
                                        src={MosaicTwowayStop}
                                        onClick={() => TwoWayAudioClick('stop', item)}
                                        alt="MosaicTwoStop"
                                      />
                                    </button>
                                )
                                : null
                            }
                            {
                              item.ShowHooter ?
                                (!isCameraCardHooterOn ?
                                  <button data-title='HooterOn' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px" }}
                                      src={MosaicHooterOn}
                                      alt="HooterOn"
                                      onClick={() => { HooterOnOffClickReq(item.ParentCameraID, 'on') }}
                                    />
                                  </button>
                                  :
                                  <button data-title='HooterOff' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px" }}
                                      src={MosaicHooterOff}
                                      alt="HooterOff"
                                      onClick={() => { HooterOnOffClickReq(item.ParentCameraID, 'off') }}
                                    />
                                  </button>) : null
                            }
                            <button
                              data-title={isFullscreenClick ? 'ExitFullscreen' : 'Fullscreen'}
                              className='MosaicButtonDiv'
                              onClick={handleFullScreen}
                            >
                              {
                                !isFullscreenClick ?
                                  <img style={{ padding: "2px" }}
                                    src={MosaicFullscreen}
                                    alt="MosaicFull"
                                  />
                                  :
                                  <img style={{ padding: "2px" }}
                                    src={MosaicExitFullscreen}
                                    alt="MosaicExit"
                                  />
                              }
                            </button>

                            <button data-title='Video Export' className='MosaicButtonDiv'>
                              <img style={{ padding: "2px", height: "24px", }}
                                src={Video_Export} alt="video Export Button"
                                onClick={
                                  () => {
                                    setPopOpen(true);
                                    setMode("Export");
                                  }
                                }
                              />
                            </button>
                          </div>
                          <div>
                            <Modal
                              show={popOpen}
                              onHide={() => setPopOpen(false)}
                              className={mode == "Playback" ? 'singleplaybackexportModal' : 'ExportModal'}
                              backdrop="static"
                            >
                              <Modal.Header closeButton>
                                {
                                  mode == "Playback" ?
                                    <Modal.Title>
                                      <p className="ModelHeaderName">{item.CameraName} - Playback</p>
                                    </Modal.Title>
                                    :
                                    <Modal.Title>
                                      <p className="ModelHeaderName">{item.CameraName} - Export</p>
                                    </Modal.Title>
                                }
                              </Modal.Header>
                              <Modal.Body>
                                <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={mode == "Playback" ? onSubmitBtn : onExportBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode={mode} setBlankDateTwo={setBlankDateTwo} getDataFromChildTwo={getDataFromChildTwo} timeStringMainTwo={timeStringMainTwo} dateTwo={dateTwo} />
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <div className="no-stream-found">
                      <img
                        src={MosaicNoStream}
                        alt="Video"
                        className="w-100 image-bg mosaic-nostream-img"
                      />
                    </div>
                )
                :
                <div className="no-stream-found">
                  <img
                    src={MosaicNoStream}
                    alt="Video"
                    className="w-100 image-bg mosaic-nostream-img"
                  />
                </div>
            }

          </>
          :
          <WebRTCPlayer
            key={`${item.ParentCameraID}_${isMultiPlay}`}
            id={isMultiPlay ? item.ParentCameraID + "4" : item.ParentCameraID}
            url={isMultiPlay ? item.playbackURL : item.RTSPURL}
            isEncode={isMultiPlay ? item.isPlaybackTranscoded : item.isTranscoded}
            item={item}
            upperDivCss="cameraHoverMainDiv"
            videoCss="player-responsive1"
            fullVideoCss="player-responsive-fullScreen"
            buttonCss="cameraButtonsMainDiv"
            noStreamDivCss="no-stream-found"
            playback={false}
            prePostDisplay={false}
            liveButton={true}
            exportCurrentVideoHide={isMultiPlay ? false : true}
            exportStartTime={exportStartTime}
            exportEndTime={exportEndTime}
            isMultiPlay={isMultiPlay}
            showPlaybackBtn={isMultiPlay ? false : true}
            showAlertCreation={isMultiPlay ? false : true}
            showHooterIcon={isMultiPlay ? false : true}
          />
      }

      <div className="p-2 ForResponsiveWidth">
        <div className='LabelDivWithButton'>
          <p className="CameraNameTextValue">{item.CameraName}</p>
          {
            MULTI_TIME_ZONE
            ?
            <div>
            {
              isCheckBoxVisible && (selectedTimeZone == item.Abbreviation || selectedTimeZone == null) ?
                <div>
                  <input
                    className="camera-checkbox"
                    type="checkbox"
                    id={item.ParentCameraID}
                    defaultChecked={isChecked ? item.isChecked ? true : false : false}
                    onClick={e => { onCheckBoxOnClick(e) }}
                  />
                </div>
                : !isCheckBoxVisible && isMultiPlay
                  ? null
                  : <div style={{ display: "flex" }}>
                  </div>
            }
            </div>
            :
            <div>
            {
              isCheckBoxVisible ?
                <div>
                  <input
                    className="camera-checkbox"
                    type="checkbox"
                    id={item.ParentCameraID}
                    defaultChecked={isChecked ? item.isChecked ? true : false : false}
                    onClick={e => { onCheckBoxOnClick(e) }}
                  />
                </div>
                : !isCheckBoxVisible && isMultiPlay
                  ? null
                  : <div style={{ display: "flex" }}>
                  </div>
            }
          </div>
          }
        </div>
        <div className='CameraDetailsDiv'>
          <div>
            <img src={SiteIcon} alt='Site' />
            <span className='CameraDetailsText ml-1'>{item.SiteName}{item.Abbreviation != null ? (` (${item.Abbreviation})`) : ""}</span>
          </div>
          <div>
            <img src={BranchIcon} alt='Location' />
            <span className='CameraDetailsText'>{item.Location}</span>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CameraCard)