
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

import { BackArrowDevice } from '../../../../assets/images'

import { Row, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { CSVLink } from "react-csv";
import Papa from 'papaparse';

import SelectHeaderCommon from './SelectHeaderCommon';
import SelectHeaderEmpty from './SelectHeaderEmpty';
import { MULTI_TIME_ZONE } from '../../../../config';

const CameraBulkUpload = React.forwardRef((props, ref) => {
    let {
        selectedCamHeaders,
        OnAddBulkDevicesClick,
        setSelectedCamHeaders,
        clearImportedCamHeaders,
        deviceBulkUploadResponse,
        setImportedFileData,
        setDeviceBulkUploadResponse,
        setBulkUpload,
        setIsSingleOrBulkSelection,
        importedFileHeaders,
        setImportedFileHeaders,
    } = { ...props };

    const { register, handleSubmit } = useForm({
        defaultValue: { ...selectedCamHeaders },
    });

    //#region useState start
    const hiddenCamFileInput = React.useRef(null);
    const [defaultBrand, setDefaultBrand] = useState(null);
    const [defaultIp, setDefaultIp] = useState(null);
    const [defaultCommunicationPort, setDefaultCommunicationPort] = useState(null);
    const [defaultHttpPort, setDefaultHttpPort] = useState(null);
    const [defaultUserName, setDefaultUserName] = useState(null);
    const [defaultPassword, setDefaultPassword] = useState(null);
    const [defaultPort, setDefaultPort] = useState(null);
    const [defaultChannelNum, setDefaultChannelNum] = useState(null);
    const [defaultLiveStreamType, setDefaultLiveStreamType] = useState(null);
    const [defaultPlaybackStreamType, setDefaultPlaybackStreamType] = useState(null);
    const [defaultDeviceName, setDefaultDeviceName] = useState(null);
    const [defaultBranch, setDefaultBranch] = useState(null);
    const [defaultEnablePlaybackTranscoding, setDefaultEnablePlaybackTranscoding] = useState(null);
    const [defaultEnableTranscoding, setDefaultEnableTranscoding] = useState(null);
    const [defaultTwoWayEnableDevice, setDefaultTwoWayEnableDevice] = useState(null);
    const [defaultcctvtype, setDefaultcctvtype] = useState(null);
    const [totalDevices, setTotalDevices] = useState(null);
    const [failedDevices, setFailedDevices] = useState(null);
    const [successDevices, setSuccessDevices] = useState(null);
    const [camImportedFileName, setCamImportedFileName] = useState(null)
    const [faildListToDownload, setFaildListToDownload] = useState(null)
    const [sucessListToDownload, setSucessListToDownload] = useState(null)
    //const [timezoneBulkUpload,setTimeZoneBulkUpload]=useState([])
    const [defaultTimeZone,setDefaultTimeZone]=useState(null)
    const[defaultPtz,setDefaultPtz]=useState(null)

    //#endregion useState end
    useEffect(() => {
        if (importedFileHeaders) {
            var brand = importedFileHeaders.find((x) => x == "brand");
            if (brand) {
                setDefaultBrand(brand);
            } else {
                brand = null;
            }

            var ip = importedFileHeaders.find((x) => x == "ipaddress");
            if (ip) {
                setDefaultIp(ip);
            } else {
                ip = null;
            }

            var communicationport = importedFileHeaders.find((x) => x == "communicationport");
            if (communicationport) {
                setDefaultCommunicationPort(communicationport);
            } else {
                communicationport = null;
            }
            var httpport = importedFileHeaders.find((x) => x == "httpport");
            if (httpport) {
                setDefaultHttpPort(httpport);
            } else {
                httpport = null;
            }

            var username = importedFileHeaders.find((x) => x == "username");
            if (username) {
                setDefaultUserName(username);
            } else {
                username = null;
            }

            var password = importedFileHeaders.find((x) => x == "password");
            if (password) {
                setDefaultPassword(password);
            } else {
                password = null;
            }

            var port = importedFileHeaders.find((x) => x == "port");
            if (port) {
                setDefaultPort(port);
            } else {
                port = null;
            }

            var channelnumber = importedFileHeaders.find((x) => x == "channelnumber");
            if (channelnumber) {
                setDefaultChannelNum(channelnumber);
            } else {
                channelnumber = null;
            }

            var livestreamtype = importedFileHeaders.find((x) => x == "livestreamtype");
            if (livestreamtype) {
                setDefaultLiveStreamType(livestreamtype);
            } else {
                livestreamtype = null;
            }
            var playbackstreamtype = importedFileHeaders.find((x) => x == "playbackstreamtype");
            if (playbackstreamtype) {
                setDefaultPlaybackStreamType(playbackstreamtype);
            } else {
                playbackstreamtype = null;
            }

            var devicename = importedFileHeaders.find((x) => x == "devicename");
            if (devicename) {
                setDefaultDeviceName(devicename);
            } else {
                devicename = null;
            }

            var branch = importedFileHeaders.find((x) => x == "branch");
            if (branch) {
                setDefaultBranch(branch);
            } else {
                branch = null;
            }

            var enablelivetranscoding = importedFileHeaders.find((x) => x == "enablelivetranscoding");
            if (enablelivetranscoding) {
                setDefaultEnableTranscoding(enablelivetranscoding);
            } else {
                branch = null;
            }

            var enableplaybacktranscoding = importedFileHeaders.find((x) => x == "enableplaybacktranscoding");
            if (enableplaybacktranscoding) {
                setDefaultEnablePlaybackTranscoding(enableplaybacktranscoding);
            } else {
                branch = null;
            }

            var enabletwowaydevice = importedFileHeaders.find((x) => x == "enabletwowaydevice");
            if (enabletwowaydevice) {
                setDefaultTwoWayEnableDevice(enabletwowaydevice);
            } else {
                branch = null;
            }

            let cctvtype = importedFileHeaders.find((x) => x == "cctvtype");
            if (cctvtype) {
                setDefaultcctvtype(cctvtype);
            } else {
                cctvtype = null;
            }

            var ptz = importedFileHeaders.find((x) => x == "ptz");
            if (ptz) {
                setDefaultPtz(ptz);
            } else {
                ptz = null;
            }

            var timezone = importedFileHeaders.find((x) => x == "timezone");
            if (timezone) {
                setDefaultTimeZone(timezone);
            } else {
                timezone = null;
            }
           

            let camHeadObj = {
                brand: brand,
                ipaddress: ip,
                communicationport: communicationport,
                httpport: httpport,
                username: username,
                password: password,
                port: port,
                channelnumber: channelnumber,
                // playbackchannelnumber: playbackchannelnumber,
                livestreamtype: livestreamtype,
                playbackstreamtype: playbackstreamtype,
                devicename: devicename,
                branch: branch,
                enablelivetranscoding: enablelivetranscoding,
                enableplaybacktranscoding: enableplaybacktranscoding,
                enabletwowaydevice: enabletwowaydevice,
                // interfacename: interfacename,
                cctvtype: cctvtype,
                ptz:ptz
            };
            if(MULTI_TIME_ZONE){
                camHeadObj.timezone = timezone 
            }
            setSelectedCamHeaders(camHeadObj);
        }
    }, [importedFileHeaders]);

    useEffect(() => {
        setImportedFileHeaders(null);
        hiddenCamFileInput.current.value = null;
    }, [clearImportedCamHeaders]);

    useEffect(() => {
        if (deviceBulkUploadResponse) {
            var failedDevList = deviceBulkUploadResponse && deviceBulkUploadResponse['CameraFailedList'] && deviceBulkUploadResponse['CameraFailedList'].length
            var successDevList = deviceBulkUploadResponse && deviceBulkUploadResponse['CameraSuccessList'] && deviceBulkUploadResponse['CameraSuccessList'].length
            if (failedDevList && failedDevList != undefined) {
                setFailedDevices(failedDevList)

            }
            if (successDevList && successDevList != undefined) {
                setSuccessDevices(successDevList)
            }

            // setTotalDevices(successDevList)
            if ((deviceBulkUploadResponse['CameraFailedList'] && deviceBulkUploadResponse['CameraFailedList'].length > 0) ||
                (deviceBulkUploadResponse['CameraSuccessList'] && deviceBulkUploadResponse['CameraSuccessList'].length > 0)) {
                const failedList = deviceBulkUploadResponse['CameraFailedList']
                const successList = deviceBulkUploadResponse['CameraSuccessList']
                const newList = [];
                const newList2 = [];
                // newList.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber", "devicename", "branch", "enablelivetranscoding", "reason"])
                // newList.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype","playbackstreamtype","playbackchannelnumber", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype","enabletwowaydevice", "reason"])
                if(MULTI_TIME_ZONE){
                    newList.push(["brand", "ipaddress", "communicationport", "httpport", "username", "password", "port", "channelnumber", "livestreamtype", "playbackstreamtype", "devicename", "branch", "enablelivetranscoding", "enableplaybacktranscoding"/* , "interfacename" */, "cctvtype", "enabletwowaydevice","ptz","timezone","reason"])
                }else{
                    newList.push(["brand", "ipaddress", "communicationport", "httpport", "username", "password", "port", "channelnumber", "livestreamtype", "playbackstreamtype", "devicename", "branch", "enablelivetranscoding", "enableplaybacktranscoding"/* , "interfacename" */, "cctvtype", "enabletwowaydevice","ptz","reason"])
                }

                
                for (let i = 0; i < failedList.length; i++) {
                    const element = failedList[i];
                    let covertLiveStreamType = "";
                    let convertPlaybackStreamType = "";

                    if (element["brandName"].toLowerCase() == "hikvision" || element["brandName"].toLowerCase() == "hikvision-hybrid") {
                        if (element["liveStreamTypeNumber"] == "01") {
                            covertLiveStreamType = "main"
                        } else if (element["liveStreamTypeNumber"] == "02") {
                            covertLiveStreamType = "sub"
                        } else if (element["liveStreamTypeNumber"] == "03") {
                            covertLiveStreamType = "ter"
                        }

                        if (element["playbackStreamTypeNumber"] == "01") {
                            convertPlaybackStreamType = "main"
                        } else if (element["playbackStreamTypeNumber"] == "02") {
                            convertPlaybackStreamType = "sub"
                        } else if (element["playbackStreamTypeNumber"] == "03") {
                            convertPlaybackStreamType = "ter"
                        }
                    } else {
                        if (element["liveStreamTypeNumber"] == "0") {
                            covertLiveStreamType = "main"
                        } else if (element["liveStreamTypeNumber"] == "1") {
                            covertLiveStreamType = "sub"
                        } else if (element["liveStreamTypeNumber"] == "2") {
                            covertLiveStreamType = "ter"
                        }

                        if (element["playbackStreamTypeNumber"] == "0") {
                            convertPlaybackStreamType = "main"
                        } else if (element["playbackStreamTypeNumber"] == "1") {
                            convertPlaybackStreamType = "sub"
                        } else if (element["playbackStreamTypeNumber"] == "2") {
                            convertPlaybackStreamType = "ter"
                        }
                    }


                    // newList.push([element["brandName"], element["privateIPAddress"], element["communicationPort"],element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], element["liveStreamTypeNumber"], element["playbackStreamTypeNumber"],element["playbackChannelNumber"], element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
                    if(MULTI_TIME_ZONE)
                    {
                        newList.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"], element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"], element["isTwoWayEnabled"],element["ptz"],element["timezone"],element["message"]])
                    }else{
                        newList.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"], element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"], element["isTwoWayEnabled"],element["ptz"],element["message"]]) 

                    }
                   
                    // newList.push([element["brand"], element["ipaddress"], element["communicationport"], element["username"], element["password"], element["port"], element["channelnumber"], element["cameraName"], element["branch"], element["enablelivetranscoding"], element["message"]])
                }

                // Success Count
                // newList2.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype", "playbackstreamtype","playbackchannelNumber", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype", "enabletwowaydevice","reason"])
                if(MULTI_TIME_ZONE){
                    newList2.push(["brand", "ipaddress", "communicationport", "httpport", "username", "password", "port", "channelnumber", "livestreamtype", "playbackstreamtype", "devicename", "branch", "enablelivetranscoding", "enableplaybacktranscoding"/* , "interfacename" */, "cctvtype", "enabletwowaydevice","ptz", "timezone","reason"])
 
                }else{
                    newList2.push(["brand", "ipaddress", "communicationport", "httpport", "username", "password", "port", "channelnumber", "livestreamtype", "playbackstreamtype", "devicename", "branch", "enablelivetranscoding", "enableplaybacktranscoding"/* , "interfacename" */, "cctvtype", "enabletwowaydevice","ptz", "reason"])
                }
            
                for (let i = 0; i < successList.length; i++) {
                    const element = successList[i];
                    let covertLiveStreamType = "";
                    let convertPlaybackStreamType = "";

                    if (element["brandName"].toLowerCase() == "hikvision" || element["brandName"].toLowerCase() == "hikvision-hybrid") {
                        if (element["liveStreamTypeNumber"] == "01") {
                            covertLiveStreamType = "main"
                        } else if (element["liveStreamTypeNumber"] == "02") {
                            covertLiveStreamType = "sub"
                        } else if (element["liveStreamTypeNumber"] == "03") {
                            covertLiveStreamType = "ter"
                        }

                        if (element["playbackStreamTypeNumber"] == "01") {
                            convertPlaybackStreamType = "main"
                        } else if (element["playbackStreamTypeNumber"] == "02") {
                            convertPlaybackStreamType = "sub"
                        } else if (element["playbackStreamTypeNumber"] == "03") {
                            convertPlaybackStreamType = "ter"
                        }
                    } else {
                        if (element["liveStreamTypeNumber"] == "0") {
                            covertLiveStreamType = "main"
                        } else if (element["liveStreamTypeNumber"] == "1") {
                            covertLiveStreamType = "sub"
                        } else if (element["liveStreamTypeNumber"] == "2") {
                            covertLiveStreamType = "ter"
                        }

                        if (element["playbackStreamTypeNumber"] == "0") {
                            convertPlaybackStreamType = "main"
                        } else if (element["playbackStreamTypeNumber"] == "1") {
                            convertPlaybackStreamType = "sub"
                        } else if (element["playbackStreamTypeNumber"] == "2") {
                            convertPlaybackStreamType = "ter"
                        }
                    }

                    // newList2.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"],element["liveStreamTypeNumber"], element["playbackStreamTypeNumber"], element["playbackChannelNumber"], element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
                   if(MULTI_TIME_ZONE){
                    newList2.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"], element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"], element["isTwoWayEnabled"],element["ptz"],element["timezone"],element["message"]])
                   }else{
                    newList2.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"], element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"], element["isTwoWayEnabled"],element["ptz"],element["message"]])
                   }
                    
                    // newList.push([element["brand"], element["ipaddress"], element["communicationport"], element["username"], element["password"], element["port"], element["channelnumber"], element["cameraName"], element["branch"], element["enablelivetranscoding"], element["message"]])
                }

                if (newList && newList.length > 1) {
                    setFaildListToDownload(newList)
                }
                if (newList2 && newList2.length > 1) {
                    setSucessListToDownload(newList2)
                }

            }
        }


    }, [deviceBulkUploadResponse]);
    //region useEffect start

    //#endregion
    const handleUploadCSVClick = (event) => {
        // debugger
        event.preventDefault()
        hiddenCamFileInput.current.click();
    };

    const handleUploaderChange = (event) => {
        try {
            event.preventDefault()
            setImportedFileData(null);
            Array.from(event.target.files)
                .filter(
                    (file) =>
                        file.type === "text/csv" || file.type === "application/vnd.ms-excel"
                )
                .forEach(async (file) => {

                    let camHeadObj = {
                        brand: null,
                        ipaddress: null,
                        communicationport: null,
                        httpport: null,
                        username: null,
                        password: null,
                        port: null,
                        channelnumber: null,
                        livestreamtype: null,
                        playbackstreamtype: null,
                        devicename: null,
                        branch: null,
                        enablelivetranscoding: null,
                        enableplaybacktranscoding: null,
                        cctvtype: null,
                        enabletwowaydevice: null,
                        ptz:null
                    };
                    if(MULTI_TIME_ZONE){
                        camHeadObj.timezone = null 
                    }

                    setSelectedCamHeaders(camHeadObj);
                    const text = await file.text();
                    // const result1 = parse(text, { header: true });
                    // const head = pars

                    //*********tej 27-02-2023********* */
                    // parse the CSV data into an array of arrays using PapaParse
                    const parsedData = Papa.parse(text).data;
                    // filter out empty or comma-separated rows from the parsed data
                    const result = parsedData.filter(row => {
                        const lastElement = row[row.length - 1];
                        if (lastElement === '' || lastElement === null || lastElement === undefined) {
                            row.pop();
                        }
                        return row.join('').trim() !== '';
                    });
                    // format the filtered data back into CSV format using PapaParse
                    // const filteredCsv = Papa.unparse(filteredData);
                    //****************** */

                    setTotalDevices(null)
                    setCamImportedFileName(null)
                    setImportedFileHeaders(null)
                    setImportedFileData(null)
                    setFailedDevices(null)
                    setSuccessDevices(null)

                    const actualData = result.slice(1, result.length);
                    setTotalDevices(actualData.length)
                    setCamImportedFileName(file['name'])
                    setDeviceBulkUploadResponse([])
                    if (result && actualData) {
                        setImportedFileHeaders(result[0]);
                        const dataToUpload = actualData;
                        setImportedFileData(dataToUpload);
                    }
                });
            // reset the value of the file input element to an empty string
            event.target.value = "";
        } catch (error) {
            console.error("handleUploaderChange", error)
        }
    };

    const headers = [
        { label: "brand", key: "brandName" },
        { label: "ipaddress", key: "privateIPAddress" },
        { label: "username", key: "userName" },
        { label: "Password", key: "password" },
        { label: "Port", key: "port" },
        { label: "channelnumber", key: "channelNumber" },
        { label: "livestreamtype", key: "livestreamtype" },
        { label: "playbackstreamtype", key: "playbackstreamtype" },
        // { label: "playbackchannelnumber", key: "playbackchannelNumber" },
        { label: "devicename", key: "cameraName" },
        { label: "branch", key: "siteName" },
        { label: "timezone", key: "timezone" },
        {label:"ptz",key:"ptz"}
    ];

    return (
        <>
            <div>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Go to back
                        </Tooltip>
                    }
                >
                    <button className="DataAccessEdit"
                        onClick={() => {
                            setBulkUpload(false);
                            setIsSingleOrBulkSelection("single_device");
                        }} >
                        <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
                    </button>
                </OverlayTrigger>


            </div>
            <div style={{ width: "75%", margin: "0 auto" }}>
                <div className="bulk-upload-msg-text" style={{ textAlign: "right", margin: "0px" }}>Click <a
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSample2New.csv"
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSampleData.csv"
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-Data.csv"
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-file.csv"
                    href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-file+V_2_1_0.csv"
                    download
                >
                    here
                </a> to download template</div>
                <div className="BulkUploadBrowseDiv">
                    <input
                        className="BulkUploadInput"
                        disabled={true}
                        value={camImportedFileName}
                    />
                    <button className="DataAccessEdit" style={{ width: "100px" }} onClick={handleUploadCSVClick}>
                        <span className="ViewUpdate">Browse</span>
                    </button>
                </div>
            </div>

            <div style={{ marginTop: "15px" }}>
                <div className="DeviceList">
                    <div>

                        <form
                            id="hook-form"
                            onSubmit={handleSubmit((e) => {
                                OnAddBulkDevicesClick(e, importedFileHeaders);
                            })}
                        >
                            <div className="DeviceTextBox DeviceListBox">
                                <Row className="header-width" style={{ margin: "0 auto" }}>
                                    <div className="Add_Devices add-devices-heading">
                                        <p>Match the headers</p>
                                    </div>

                                </Row>
                                <div className="lst-section-width">
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Brand Name<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultBrand && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultBrand={setDefaultBrand}
                                                    label="brand"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultBrand && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultBrand}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="brand"
                                                    setDefaultBrand={setDefaultBrand}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                IP Address<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultIp && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultIp={setDefaultIp}
                                                    label="ipaddress"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultIp && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultIp}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="ipaddress"
                                                    setDefaultIp={setDefaultIp}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Communication Port<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultCommunicationPort && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultCommunicationPort={setDefaultCommunicationPort}
                                                    label="communicationport"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultCommunicationPort && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultCommunicationPort}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="communicationport"
                                                    setDefaultCommunicationPort={setDefaultCommunicationPort}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Http Port<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultHttpPort && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultHttpPort={setDefaultHttpPort}
                                                    label="httpport"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultHttpPort && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultHttpPort}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="httpport"
                                                    setDefaultHttpPort={setDefaultHttpPort}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                User Name<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultUserName && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultUserName={setDefaultUserName}
                                                    label="username"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultUserName && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultUserName}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="username"
                                                    setDefaultUserName={setDefaultUserName}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Password<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultPassword && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultPassword={setDefaultPassword}
                                                    label="password"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultPassword && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultPassword}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="password"
                                                    setDefaultPassword={setDefaultPassword}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Port<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultPort && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultPort={setDefaultPort}
                                                    label="port"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultPort && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultPort}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="port"
                                                    setDefaultPort={setDefaultPort}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Channel Number<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultChannelNum && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultChannelNum={setDefaultChannelNum}
                                                    label="channelnumber"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultChannelNum && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultChannelNum}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="channelnumber"
                                                    setDefaultChannelNum={setDefaultChannelNum}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Live Stream Type<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultLiveStreamType && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultLiveStreamType={setDefaultLiveStreamType}
                                                    label="livestreamtype"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultLiveStreamType && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultLiveStreamType}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="livestreamtype"
                                                    setDefaultLiveStreamType={setDefaultLiveStreamType}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Playback Stream Type<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultPlaybackStreamType && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultPlaybackStreamType={setDefaultPlaybackStreamType}
                                                    label="playbackstreamtype"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultPlaybackStreamType && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultPlaybackStreamType}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="playbackstreamtype"
                                                    setDefaultPlaybackStreamType={setDefaultPlaybackStreamType}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Device Name<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultDeviceName && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultDeviceName={setDefaultDeviceName}
                                                    label="devicename"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultDeviceName && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultDeviceName}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="devicename"
                                                    setDefaultDeviceName={setDefaultDeviceName}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Branch<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultBranch && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultBranch={setDefaultBranch}
                                                    label="branch"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultBranch && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultBranch}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="branch"
                                                    setDefaultBranch={setDefaultBranch}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    {/*  Rupesh Start*/}
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Enable Live Transcoding to H.264<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultEnableTranscoding && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultEnableTranscoding={setDefaultEnableTranscoding}
                                                    label="enablelivetranscoding"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultEnableTranscoding && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultEnableTranscoding}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="enablelivetranscoding"
                                                    setDefaultEnableTranscoding={setDefaultEnableTranscoding}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    {/*  */}

                                    
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Enable Playback Transcoding to H.264<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultEnablePlaybackTranscoding && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultEnablePlaybackTranscoding={setDefaultEnablePlaybackTranscoding}
                                                    label="enableplaybacktranscoding"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultEnablePlaybackTranscoding && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultEnablePlaybackTranscoding}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="enableplaybacktranscoding"
                                                    setDefaultEnablePlaybackTranscoding={setDefaultEnablePlaybackTranscoding}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    {/*  */}
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Two way Enable Device<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultTwoWayEnableDevice && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultTwoWayEnableDevice={setDefaultTwoWayEnableDevice}
                                                    label="enabletwowaydevice"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultTwoWayEnableDevice && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultTwoWayEnableDevice}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="enabletwowaydevice"
                                                    setDefaultTwoWayEnableDevice={setDefaultTwoWayEnableDevice}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                CCTV Type<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultcctvtype && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultcctvtype={setDefaultcctvtype}
                                                    label="interfacename"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultcctvtype && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultcctvtype}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="interfacename"
                                                    setDefaultcctvtype={setDefaultcctvtype}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>
                              

                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                               PTZ<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultPtz && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultPtz={setDefaultPtz}
                                                    label="ptz"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultPtz && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultPtz}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="ptz"
                                                    setDefaultPtz={setDefaultPtz}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                    </Row>

                                    {
                                     MULTI_TIME_ZONE ?
                                    <Row className="lst-header-width">
                                        <div className="column">
                                            <p className="SiteNameText BulkUploadTextName ml-2">
                                                Timezone<span style={{ color: "red" }}> *</span>
                                            </p>
                                        </div>
                                        <div className="column1">
                                            {!defaultTimeZone && (
                                                <SelectHeaderEmpty
                                                    importedFileHeaders={importedFileHeaders}
                                                    setDefaultTimeZone={setDefaultTimeZone}
                                                    label="timezone"
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                            {defaultTimeZone && (
                                                <SelectHeaderCommon
                                                    defaultVal={defaultTimeZone}
                                                    importedFileHeaders={importedFileHeaders}
                                                    label="timezone"
                                                    setDefaultTimeZone={setDefaultTimeZone}
                                                    selectedCamHeaders={selectedCamHeaders}
                                                />
                                            )}
                                        </div>
                                        
                                    </Row>
                                    :
                                    null
}
     
                                </div>
                                <div
                                    className="BulkText1 AlignBulk"
                                >
                                    <input
                                        type="file"
                                        multiple={false}
                                        ref={hiddenCamFileInput}
                                        onChange={handleUploaderChange}
                                        style={{ display: "none" }}
                                        value=""
                                    />
                                </div>
                                <p style={{ textAlign: "center" }}>

                                    {camImportedFileName && totalDevices && deviceBulkUploadResponse &&
                                        !(deviceBulkUploadResponse['CameraSuccessList'] || deviceBulkUploadResponse['CameraFailedList']) &&
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={<Tooltip>{camImportedFileName}</Tooltip>}>
                                            <span className="bulk-upload-msg-text">({totalDevices} Devices)</span>
                                        </OverlayTrigger>
                                    }
                                    {deviceBulkUploadResponse && failedDevices && failedDevices != 0 && totalDevices &&
                                        <span className="bulk-upload-msg-text template-name">
                                            <CSVLink filename={"CameraBulkUploadFailedList.csv"} className="mendat-fields" data={faildListToDownload}>{failedDevices} /
                                                {totalDevices} Failed Uploads</CSVLink> </span>

                                    }
                                    {deviceBulkUploadResponse && successDevices && successDevices != 0 && totalDevices &&
                                        <span className="bulk-upload-msg-text template-name">
                                            <CSVLink filename={"CameraBulkUploadSuccessList.csv"}
                                                className="mendat-fields"
                                                data={sucessListToDownload}>
                                                {successDevices} /{totalDevices} Uploads Successful
                                            </CSVLink>
                                        </span>
                                    }

                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
});

export default CameraBulkUpload;