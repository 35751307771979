import React from 'react'
import { postDataAccess, updateDataAccess } from '../../../services/dataAccessService'
import { SuccessToast } from '../../../CustomHooks/SuccessToast'
import { ErrorToast } from '../../../CustomHooks/ErrorToast'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap'
import { Edit, Save, Cancel, AddNode, DeleteWhite } from '../../../assets/images'
import { WarningToast } from '../../../CustomHooks/WarningToast'

let templateObj = {
    Description: "",
    EventAssociated: "",
    AlertCodeId: 0,
    EventCodeWithName: "",
    EventGroupId: 0,
    EventGroupTitle: "",
    AlertTemplateId: 0,
    FromTime: "00:00",
    FullTime: false,
    TemplateName: "",
    Instruction: "",
    SeverityId: 0,
    SeverityTitle: "",
    ToTime: "00:00",
    DeviceTypeId: 0,
    ClientId: 0,
    IDSPanelZoneTypeId: 0,
    SiteId: 0,
    DeviceId: 0,
    WorkflowId: 0
}

let newAlertTemplateObj = {
    templateId : "",
    templateName: "",
    templateClientId: "0",
    templateSiteId: [],
    templateDeviceTypeId: "0",
    templateDeviceSubTypeId: "0",
    templateDevice: [],
    templateAlertType: "0",
    scheduleType: "NA",
    schedulerObj : {},
    tempSelectedSites:[],
    tempSelectedDevices:[],
    // templateAlertCodeId:"",
    // templateDescription:"",
    // templateCreatedOn:"",
    // templateCreatedBy:"",
    // templateModifiedOn:"",
    // templateModifiedBy:"",
    // LstDeviceAlertTemplateConfig:[],

}

const ConfigHeader = ({ selectedMenu, selectedSubMenu, nodes, isNodeSelected, selectedNode, disableAdd, setDisableAdd, editMode, setEditMode, fetchPayload, setIsNodeSelected, addNewClicked,  setAddNewClicked, setIsNotifFormEnabled, isNotifFormEnabled, setNotifDeleteBtnClicked, setNotifSaveBtnClicked, setIsEmptyForm, isEmptyForm, setInEditMode, inEditMode, setShowEditIcon, showEditIcon, isAlertTempFormEnabled, isEmptyFormAlert, setIsEmptyFormAlert, setIsAlertTempFormEnabled, setAddNewClickedAlert, 
    setSelectedTemplate,
    setIsAlertTemplateEdit,
    setAlertDeleteBtnClicked,
    setAlertSaveBtnClicked,
    deleteAlertTemplate,
    setAlertTemplateObj,
    displayButtons,
    setIsStore,
    deviceTemplateList,
    selTempDevice,
    activityLogShow,
    setActivityLogShow,
    getAlertTemplateById,
    alertTemplateObj,
    DeleteAlertTemplate2,
    buttonDisable,
    setButtonDisable

}) => {

    const getPosition = (arr, id) => {
        let xPos = 10;
        let yPos = 20;
        let data = arr.filter((item) => item.id == id);
        xPos = data[0].position.x;
        yPos = data[0].position.y;
        return { xPos, yPos }
    }

    const addDataAccess = () => {
        setButtonDisable(true)
        if (selectedNode === null) {
            WarningToast("Please select a data access group to save or update it.")
            setButtonDisable(false)
            return;
        }

        if (selectedNode.type == "input") {
            WarningToast("You cannot update the admin data access group.");
            setButtonDisable(false)
            return;
        }

        if (selectedNode.data.label.props.children[0].props.children[0].props.children === "") {
            setButtonDisable(false)
            WarningToast("Data access group name cannot be empty.");
            return;
        }

        if (selectedNode.data.label.props.children[0].props.children[1].props.children === "") {
            setButtonDisable(false)
            WarningToast("Data access group description cannot be empty.");
            return;
        }

        let tempObj = fetchPayload();
        if (isNodeSelected && !disableAdd) {
            let tempNodes = nodes.filter((item) => item.id == selectedNode.id);
            let { xPos, yPos } = getPosition(tempObj, selectedNode.id);
            let payloadObj = {
                id: parseInt(selectedNode.id),
                parentId: tempNodes[0].parentId == 1 ? 0 : tempNodes[0].parentId,
                name: tempNodes[0].data.label.props.children[0].props.children[0].props.children,
                description: tempNodes[0].data.label.props.children[0].props.children[1].props.children,
                position_X: xPos.toString(),
                position_Y: yPos.toString(),
                fileName: tempNodes[0].data.label.props.children[0].props.children[0].props.children + "_" + localStorage.getItem('authCode'),
            }
            updateDataAccess(payloadObj)
                .then((resp) => {
                    if (resp.Status === "success") {
                        setButtonDisable(false)
                        SuccessToast(resp.Message);
                        setEditMode(!editMode);
                        setIsNodeSelected(false);
                    } else {
                        setButtonDisable(false)
                        ErrorToast(resp.Message);
                        setEditMode(!editMode);
                        setIsNodeSelected(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else {
            let nodeCount = 0;
            let edgeCount = 0;
            for (let x = 0; x < nodes.length; x++) {
                if (nodes[x].type == "default" || nodes[x].type == "input") {
                    nodeCount++;
                } else if (nodes[x].type == "edge") {
                    edgeCount++;
                }
            }
            if (edgeCount !== (nodeCount - 1)) {
                WarningToast("Kindly inherit the data access group from other data access group");
                return;
            }

            let lastNode;
            let parentId;
            let lastNodeId;
            /* ----------------- First we need to get the last node data ---------------- */
            for (let x = nodes.length - 1; x > 0; x--) {
                if (nodes[x].type == "default") {
                    lastNodeId = nodes[x].id
                    parentId = nodes[x].parentId
                    lastNode = nodes[x];
                    break;
                }
            }

            /* ------ Then based on the last edge we will try to get the parent id ------ */
            let sourceId = 0;
            let tempParentNodeId = 0;
            for (let x = nodes.length - 1; x > 0; x--) {
                if (nodes[x].type == "edge") {
                    sourceId = nodes[x].source;
                    break;
                }
            }
            let { xPos, yPos } = getPosition(tempObj, lastNodeId);
            let newObj = {
                id: -1,
                parentId: parseInt(sourceId) == 1 ? 0 : parseInt(sourceId),
                name: lastNode.data.label.props.children[0].props.children[0].props.children,
                description: lastNode.data.label.props.children[0].props.children[1].props.children,
                position_X: xPos.toString(),
                position_Y: yPos.toString(),
                fileName: lastNode.data.label.props.children[0].props.children[0].props.children + "_" + localStorage.getItem('authCode'),
            }
            postDataAccess(newObj)
                .then(resp => {
                    if (resp.Status == "success") {
                        setButtonDisable(false)
                        console.log("ConfigHeader obj", resp)
                        SuccessToast(resp.Message);
                        setDisableAdd(false);
                        setEditMode(!editMode);
                        setIsNodeSelected(false);
                    } else {
                        setButtonDisable(false)
                        ErrorToast(resp.Message);
                    }
                })
                .catch(err => {
                    console.log("ConfigHeader obj", err);
                })
        }
    }

    return <div className='row col-lg-12' style={{ margin: "0px auto", padding: "0px" }}>
        <div className="col-lg-12 dashboardHeadingInnerDiv"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div className="NameHeading1"
            >
                <p className='config-tool-head'>
                    {
                        selectedMenu == "Notification Setting"
                         || "Alert Pre Post Setting"
                            ? <>Configuration Tool &gt; {selectedMenu}</> : <>Configuration Tool &gt; {selectedMenu} &gt; {selectedSubMenu}</>
                    }
                </p>

            </div>
            {
                selectedMenu === "Users" && selectedSubMenu === "Data Access Group"
                    ? (
                        <div>
                            {
                                !editMode
                                    ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Edit
                                            </Tooltip>
                                        }
                                    >
                                        <button className="DataAccessEdit" onClick={() => { setEditMode(!editMode) }}>
                                            <img src={Edit} className="EditedIcon" alt='edit icon' />
                                        </button>
                                    </OverlayTrigger>
                                    :
                                    <>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Save
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" onClick={addDataAccess} disabled={buttonDisable}>
                                                <img src={Save} className="EditedIcon" alt='save icon' />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Cancel
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" onClick={() => {
                                                setEditMode(!editMode)
                                                setDisableAdd(false)
                                            }
                                            }>
                                                <img src={Cancel} className="EditedIcon" alt='cancel icon' />
                                            </button>
                                        </OverlayTrigger>
                                    </>
                            }
                        </div>
                    )
                    : null
            }
            {
                selectedMenu === "Notification Setting"
                    ? (
                        <div>
                            {
                                !isNotifFormEnabled && !isEmptyForm
                                    ? <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => {
                                        setAddNewClicked(true)
                                        setIsEmptyForm(true);
                                        setIsNotifFormEnabled(true) 
                                    } }>
                                        <img src={AddNode} alt='add new form' />
                                        <span className="ViewUpdate ml-2">Add New</span>
                                    </button>
                                    : null
                            }
                            {
                                !isNotifFormEnabled && !isEmptyForm 
                                    ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        trigger={['hover']}
                                        overlay={
                                            <Tooltip>
                                                Delete
                                            </Tooltip>
                                        }
                                    >
                                        <button className="DataAccessEdit" onClick={() => setNotifDeleteBtnClicked(true)} disabled={buttonDisable}>
                                            <img src={DeleteWhite} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' />
                                        </button>
                                    </OverlayTrigger>
                                    : null
                            }
                            {
                                !isNotifFormEnabled && !isEmptyForm 
                                    ? <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Edit
                                        </Tooltip>
                                    }
                                >
                                    <button className="DataAccessEdit" onClick={() => {
                                        setIsNotifFormEnabled(true)
                                    }}>
                                        <img src={Edit} className="EditedIcon" alt='edit icon' />
                                    </button>
                                </OverlayTrigger>
                                    : null
                            }
                            {
                                isEmptyForm && isNotifFormEnabled &&
                                <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Save
                                    </Tooltip>
                                }
                            >
                                <button className="DataAccessEdit" onClick={() => setNotifSaveBtnClicked(true)} disabled={buttonDisable}
                                >
                                    <img src={Save} className="EditedIcon" alt='save icon' />
                                </button>
                            </OverlayTrigger>
                            }
                            {
                                isNotifFormEnabled && !isEmptyForm 
                                    ?
                                    <>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Update
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" onClick={() => setNotifSaveBtnClicked(true)}  disabled={buttonDisable}>
                                                <img src={Save} className="EditedIcon" alt='save icon' />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Cancel
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" onClick={() => setIsNotifFormEnabled(false)}>
                                                <img src={Cancel} className="EditedIcon" alt='cancel icon' />
                                            </button>
                                        </OverlayTrigger>
                                    </>
                                    : null
                            }
                            {/* } */}
                        </div>
                    )
                    : null
            }

            {
                selectedMenu === "Alert Template" && selectedSubMenu === "Add Alert Template" && displayButtons && !activityLogShow 
                    ? (
                        <div>
                            {
                                !isAlertTempFormEnabled && !isEmptyFormAlert && deviceTemplateList.length == 0
                                ?
                                <button className="DataAccessEdit" style={{ width: "100px" }} 
                                    onClick={() => {
                                        setAddNewClickedAlert(true);
                                        setIsEmptyFormAlert(true);
                                        setIsAlertTempFormEnabled(true);
                                        setSelectedTemplate(templateObj)
                                        setAlertTemplateObj(newAlertTemplateObj);
                                        setIsAlertTemplateEdit(true)
                                        setIsStore(false);
                                        setActivityLogShow(false);
                                    }}
                                >
                                        <img src={AddNode} alt='add new form' />
                                        <span className="ViewUpdate ml-2">Add New</span>
                                    </button>
                                : null
                            }
                            {
                                !isAlertTempFormEnabled && !isEmptyFormAlert
                                ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        trigger={['hover']}
                                        overlay={
                                            <Tooltip>
                                                Delete
                                            </Tooltip>
                                        }
                                    >
                                        <button className="DataAccessEdit" disabled={buttonDisable} onClick={() => {
                                            setAlertDeleteBtnClicked(true);
                                            deleteAlertTemplate()
                                        }}>
                                            <img src={DeleteWhite} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' />
                                        </button>
                                    </OverlayTrigger>
                                : null
                            }
                            {
                                !isAlertTempFormEnabled && !isEmptyFormAlert
                                ?
                                     <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            Edit
                                        </Tooltip>
                                    }
                                >
                                    <button className="DataAccessEdit" onClick={() => { setIsAlertTemplateEdit(true); setIsAlertTempFormEnabled(true)}}>
                                        <img src={Edit} className="EditedIcon" alt='edit icon' />
                                    </button>
                                </OverlayTrigger>
                                : null
                            }
                            {
                                isEmptyFormAlert && isAlertTempFormEnabled &&
                                <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Save
                                    </Tooltip>
                                }
                            >
                                <button className="DataAccessEdit" disabled={buttonDisable} onClick={() => setAlertSaveBtnClicked(true)}>
                                    <img src={Save} className="EditedIcon" alt='save icon' />
                                </button>
                            </OverlayTrigger>
                            }
                            {
                                isAlertTempFormEnabled && !isEmptyFormAlert
                                ?
                                    <>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Update
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" disabled={buttonDisable} onClick={() => {
                                                setAlertSaveBtnClicked(true)
                                            }}>
                                                <img src={Save} className="EditedIcon" alt='save icon' />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Cancel
                                                </Tooltip>
                                            }
                                        >
                                            <button className="DataAccessEdit" onClick={() => {
                                                getAlertTemplateById(alertTemplateObj.templateId);
                                                setIsAlertTemplateEdit(false)
                                                setIsAlertTempFormEnabled(false)
                                            }}>
                                                <img src={Cancel} className="EditedIcon" alt='cancel icon' />
                                            </button>
                                        </OverlayTrigger>
                                    </>
                                : null
                            }
                        </div>
                    )
                    : null
            }
            
        </div>
    </div>



}

export default ConfigHeader