
import React from 'react'

const DeviceEventReportList = ({item}) => {
  return (
    <tr>
    <td>{item.CustomerName}</td>
    <td>{item.SiteName}</td>
    <td>{item.DeviceName}</td>
    <td>{item.EventCode}</td>
    <td>{item.DeviceType}</td>
    <td>{item.EventType}</td>
    <td>{item.EventDescription}</td>
    <td>{item.StatusDateTime}</td>
</tr>
  )
}

export default DeviceEventReportList

