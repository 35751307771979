import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { EditShape, WFBullet, Eye, Cross, DeleteQc, EditQc, DropArrow, SearchIcon, Filter, BackArrowDevice, SearchIconWhite, RetailStore2, RetailStore2White } from '../../../assets/images'
import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'reactjs-popup/dist/index.css';

import Swal from 'sweetalert2'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Popup from 'reactjs-popup'
import { MultiSelect } from "react-multi-select-component";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";

import { parseTimeIntoDate } from '../../../utils/utils'
import Scheduler from './Scheduler'
import ActivityLogComponent from './ActivityLogComponent'
import { useDataAccessPagination } from '../../../CustomHooks/useDataAccessPagination'
import { AlertTemplateList } from './AlertTemplateList'
import { GetDataToFilter } from '../../../services/configurationServices'
import AlertTemplateForm from './AlertTemplateForm'

export default function AlertTemplateStep({
    pageState,
    selectedTemplate,
    onSubmitTemplate,
    onSubmitDeviceTemplate,
    setIsFullTime,
    setFromTime,
    setToTime,
    severities,
    setSelectedSeverity,
    onSelectTemplate,
    onDeleteTemplate,
    workflows,
    onFinish,
    onPreviewWF,
    isAlertTemplateEdit,
    setIsAlertTemplateEdit,
    alertTemplateRef,
    filteredEventTemplates,
    onSearchChange,
    devicesmasterlist,
    devicetypeid,
    setdevicetypeid,
    siteList,
    deviceList,
    setDeviceList,
    getSiteList,
    getDeviceList,
    alertTemplateList,
    GetAlertTemplateList,
    alertSaveBtnClicked,
    getAlertTemplateById,
    alertTemplateObj,
    totalTemplateCount,
    totalDeviceCount,
    isAlertUpdated,
    setIsAlertUpdated,
    getAlertTemplateByDeviceId,
    deviceTemplateList,
    devTemClicked,
    setDevTemClicked,
    selTempDevice,
    setSelTempDevice,
    setDeviceTemplateList,
    getCustomerData,
    customerData,
    isAlertTempFormEnabled,
    isStore,
    setDisplayButtons,
    GetTemplateDataToFilter,
    activityLogShow,
    setActivityLogShow,
    isDarkTheme,
    setAlertTemplateObj,
    setAddNewClickedAlert,
    setIsEmptyFormAlert,
    setIsAlertTempFormEnabled,
    setIsStore,
    getZonesAT,
    zoneList,
    templateDelete,
    validationError,
    savebtnClick,
    setSavebtnClick,
    setInputValidationError
}) {
    let history = useHistory()

    //region useState Start
    const [siteSelected, setSiteSelected] = useState([]);
    const [deviceSelected, setDeviceSelected] = useState([]);
    const [masterCustomerList, setmasterCustomerList] = useState([]);
    const [masterSiteList, setmasterSiteList] = useState([]);
    const [masterDeviceTypeList, setmasterDeviceTypeList] = useState([]);
    const [masterAlertTypeList, setmasterAlertTypeList] = useState([]);
    const [filtercustomerList, setFilterCustomerList] = useState([]);
    const [filterSiteList, setFilterSiteList] = useState([]);
    const [filterDeviceTypeList, setFilterDeviceTypeList] = useState([]);
    const [filterAlertTypeList, setFilterAlertTypeList] = useState([]);
    const [customerSelectAll, setCustomerSelectAll] = useState(false);
    const [siteSelectAll, setSiteSelectAll] = useState(false);
    const [deviceTypeSelectAll, setDeviceTypeSelectAll] = useState(false);
    const [alertTypeSelectAll, setAlertTypeSelectAll] = useState(false);
    const [customerSearch, setCustomerSearch] = useState("");
    const [siteSearch, setSiteSearch] = useState("");
    const [deviceTypeSearch, setDeviceTypeSearch] = useState("");
    const [alertTypeSearch, setAlertTypeSearch] = useState("")
    const [filterCount, setFilterCount] = useState(0)
    const [issearch, setissearch] = useState(true)
    //region useState End

    const onSelectWorkflow = (id) => {
        history.push(`/workflow/${id}`)
    }

    const toggleSearch = () => {
        try {
            setissearch(!issearch)
            document.getElementById("Search_txt").value = ""
            onSearchChange("", "Alert Template")
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="col-lg-12 mt-2" style={{ height: "calc(100% - 80px)" }}>
                {
                    pageState == "Add Alert Template" &&
                    <>
                        <AlertTemplateForm
                            selectedTemplate={selectedTemplate}
                            onSubmitTemplate={onSubmitTemplate}
                            onSubmitDeviceTemplate={onSubmitDeviceTemplate}
                            setIsFullTime={setIsFullTime}
                            setFromTime={setFromTime}
                            setToTime={setToTime}
                            severities={severities}
                            setSelectedSeverity={setSelectedSeverity}
                            isAlertTemplateEdit={isAlertTemplateEdit}
                            setIsAlertTemplateEdit={setIsAlertTemplateEdit}
                            ref={alertTemplateRef}
                            devicesmasterlist={devicesmasterlist}
                            devicetypeid={devicetypeid}
                            setdevicetypeid={setdevicetypeid}
                            siteList={siteList}
                            deviceList={deviceList}
                            setDeviceList={setDeviceList}
                            getSiteList={getSiteList}
                            siteSelected={siteSelected}
                            setSiteSelected={setSiteSelected}
                            getDeviceList={getDeviceList}
                            deviceSelected={deviceSelected}
                            setDeviceSelected={setDeviceSelected}
                            alertTemplateList={alertTemplateList}
                            alertSaveBtnClicked={alertSaveBtnClicked}
                            workflows={workflows}
                            getAlertTemplateById={getAlertTemplateById}
                            alertTemplateObj={alertTemplateObj}
                            GetAlertTemplateList={GetAlertTemplateList}
                            totalTemplateCount={totalTemplateCount}
                            totalDeviceCount={totalDeviceCount}
                            isAlertUpdated={isAlertUpdated}
                            setIsAlertUpdated={setIsAlertUpdated}
                            getAlertTemplateByDeviceId={getAlertTemplateByDeviceId}
                            deviceTemplateList={deviceTemplateList}
                            devTemClicked={devTemClicked}
                            setDevTemClicked={setDevTemClicked}
                            selTempDevice={selTempDevice}
                            setSelTempDevice={setSelTempDevice}
                            setDeviceTemplateList={setDeviceTemplateList}
                            getCustomerData={getCustomerData}
                            customerData={customerData}
                            isAlertTempFormEnabled={isAlertTempFormEnabled}
                            isStore={isStore}
                            setDisplayButtons={setDisplayButtons}
                            filtercustomerList={filtercustomerList}
                            setFilterCustomerList={setFilterCustomerList}
                            filterSiteList={filterSiteList}
                            setFilterSiteList={setFilterSiteList}
                            filterDeviceTypeList={filterDeviceTypeList}
                            setFilterDeviceTypeList={setFilterDeviceTypeList}
                            filterAlertTypeList={filterAlertTypeList}
                            setFilterAlertTypeList={setFilterAlertTypeList}
                            GetTemplateDataToFilter={GetTemplateDataToFilter}
                            customerSearch={customerSearch}
                            setCustomerSearch={setCustomerSearch}
                            siteSearch={siteSearch}
                            setSiteSearch={setSiteSearch}
                            deviceTypeSearch={deviceTypeSearch}
                            setDeviceTypeSearch={setDeviceTypeSearch}
                            alertTypeSearch={alertTypeSearch}
                            setAlertTypeSearch={setAlertTypeSearch}
                            masterCustomerList={masterCustomerList}
                            setmasterCustomerList={setmasterCustomerList}
                            masterSiteList={masterSiteList}
                            setmasterSiteList={setmasterSiteList}
                            masterDeviceTypeList={masterDeviceTypeList}
                            setmasterDeviceTypeList={setmasterDeviceTypeList}
                            masterAlertTypeList={masterAlertTypeList}
                            setmasterAlertTypeList={setmasterAlertTypeList}
                            customerSelectAll={customerSelectAll}
                            setCustomerSelectAll={setCustomerSelectAll}
                            siteSelectAll={siteSelectAll}
                            setSiteSelectAll={setSiteSelectAll}
                            deviceTypeSelectAll={deviceTypeSelectAll}
                            setDeviceTypeSelectAll={setDeviceTypeSelectAll}
                            alertTypeSelectAll={alertTypeSelectAll}
                            setAlertTypeSelectAll={setAlertTypeSelectAll}
                            filterCount={filterCount}
                            setFilterCount={setFilterCount}
                            activityLogShow={activityLogShow}
                            setActivityLogShow={setActivityLogShow}
                            isDarkTheme={isDarkTheme}
                            setAlertTemplateObj={setAlertTemplateObj}
                            setAddNewClickedAlert={setAddNewClickedAlert}
                            setIsEmptyFormAlert={setIsEmptyFormAlert}
                            setIsAlertTempFormEnabled={setIsAlertTempFormEnabled}
                            setIsStore={setIsStore}
                            getZonesAT={getZonesAT}
                            zoneList={zoneList}
                            templateDelete={templateDelete}
                            validationError={validationError}
                            savebtnClick={savebtnClick}
                            setSavebtnClick={setSavebtnClick}
                            setInputValidationError={setInputValidationError}
                        />
                    </>
                }

                {
                    pageState == "Manage Workflow" &&
                    <>
                        {
                            <>
                                <div >
                                    <div className='LeftAddSideHeight'>
                                        <div className="AddUser">
                                            <h1 className="MainHeaderName">1. Add Workflow</h1>
                                            <div className="wf-list">
                                                {workflows &&
                                                    workflows.map(wf => {
                                                        return <div className="wf-list-item" key={wf.workflowID} onClick={() => onSelectWorkflow(wf.workflowID)}>
                                                            <div className="col-lg-1">
                                                                <img src={WFBullet} alt="workflow bullet" />
                                                            </div>
                                                            <div className="col-lg-5 template-name">
                                                                <span>{wf.workflowName}</span>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <a style={{ display: "inline-flex" }} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    onPreviewWF(wf.workflowID)
                                                                }}>
                                                                    <span>Preview Workflow</span>
                                                                </a>
                                                            </div>
                                                            <div className="col-lg-1">
                                                                <img className="EyeIcon" alt='view icon' src={Eye} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    onPreviewWF(wf.workflowID)
                                                                }} />
                                                            </div>
                                                            <div className="col-lg-1">
                                                                <img className="EyeIcon" alt='edit icon' src={EditQc} />
                                                            </div>
                                                        </div>
                                                    })}
                                            </div>
                                            <div className='workFlow-btn-container'>
                                                <div className="AddMore alertTemplate-addWorkFlow-addMore-btn">
                                                    <a href="#" className="UserAddPlus" onClick={() => history.push('/workflow/0')}>Add More +</a>
                                                </div>
                                                <div className="ButtonDiv alertTemplate-addWorkFlow-next-btn-div">
                                                    <button className="AlertViewButton  mr-3" hidden>
                                                        <p>Previous</p>
                                                    </button>
                                                    <button className="NextMoveButton" onClick={() => onFinish('users')}>
                                                        <span className="ViewUpdate">Next</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }

                {
                    pageState == "Manage Workflow" ?
                        <div className="RightListSide" style={{display:"none"}}>
                            <div className="HeaderSiteList m-2">
                                {issearch && <p className="active-all-light active-block active-text button-common-margin">Alert Template List</p>}
                                {!issearch &&
                                    <input id="Search_txt" autoFocus placeholder="Search Alert Template" className="SearchViewInput1" type="text" onChange={e => onSearchChange(e.target.value, 'Alert Template')} />
                                }
                                <div className="SearchTextDivConfigTool">
                                    {issearch &&
                                        <img className="EyeIcon" alt='search icon' src={SearchIcon} onClick={() => toggleSearch()} />}
                                    {!issearch && <img className="EyeIcon" alt='cancel icon' src={Cross} onClick={() => toggleSearch()} />}
                                </div>
                            </div>
                            <div className="SiteListMain">
                                {
                                    filteredEventTemplates && (
                                        filteredEventTemplates?.length > 0 ?
                                            filteredEventTemplates.map(template => {
                                                return <div className={selectedTemplate?.AlertTemplateId === template.AlertTemplateId ? "OneList SelectedList" : "OneList"} key={template.AlertTemplateId} onClick={() => onSelectTemplate(template, true)}>
                                                    <p className="ListofText">{template.TemplateName}
                                                        <span className="SiteNameText"> - {template.DeviceType}</span>
                                                    </p>
                                                    <div>
                                                        <img className="m-2" src={EditShape} alt='edit icon' />
                                                        <img src={DeleteQc} alt='delete icon' onClick={(e) => onDeleteTemplate(e, template.AlertTemplateId)} />
                                                    </div>
                                                </div>
                                            }) : <div className="no-data-found"><p>No Templates Found.</p></div>
                                    )
                                }
                                {
                                    !filteredEventTemplates &&
                                    <div class="no-report-found"><p class="noRecordFoundText">No Templates Available.</p></div>
                                }
                            </div>
                        </div> : ""
                }
            </div>
        </>
    )
}

// const AlertTemplateForm = React.forwardRef((props, ref) => {
//     let {
//         selectedTemplate,
//         onSubmitTemplate,
//         onSubmitDeviceTemplate,
//         setIsFullTime,
//         setFromTime,
//         setToTime,
//         severities,
//         setSelectedSeverity,
//         isAlertTemplateEdit,
//         setIsAlertTemplateEdit,
//         devicesmasterlist,
//         devicetypeid,
//         setdevicetypeid,
//         siteList,
//         deviceList,
//         setDeviceList,
//         getSiteList,
//         setSiteSelected,
//         siteSelected,
//         getDeviceList,
//         deviceSelected,
//         setDeviceSelected,
//         alertTemplateList,
//         alertSaveBtnClicked,
//         workflows,
//         getAlertTemplateById,
//         alertTemplateObj,
//         GetAlertTemplateList,
//         totalTemplateCount,
//         totalDeviceCount,
//         isAlertUpdated,
//         setIsAlertUpdated,
//         getAlertTemplateByDeviceId,
//         deviceTemplateList,
//         devTemClicked,
//         setDevTemClicked,
//         selTempDevice,
//         setSelTempDevice,
//         setDeviceTemplateList,
//         getCustomerData,
//         customerData,
//         isAlertTempFormEnabled,
//         isStore,
//         setDisplayButtons,
//         activityLogShow,
//         setActivityLogShow,
//         filtercustomerList,
//         setFilterCustomerList,
//         filterSiteList,
//         setFilterSiteList,
//         filterDeviceTypeList,
//         setFilterDeviceTypeList,
//         filterAlertTypeList,
//         setFilterAlertTypeList,
//         customerSearch,
//         setCustomerSearch,
//         siteSearch,
//         setSiteSearch,
//         deviceTypeSearch,
//         setDeviceTypeSearch,
//         alertTypeSearch,
//         setAlertTypeSearch,
//         masterCustomerList,
//         setmasterCustomerList,
//         masterSiteList,
//         setmasterSiteList,
//         masterDeviceTypeList,
//         setmasterDeviceTypeList,
//         masterAlertTypeList,
//         setmasterAlertTypeList,
//         customerSelectAll,
//         setCustomerSelectAll,
//         siteSelectAll,
//         setSiteSelectAll,
//         deviceTypeSelectAll,
//         setDeviceTypeSelectAll,
//         alertTypeSelectAll,
//         setAlertTypeSelectAll,
//         filterCount,
//         setFilterCount,
//         isDarkTheme,
//         setAlertTemplateObj,
//         setAddNewClickedAlert,
//         setIsEmptyFormAlert,
//         setIsAlertTempFormEnabled,
//         setIsStore,
//         getZonesAT,
//         zoneList,
//         templateDelete,
//         validationError,
//         savebtnClick,
//         setSavebtnClick
//     } = { ...props }

//     //region useState Start
//     const [selectedItem, setSelectedItem] = useState("");
//     const [dateCustom, setDateCustom] = useState(null);
//     const [selectedSiteTab, setSelectedSiteTab] = useState("no-alert");
//     const [sevenDaysData, setSevenDaysData] = useState([
//         {
//             "id": 0,
//             "day": "mon",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 1,
//             "day": "tue",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 2,
//             "day": "wed",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 3,
//             "day": "thu",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 4,
//             "day": "fri",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 5,
//             "day": "sat",
//             "dayResource": [],
//             "isEnabled": false
//         },
//         {
//             "id": 6,
//             "day": "sun",
//             "dayResource": [],
//             "isEnabled": false
//         },
//     ]);
//     const [customData, setCustomData] = useState([]);
//     const [templateModalFilter, setTemplateModalFilter] = useState(false);
//     const [templateNameError, setTemplateNameError] = useState('')
//     const [templateDeviceTypeError, setTemplateDeviceTypeError] = useState('')
//     const [templateAlertError, setTemplateAlertError] = useState('')
//     const [templateCustomerError, setTemplateCustomerError] = useState('')
//     const [templateSiteError, setTemplateSiteError] = useState('')
//     const [templateDeviceError, setTemplateDeviceError] = useState('')
//     const [templateSensorError, setTemplateSensorError] = useState('')
//     const [gettingData, setGettingData] = useState(false);
//     const [issensor, setissensor] = useState(false)
//     const [alertSearch, setAlertSearch] = useState("");
//     const [deviceSearch, setDeviceSearch] = useState("");
//     const [activeTab, setActiveTab] = useState("Template")
//     const [timer, setTimer] = useState(null);
//     const [alertTimer, setAlertTimer] = useState(null);
//     const [filterText, setfilterText] = useState("");
//     const [customerCount, setCustomerCount] = useState(0);
//     const [siteCount, setSiteCount] = useState(0);
//     const [deviceTypeCount, setDeviceTypeCount] = useState(0);
//     const [alertTypeCount, setalertTypeCount] = useState(0);
//     const [siteIndivSelected, setSiteIndivSelected] = useState("");
//     const [deviceIndivSelected, setDeviceIndivSelected] = useState("");
//     //region useState End

//     //region hook controls
//     const { register, handleSubmit, setValue, getValues, watch } = useForm({ ...selectedTemplate });
//     const [nextClickOne, prevClickOne, pageCounterOne, currentPageNoOne, limitOne, totalPagesOne, setCurrentPageNoOne, setTotalPagesOne, maxPageAllowedOne, setPageCounterOne] = useDataAccessPagination(10, 1, 10, 1);
//     const [nextClickTwo, prevClickTwo, pageCounterTwo, currentPageNoTwo, limitTwo, totalPagesTwo, setCurrentPageNoTwo, setTotalPagesTwo, maxPageAllowedTwo, setPageCounterTwo] = useDataAccessPagination(10, 1, 10, 1);
//     //endregion

//     //region useEffect Start
//     useEffect(() => {
//         if (savebtnClick) {
//             let templst = validationError.split(",");
//             for (let i = 0; i < templst.length; i++) {
//                 if (templst[i].includes("template name-")) {
//                     setTemplateNameError(templst[i].replace("template name-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("device type-")) {
//                     setTemplateDeviceTypeError(templst[i].replace("device type-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("alert type-")) {
//                     setTemplateAlertError(templst[i].replace("alert type-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("customer name-")) {
//                     setTemplateCustomerError(templst[i].replace("customer name-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("site-")) {
//                     setTemplateSiteError(templst[i].replace("site-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("device-")) {
//                     setTemplateDeviceError(templst[i].replace("device-", ""));
//                     continue
//                 }
//                 if (templst[i].includes("sensor-")) {
//                     setTemplateSensorError(templst[i].replace("sensor-", ""));
//                     continue
//                 }
//             }
//             setSavebtnClick(false)
//         }
//     }, [savebtnClick])

//     useEffect(() => {
//         if (templateModalFilter && filterCount == 0) {
//             GetTemplateDataToFilter("all", "")
//             setCustomerSelectAll(false);
//             setSiteSelectAll(false);
//             setDeviceTypeSelectAll(false);
//             setAlertTypeSelectAll(false)
//             setCustomerSearch("");
//             setSiteSearch("");
//             setDeviceTypeSearch("");
//             setAlertTypeSearch("");
//         }
//     }, [templateModalFilter]);

//     useEffect(() => {
//         setSelectedSeverity(selectedTemplate?.SeverityId)
//         Diplayformfields(selectedTemplate.DeviceTypeId)
//         setValue('TemplateName', selectedTemplate?.TemplateName)
//         setValue('AlertCodeId', selectedTemplate?.AlertCodeId)
//         setValue('Description', selectedTemplate?.Description)
//         setValue('SeverityId', selectedTemplate?.SeverityId)
//         setValue('FullTime', selectedTemplate?.FullTime)
//         setValue('DeviceTypeId', selectedTemplate?.DeviceTypeId)
//         setValue('ClientId', selectedTemplate?.ClientId)
//         setValue('IDSPanelZoneTypeId', selectedTemplate?.IDSPanelZoneTypeId)
//         setValue('SiteId', selectedTemplate.SiteId)
//         setValue('DeviceId', selectedTemplate.DeviceId)
//         setValue('WorkflowId', selectedTemplate.WorkflowId)
//         setIsFullTime(selectedTemplate?.FullTime)
//         if (selectedTemplate.FromTime != null &&
//             selectedTemplate.FromTime !== "" &&
//             selectedTemplate.FromTime != undefined) {
//             let dt = parseTimeIntoDate(selectedTemplate.FromTime);
//             setFromTime(dt);
//         }
//         if (selectedTemplate.ToTime != null &&
//             selectedTemplate.ToTime !== "" &&
//             selectedTemplate.ToTime != undefined) {
//             let dt = parseTimeIntoDate(selectedTemplate.ToTime);
//             setToTime(dt);
//         }

//     }, [selectedTemplate])

//     useEffect(() => {
//         console.log("alertTemplateObj", alertTemplateObj)
//         setValue('TemplateName', alertTemplateObj?.templateName)
//         setValue('ClientId', alertTemplateObj?.templateClientId)
//         setdevicetypeid(alertTemplateObj?.templateDeviceTypeId)
//         setValue('IDSPanelZoneTypeId', alertTemplateObj?.templateDeviceSubTypeId)
//         setValue('DeviceTypeId', alertTemplateObj?.templateDeviceTypeId)
//         setValue('AlertCodeId', alertTemplateObj?.templateAlertType)

//         if (alertTemplateObj.templateClientId != "0") {
//             // getSiteList(alertTemplateObj?.templateClientId)
//         } else {
//             setSiteSelected([]);
//             setSiteIndivSelected("");
//         }
//         if (alertTemplateObj?.templateDeviceTypeId == 3) {
//             setissensor(true);
//         } else {
//             setissensor(false);
//         }
//         if (alertTemplateObj.tempSelectedSites.length > 0 && alertTemplateObj.templateDeviceTypeId != "0" && alertTemplateObj.templateDeviceSubTypeId) {
//             // getDeviceList(alertTemplateObj?.tempSelectedSites,alertTemplateObj?.templateDeviceTypeId, alertTemplateList.templateDeviceSubTypeId)
//         } else {
//             setDeviceSelected([]);
//             setDeviceIndivSelected("");
//         }
//         if (alertTemplateObj?.scheduleType == 1) {
//             setSelectedItem("No Alert")
//             setSelectedSiteTab("no-alert");
//         } else if (alertTemplateObj?.scheduleType == 0) {
//             setSelectedItem("Custom")
//             setSelectedSiteTab("custom");

//         } else if (alertTemplateObj?.scheduleType == "NA") {
//             setSelectedItem("No Alert")
//             setSelectedSiteTab("no-alert");

//         } else {
//             setSelectedItem("No Alert");
//             setSelectedSiteTab("no-alert");
//         }
//         let scheduleObj = alertTemplateObj.schedulerObj;
//         if (scheduleObj?.custom && scheduleObj?.sevendays) {
//             setCustomData(scheduleObj.custom);
//             if (scheduleObj.sevendays.length == 0) {
//                 setSevenDaysData([
//                     {
//                         "id": 0,
//                         "day": "mon",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 1,
//                         "day": "tue",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 2,
//                         "day": "wed",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 3,
//                         "day": "thu",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 4,
//                         "day": "fri",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 5,
//                         "day": "sat",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                     {
//                         "id": 6,
//                         "day": "sun",
//                         "dayResource": [],
//                         "isEnabled": false
//                     },
//                 ])
//             } else {
//                 setSevenDaysData(scheduleObj.sevendays)
//             }
//             setGettingData(true);
//         } else {
//             setCustomData([]);
//             setSevenDaysData([
//                 {
//                     "id": 0,
//                     "day": "mon",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 1,
//                     "day": "tue",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 2,
//                     "day": "wed",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 3,
//                     "day": "thu",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 4,
//                     "day": "fri",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 5,
//                     "day": "sat",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//                 {
//                     "id": 6,
//                     "day": "sun",
//                     "dayResource": [],
//                     "isEnabled": false
//                 },
//             ])
//             setGettingData(false);
//         }
//         setDateCustom(null);
//     }, [alertTemplateObj]);

//     useEffect(() => {
//         let siteTempArr = siteList.filter(site => alertTemplateObj?.tempSelectedSites.includes(site.value));
//         setSiteSelected(siteTempArr);
//         if(siteTempArr.length > 0 && alertTemplateObj?.templateDeviceTypeId == 3){
//             setSiteIndivSelected(siteTempArr[0].value)
//         }
//     }, [siteList, alertTemplateObj])

//     useEffect(() => {
//         let deviceTypeArr = deviceList.filter(DeviceTypeId => alertTemplateObj?.tempSelectedDevices.includes(DeviceTypeId.value));
//         setDeviceSelected(deviceTypeArr);
//         if(deviceTypeArr.length > 0 && alertTemplateObj?.templateDeviceTypeId == 3){
//             setDeviceIndivSelected(deviceTypeArr[0].value);
//         }
//     }, [deviceList, alertTemplateObj])

//     useEffect(() => {
//         if (alertTemplateObj?.templateAlertType) {
//             setValue('AlertCodeId', alertTemplateObj?.templateAlertType)
//         }
//     }, [devicetypeid, alertTemplateObj]);

//     useEffect(() => {
//         if (alertTemplateObj?.templateClientId) {
//             setValue('ClientId', alertTemplateObj?.templateClientId)
//         }
//     }, [customerData, alertTemplateObj]);

//     useEffect(() => {
//         if (alertTemplateObj?.templateDeviceSubTypeId) {
//             setValue('IDSPanelZoneTypeId', alertTemplateObj?.templateDeviceSubTypeId)
//         }
//     }, [zoneList, alertTemplateObj])

//     useEffect(() => {
//         if (templateDelete) {
//             GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
//         }
//     }, [templateDelete])

//     useEffect(() => {
//         if (alertSaveBtnClicked) {
//             if (deviceTemplateList.length > 0 && selTempDevice != null) {
//                 //Call api related to device template updation.
//                 onSubmitDeviceTemplate(getValues(), siteSelected, deviceSelected, selectedItem, sevenDaysData, customData);
//             } else {
//                 onSubmitTemplate(getValues(), siteSelected, deviceSelected, selectedItem, sevenDaysData, customData);
//             }
//         }
//     }, [alertSaveBtnClicked]);

//     useEffect(() => {
//         console.log("siteSelected", siteSelected)
//         let showAllFlag
//         if (alertTemplateObj.templateId == "") {
//             showAllFlag = false;
//         } else {
//             showAllFlag = true;
//         }
//         if (siteSelected.length > 0 && watch('DeviceTypeId') == 3 && watch('AlertCodeId') != 0) {
//             getDeviceList(siteSelected, watch('DeviceTypeId'), "1", watch('AlertCodeId'), showAllFlag, alertTemplateObj.tempSelectedDevices, deviceTemplateList, isStore)
//             setDeviceIndivSelected("");
//         }
//         if (siteSelected.length > 0 && watch('DeviceTypeId') != 0 && watch('DeviceTypeId') != 3 && watch('AlertCodeId') != 0) {
//             getDeviceList(siteSelected, watch('DeviceTypeId'), "1", watch('AlertCodeId'), showAllFlag, alertTemplateObj.tempSelectedDevices, deviceTemplateList, isStore)
//             setDeviceIndivSelected("");
//         }
//     }, [watch('DeviceTypeId'), siteSelected, watch('AlertCodeId')])


//     useEffect(() => {
//         let showAllFlag
//         if (alertTemplateObj.templateId == "") {
//             showAllFlag = false;
//         } else {
//             showAllFlag = true;
//         }
//         if (deviceSelected.length > 0 && watch('DeviceTypeId') == 3 && watch('AlertCodeId') != 0) {
//             getZonesAT(deviceSelected, showAllFlag, watch('AlertCodeId'));
//         }
//     }, [deviceSelected, watch('DeviceTypeId'), watch('AlertCodeId')]);

//     useEffect(() => {
//         if (watch('AlertCodeId') != 0 && watch('DeviceTypeId') != 0) {
//             let showAllFlag
//             if (alertTemplateObj.templateId == "") {
//                 showAllFlag = false;
//             } else {
//                 showAllFlag = true;
//             }
//             getCustomerData(watch('DeviceTypeId'), watch('AlertCodeId'), showAllFlag)
//         }
//     }, [watch('AlertCodeId'), watch('DeviceTypeId')])

//     useEffect(() => {
//         if (watch('ClientId') != 0 && watch('DeviceTypeId') != 0 && watch('AlertCodeId') != 0 && watch('ClientId') != undefined && watch('DeviceTypeId') != undefined && watch('AlertCodeId') != undefined) {
//             if (isStore) {
//                 let showAllFlag;
//                 if (alertTemplateObj.templateId == "") {
//                     showAllFlag = 0;
//                 } else if (alertTemplateObj.templateId != "") {
//                     showAllFlag = 2;
//                 } else {
//                     showAllFlag = 1;
//                 }
//                 getSiteList(watch('ClientId'), watch('DeviceTypeId'), watch('AlertCodeId'), showAllFlag, isStore)
//                 setSiteIndivSelected("");
//             } else {
//                 let showAllFlag;
//                 if (alertTemplateObj.templateId == "") {
//                     showAllFlag = 0;
//                 } else {
//                     showAllFlag = 1;
//                 }
//                 getSiteList(watch('ClientId'), watch('DeviceTypeId'), watch('AlertCodeId'), showAllFlag, isStore)
//                 setSiteIndivSelected("");
//             }
//         }
//     }, [watch('DeviceTypeId'), watch('ClientId'), watch('AlertCodeId'), isStore])

//     useEffect(() => {
//         let cOne = currentPageNoOne;
//         setCurrentPageNoOne(cOne);
//         setPageCounterOne(cOne);
//         setTotalPagesOne(parseInt(totalTemplateCount));
//     }, [totalTemplateCount]);

//     useEffect(() => {
//         let cTwo = currentPageNoTwo;
//         setCurrentPageNoTwo(cTwo);
//         setPageCounterTwo(cTwo);
//         setTotalPagesTwo(parseInt(totalDeviceCount))
//     }, [totalDeviceCount]);

//     useEffect(() => {
//         if (activeTab == "Template") {
//             GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
//         }
//     }, [currentPageNoOne]);

//     useEffect(() => {
//         if (activeTab == "Device") {
//             GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch, filterText);
//         }
//     }, [currentPageNoTwo])

//     useEffect(() => {
//         if (isAlertUpdated) {
//             if (activeTab == "Template") {
//                 GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
//             }
//             setIsAlertUpdated(false);
//         }
//     }, [isAlertUpdated])

//     useEffect(() => {
//         if (activeTab == "Template") {
//             clearTimeout(timer);
//             const newTimer = setTimeout(() => {
//                 GetAlertTemplateList("alert_template_list", 1, alertSearch, filterText)
//                 setCurrentPageNoOne(1)
//             }, 500)
//             setTimer(newTimer);
//         }
//     }, [alertSearch])

//     useEffect(() => {
//         if (activeTab == "Device") {
//             clearTimeout(alertTimer);
//             const newTimer = setTimeout(() => {
//                 GetAlertTemplateList("site_list", 1, deviceSearch, filterText);
//                 setCurrentPageNoTwo(1);
//             }, 500)
//             setAlertTimer(newTimer);
//         }
//     }, [deviceSearch]);

//     useEffect(() => {
//         if (!isAlertTempFormEnabled) {
//             clearTemplateFields()
//         }
//     }, [isAlertTempFormEnabled])
//     //region useEffect End

//     //region functions start
//     const clearTemplateFields = () => {
//         setTemplateNameError('');
//         setTemplateDeviceTypeError('');
//         setTemplateAlertError('');
//         setTemplateCustomerError('');
//         setTemplateSiteError('');
//         setTemplateDeviceError('');
//         setTemplateSensorError('');
//     }

//     const GetTemplateDataToFilter = (pType, pSelectedIds) => {
//         try {
//             GetDataToFilter(pType, pSelectedIds).then((resp) => {
//                 if (resp != undefined) {
//                     if (resp.Status == "success") {
//                         if (pType == "customer") {
//                         } else if (pType == "site") {

//                         }
//                         else if (pType == "alert_type") {

//                         } else if (pType == "device_type") {

//                         } else {
//                             customerIsChecked(resp.Data.LstClient);
//                             siteIsChecked(resp.Data.LstSite);
//                             alertIsChecked(resp.Data.LstAlertCode);
//                             deviceTypeIsChecked(resp.Data.LstDeviceType);
//                         }
//                     }
//                 }
//             })
//         } catch (error) {
//             console.error(error)
//         }
//     }

//     const customerIsChecked = (arr) => {
//         const customer = arr.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterCustomerList(customer);
//         setFilterCustomerList(customer);
//         setCustomerCount(customer.length);
//     }

//     const siteIsChecked = (arr) => {
//         const site = arr.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterSiteList(site);
//         setFilterSiteList(site);
//         setSiteCount(site.length)
//     }

//     const alertIsChecked = (arr) => {
//         const alert_type = arr.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterAlertTypeList(alert_type);
//         setFilterAlertTypeList(alert_type);
//         setalertTypeCount(alert_type.length)
//     }

//     const deviceTypeIsChecked = (arr) => {
//         const device_type = arr.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterDeviceTypeList(device_type);
//         setFilterDeviceTypeList(device_type);
//         setDeviceTypeCount(device_type.length)
//     }

//     useImperativeHandle(ref, () => ({
//         isDataChanged() {
//             return getValues('TemplateName') != selectedTemplate?.TemplateName ||
//                 getValues('AlertCodeId') != selectedTemplate?.AlertCodeId ||
//                 getValues('Description') != selectedTemplate?.Description ||
//                 getValues('SeverityId') != selectedTemplate?.SeverityId ||
//                 getValues('FullTime') != selectedTemplate?.FullTime ||
//                 getValues('DeviceTypeId') != selectedTemplate?.DeviceTypeId ||
//                 getValues('ClientId') != selectedTemplate?.ClientId ||
//                 getValues('IDSPanelZoneTypeId') != selectedTemplate?.IDSPanelZoneTypeId ||
//                 getValues('SiteId') != selectedTemplate?.SiteId ||
//                 getValues('DeviceId') != selectedTemplate?.DeviceId ||
//                 getValues('WorkflowId') != selectedTemplate?.WorkflowId
//             //getValues('isAlertFlag') !=selectedItem?.isAlertFlag

//         }
//     }))
//     //#endregion

//     const OnDeviceTypeChanges = (e) => {
//         try {
//             setValue('DeviceTypeId', e.target.value)
//             Diplayformfields(e.target.value)
//             setdevicetypeid(e.target.value)
//             setValue('AlertCodeId', 0)
//             setValue('ClientId', "0")
//             setValue('IDSPanelZoneTypeId', "0")
//             setSiteSelected([]);
//             setSiteIndivSelected("");
//             setDeviceSelected([]);
//             setDeviceIndivSelected("");
//         } catch (error) {
//             console.error(error)
//         }
//     }

//     const Diplayformfields = (id) => {
//         try {
//             if (!devicesmasterlist) return;
//             var _type = devicesmasterlist.deviceTypeList.find(x => x.deviceTypeID == id)
//             if (_type) {
//                 if (_type.deviceType == "Camera Device") {
//                     setissensor(false)
//                 } else if (_type.deviceType == "IDS Panel") {
//                     setissensor(false)
//                 } else if (_type.deviceType == "Sensor") {
//                     setissensor(true)
//                 }
//             }
//         } catch (error) {
//             console.error(error)
//         }
//     }

//     const onSpecialCharacter = (event) => {
//         const keyCode = event.keyCode || event.which;
//         const keyValue = String.fromCharCode(keyCode);
//         const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);
//         if (!isValid) {
//             event.preventDefault();
//             return;
//         }
//     }

//     const handleCustomerSelectAll = () => {
//         const tempArr = filtercustomerList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: !customerSelectAll
//             }
//             return obj;
//         })

//         let customerOne = masterCustomerList.map((item) => {
//             let objcustomer;
//             if (customerSelectAll) {
//                 if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
//                     objcustomer = {
//                         ...item
//                     }
//                 } else {
//                     objcustomer = {
//                         ...item,
//                         isChecked: false
//                     }
//                 }
//             } else {
//                 if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
//                     objcustomer = {
//                         ...item,
//                     }
//                 } else {
//                     objcustomer = {
//                         ...item,
//                         isChecked: true
//                     }
//                 }
//             }
//             return objcustomer;
//         })

//         setCustomerSelectAll(!customerSelectAll);
//         setFilterCustomerList(tempArr);
//         setmasterCustomerList(customerOne);


//         let customerArr = customerOne.filter((item) => item.isChecked).map((i) => i.Item1);
//         let clientIds = customerArr.join(',');
//         if (customerArr.length != 0) {
//             GetDataToFilter("customer", clientIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     siteIsChecked(resp.Data.LstSite);
//                     setSiteSelectAll(false);
//                     deviceTypeIsChecked(resp.Data.LstDeviceType);
//                     setDeviceTypeSelectAll(false);
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false)
//                 }
//             })
//         }

//     };

//     const customerCheckHandler = (e, id) => {
//         let arr = filtercustomerList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })


//         let masterCustomer = masterCustomerList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })

//         let customerArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
//         let clientIds = customerArr.join(',');
//         if (customerArr.length != 0) {
//             GetDataToFilter("customer", clientIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     siteIsChecked(resp.Data.LstSite);
//                     setSiteSelectAll(false);
//                     deviceTypeIsChecked(resp.Data.LstDeviceType);
//                     setDeviceTypeSelectAll(false);
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false)
//                 }
//             })
//         } else {
//             GetDataToFilter("all", "").then((resp) => {
//                 if (resp.Status == "success") {
//                     siteIsChecked(resp.Data.LstSite);
//                     setSiteSelectAll(false);
//                     deviceTypeIsChecked(resp.Data.LstDeviceType);
//                     setDeviceTypeSelectAll(false);
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false)
//                 }
//             })
//         }

//         setmasterCustomerList(masterCustomer);
//         setFilterCustomerList(arr);

//     }

//     const handleSiteSelectAll = () => {

//         const tempArr = filterSiteList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: !siteSelectAll
//             }
//             return obj;
//         })
//         let siteOne = masterSiteList.map((item) => {
//             let objsite;
//             if (siteSelectAll) {
//                 if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
//                     objsite = {
//                         ...item
//                     }
//                 } else {
//                     objsite = {
//                         ...item,
//                         isChecked: false
//                     }
//                 }
//             } else {
//                 if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
//                     objsite = {
//                         ...item
//                     }
//                 } else {
//                     objsite = {
//                         ...item,
//                         isChecked: true
//                     }
//                 }
//             }
//             return objsite;
//         })
//         setSiteSelectAll(!siteSelectAll);
//         setFilterSiteList(tempArr);
//         setmasterSiteList(siteOne);

//         let siteArr = siteOne.filter((item) => item.isChecked).map((i) => i.Item1);
//         let siteIds = siteArr.join(',');
//         if (siteArr.length != 0) {
//             GetDataToFilter("site", siteIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     deviceTypeIsChecked(resp.Data.LstDeviceType);
//                     setDeviceTypeSelectAll(false);
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false);
//                 }
//             })
//         }
//     };
//     const siteCheckHandler = (e, id) => {
//         let arr = filterSiteList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })
//         let masterSite = masterSiteList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })

//         let siteArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
//         let siteIds = siteArr.join(',');
//         if (siteArr.length != 0) {
//             GetDataToFilter("site", siteIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     deviceTypeIsChecked(resp.Data.LstDeviceType);
//                     setDeviceTypeSelectAll(false);
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false);
//                 }
//             })
//         } else {
//             let customerArr = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
//             let clientIds = customerArr.join(',');
//             if (customerArr.length != 0) {
//                 GetDataToFilter("customer", clientIds).then((resp) => {
//                     if (resp.Status == "success") {
//                         // siteIsChecked(resp.Data.LstSite);
//                         // setSiteSelectAll(false);
//                         deviceTypeIsChecked(resp.Data.LstDeviceType);
//                         setDeviceTypeSelectAll(false);
//                         alertIsChecked(resp.Data.LstAlertCode);
//                         setAlertTypeSelectAll(false)
//                     }
//                 })
//             } else {
//                 GetDataToFilter("all", "").then((resp) => {
//                     if (resp.Status == "success") {
//                         siteIsChecked(resp.Data.LstSite);
//                         setSiteSelectAll(false);
//                         deviceTypeIsChecked(resp.Data.LstDeviceType);
//                         setDeviceTypeSelectAll(false);
//                         alertIsChecked(resp.Data.LstAlertCode);
//                         setAlertTypeSelectAll(false)
//                     }
//                 })
//             }
//             // GetDataToFilter("site", siteIds).then((resp)=>{
//             //     if(resp.Status == "success"){
//             //         deviceTypeIsChecked(resp.Data.LstDeviceType);  
//             //         setDeviceTypeSelectAll(false);
//             //         alertIsChecked(resp.Data.LstAlertCode);
//             //         setAlertTypeSelectAll(false);
//             //     }
//             // })
//         }
//         setmasterSiteList(masterSite);
//         setFilterSiteList(arr);
//     }

//     const handleDeviceTypeSelectAll = () => {

//         const tempArr = filterDeviceTypeList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: !deviceTypeSelectAll
//             }
//             return obj;
//         })
//         let deviceTypeOne = masterDeviceTypeList.map((item) => {
//             let objdeviceType;
//             if (deviceTypeSelectAll) {
//                 if (!item.Item2.toLowerCase().includes(deviceTypeSearch.toLowerCase())) {
//                     objdeviceType = {
//                         ...item
//                     }
//                 } else {
//                     objdeviceType = {
//                         ...item,
//                         isChecked: false
//                     }
//                 }
//             } else {
//                 if (!item.Item2.toLowerCase().includes(deviceTypeSearch.toLowerCase())) {
//                     objdeviceType = {
//                         ...item,
//                     }
//                 } else {
//                     objdeviceType = {
//                         ...item,
//                         isChecked: true
//                     }
//                 }
//             }
//             return objdeviceType;
//         })
//         setDeviceTypeSelectAll(!deviceTypeSelectAll);
//         setFilterDeviceTypeList(tempArr);
//         setmasterDeviceTypeList(deviceTypeOne);

//         let deviceTypeArr = deviceTypeOne.filter((item) => item.isChecked).map((i) => i.Item1);
//         let deviceTypeIds = deviceTypeArr.join(',');
//         if (deviceTypeArr.length != 0) {
//             GetDataToFilter("device_type", deviceTypeIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false);
//                 }
//             })
//         }
//     };
//     const deviceTypeCheckHandler = (e, id) => {
//         let arr = filterDeviceTypeList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })
//         let masterDeviceType = masterDeviceTypeList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })
//         let deviceTypeArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
//         let deviceTypeIds = deviceTypeArr.join(',');
//         if (deviceTypeArr.length != 0) {
//             GetDataToFilter("device_type", deviceTypeIds).then((resp) => {
//                 if (resp.Status == "success") {
//                     alertIsChecked(resp.Data.LstAlertCode);
//                     setAlertTypeSelectAll(false);
//                 }
//             })
//         } else {
//             let siteArr = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
//             let siteIds = siteArr.join(',');
//             if (siteArr.length != 0) {
//                 GetDataToFilter("site", siteIds).then((resp) => {
//                     if (resp.Status == "success") {
//                         deviceTypeIsChecked(resp.Data.LstDeviceType);
//                         setDeviceTypeSelectAll(false);
//                         alertIsChecked(resp.Data.LstAlertCode);
//                         setAlertTypeSelectAll(false);
//                     }
//                 })
//             } else {
//                 let customerArr = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
//                 let clientIds = customerArr.join(',');
//                 if (customerArr.length != 0) {
//                     GetDataToFilter("customer", clientIds).then((resp) => {
//                         if (resp.Status == "success") {
//                             // siteIsChecked(resp.Data.LstSite);
//                             // setSiteSelectAll(false);
//                             deviceTypeIsChecked(resp.Data.LstDeviceType);
//                             setDeviceTypeSelectAll(false);
//                             alertIsChecked(resp.Data.LstAlertCode);
//                             setAlertTypeSelectAll(false)
//                         }
//                     })
//                 } else {
//                     GetDataToFilter("all", "").then((resp) => {
//                         if (resp.Status == "success") {
//                             siteIsChecked(resp.Data.LstSite);
//                             setSiteSelectAll(false);
//                             deviceTypeIsChecked(resp.Data.LstDeviceType);
//                             setDeviceTypeSelectAll(false);
//                             alertIsChecked(resp.Data.LstAlertCode);
//                             setAlertTypeSelectAll(false)
//                         }
//                     })
//                 }
//             }

//         }
//         setmasterDeviceTypeList(masterDeviceType);
//         setFilterDeviceTypeList(arr);
//     }

//     const handleAlertTypeSelectAll = () => {

//         const tempArr = filterAlertTypeList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: !alertTypeSelectAll
//             }
//             return obj;
//         })
//         let alertTypeOne = masterAlertTypeList.map((item) => {
//             let objalertType;
//             if (alertTypeSelectAll) {
//                 if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
//                     objalertType = {
//                         ...item
//                     }
//                 } else {
//                     objalertType = {
//                         ...item,
//                         isChecked: false
//                     }
//                 }

//             } else {
//                 if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
//                     objalertType = {
//                         ...item,
//                     }
//                 } else {
//                     objalertType = {
//                         ...item,
//                         isChecked: true
//                     }
//                 }
//             }
//             return objalertType;
//         })
//         setAlertTypeSelectAll(!alertTypeSelectAll);
//         setFilterAlertTypeList(tempArr);
//         setmasterAlertTypeList(alertTypeOne)
//     };
//     const alertTypeCheckHandler = (e, id) => {
//         let arr = filterAlertTypeList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })
//         let masterAlertType = masterAlertTypeList.map((item) => {
//             let obj;
//             if (item.Item1 == id) {
//                 obj = {
//                     ...item,
//                     isChecked: !item.isChecked
//                 }
//             } else {
//                 obj = {
//                     ...item
//                 }
//             }
//             return obj;
//         })
//         setmasterAlertTypeList(masterAlertType);
//         setFilterAlertTypeList(arr);
//     }

//     const searchFilterHandler = (e, type) => {
//         let searchText = e.target.value;
//         if (type == "Customer") {
//             setCustomerSearch(searchText);
//             let arr = masterCustomerList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
//             let isChecked = true;
//             for (let i = 0; i < arr.length; i++) {
//                 if (arr[i].isChecked == true) {
//                     continue;
//                 } else {
//                     isChecked = false;
//                     break;
//                 }
//             }
//             setCustomerSelectAll(isChecked);
//             setFilterCustomerList(arr);
//             setCustomerCount(arr.length);
//         } else if (type == "Site") {
//             setSiteSearch(searchText);
//             let arr = masterSiteList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
//             let isChecked = true;
//             for (let i = 0; i < arr.length; i++) {
//                 if (arr[i].isChecked == true) {
//                     continue;
//                 } else {
//                     isChecked = false;
//                     break;
//                 }
//             }
//             setSiteSelectAll(isChecked);
//             setFilterSiteList(arr);
//             setSiteCount(arr.length);
//         } else if (type == "Device Type") {
//             setDeviceTypeSearch(searchText)
//             let arr = masterDeviceTypeList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
//             let isChecked = true;
//             for (let i = 0; i < arr.length; i++) {
//                 if (arr[i].isChecked == true) {
//                     continue;
//                 } else {
//                     isChecked = false;
//                     break;
//                 }
//             }
//             setDeviceTypeSelectAll(isChecked);
//             setFilterDeviceTypeList(arr);
//             setDeviceTypeCount(arr.length);
//         } else if (type == "Alert Type") {
//             setAlertTypeSearch(searchText)
//             let arr = masterAlertTypeList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
//             let isChecked = true;
//             for (let i = 0; i < arr.length; i++) {
//                 if (arr[i].isChecked == true) {
//                     continue;
//                 } else {
//                     isChecked = false;
//                     break;
//                 }
//             }
//             setAlertTypeSelectAll(isChecked);
//             setFilterAlertTypeList(arr);
//             setalertTypeCount(arr.length)
//         }
//     }

//     const filterClearAll = () => {
//         GetTemplateDataToFilter("all", "")
//         setCustomerSearch("")
//         setSiteSearch("")
//         setDeviceTypeSearch("")
//         setAlertTypeSearch("")
//         setCustomerSelectAll(false);
//         setSiteSelectAll(false);
//         setDeviceTypeSelectAll(false);
//         setAlertTypeSelectAll(false);
//         setfilterText("");
//         const tempcustomer = masterCustomerList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterCustomerList(tempcustomer);
//         setFilterCustomerList(tempcustomer);

//         const tempsite = masterSiteList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterSiteList(tempsite);
//         setFilterSiteList(tempsite);

//         const tempdevicetype = masterDeviceTypeList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterDeviceTypeList(tempdevicetype)
//         setFilterDeviceTypeList(tempdevicetype);

//         const tempalerttype = masterAlertTypeList.map((item, index) => {
//             let obj = {
//                 ...item,
//                 isChecked: false
//             }
//             return obj;
//         })
//         setmasterAlertTypeList(tempalerttype)
//         setFilterAlertTypeList(tempalerttype);
//         setFilterCount(0)
//         if (activeTab == "Template") {
//             GetAlertTemplateList("alert_template_list", 1, alertSearch, "")
//         }
//         else {
//             GetAlertTemplateList("site_list", 1, deviceSearch, "");
//         }

//     }

//     const applyAll = () => {
//         let count = 0;
//         let isCustomerChecked = masterCustomerList.some((item) => item.isChecked);
//         let isSiteChecked = masterSiteList.some((item) => item.isChecked);
//         let isDeviceTypeChecked = masterDeviceTypeList.some((item) => item.isChecked);
//         let isAlertTypeChecked = masterAlertTypeList.some((item) => item.isChecked);
//         if (isCustomerChecked) {
//             count++;
//         }
//         if (isSiteChecked) {
//             count++;
//         }
//         if (isDeviceTypeChecked) {
//             count++;
//         }
//         if (isAlertTypeChecked) {
//             count++;
//         }
//         setFilterCount(count);

//         let customer = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
//         let clientIds = customer.join(',');

//         let site = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
//         let siteIds = site.join(',');

//         let deviceType = masterDeviceTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
//         let deviceTypeIds = deviceType.join(',');

//         let alertType = masterAlertTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
//         let alertTypeIds = alertType.join(',');

//         const fileterTextstring = `customer:${clientIds};site:${siteIds};device_type:${deviceTypeIds};alert_type:${alertTypeIds}`;
//         setfilterText(fileterTextstring);
//         if (activeTab == "Template") {
//             GetAlertTemplateList("alert_template_list", 1, alertSearch, fileterTextstring)
//         }
//         else {
//             GetAlertTemplateList("site_list", 1, deviceSearch, fileterTextstring);
//         }

//         setTemplateModalFilter(false)
//     }

//     const PaginationComponentOne = (currentPageNo) => {
//         let numbers = [];
//         if (maxPageAllowedOne - 2 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1) || currentPageNo === (maxPageAllowedOne - 2))) {
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedOne - 2}
//                     active={maxPageAllowedOne - 2 == currentPageNo}
//                     onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 2)}
//                 >
//                     {maxPageAllowedOne - 2}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedOne - 1}
//                     active={maxPageAllowedOne - 1 == currentPageNo}
//                     onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
//                 >
//                     {maxPageAllowedOne - 1}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedOne}
//                     active={maxPageAllowedOne == currentPageNo}
//                     onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
//                 >
//                     {maxPageAllowedOne}
//                 </Pagination.Item>)
//         }
//         else if (maxPageAllowedOne - 1 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1))) {
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedOne - 1}
//                     active={maxPageAllowedOne - 1 == currentPageNo}
//                     onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
//                 >
//                     {maxPageAllowedOne - 1}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedOne}
//                     active={maxPageAllowedOne == currentPageNo}
//                     onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
//                 >
//                     {maxPageAllowedOne}
//                 </Pagination.Item>)
//         }
//         else {
//             for (let i = currentPageNo; i < currentPageNo + 3; i++) {
//                 if (i <= maxPageAllowedOne) {
//                     numbers.push(
//                         <Pagination.Item
//                             key={i}
//                             active={i == currentPageNo}
//                             onClick={() => setCurrentPageNoOne(i)}
//                         >
//                             {i}
//                         </Pagination.Item>
//                     )
//                 }
//             }
//         }
//         return numbers;
//     }

//     const PaginationComponentTwo = (currentPageNo) => {
//         let numbers = [];
//         if (maxPageAllowedTwo - 2 != 0 && (currentPageNo === maxPageAllowedTwo || currentPageNo === (maxPageAllowedTwo - 1) || currentPageNo === (maxPageAllowedTwo - 2))) {
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedTwo - 2}
//                     active={maxPageAllowedTwo - 2 == currentPageNo}
//                     onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 2)}
//                 >
//                     {maxPageAllowedTwo - 2}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedTwo - 1}
//                     active={maxPageAllowedTwo - 1 == currentPageNo}
//                     onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 1)}
//                 >
//                     {maxPageAllowedTwo - 1}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedTwo}
//                     active={maxPageAllowedTwo == currentPageNo}
//                     onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo)}
//                 >
//                     {maxPageAllowedTwo}
//                 </Pagination.Item>)
//         }
//         else if (maxPageAllowedTwo - 1 != 0 && (currentPageNo === maxPageAllowedTwo || currentPageNo === (maxPageAllowedTwo - 1))) {
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedTwo - 1}
//                     active={maxPageAllowedTwo - 1 == currentPageNo}
//                     onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 1)}
//                 >
//                     {maxPageAllowedTwo - 1}
//                 </Pagination.Item>)
//             numbers.push(
//                 <Pagination.Item
//                     key={maxPageAllowedTwo}
//                     active={maxPageAllowedTwo == currentPageNo}
//                     onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo)}
//                 >
//                     {maxPageAllowedTwo}
//                 </Pagination.Item>)
//         }
//         else {
//             for (let i = currentPageNo; i < currentPageNo + 3; i++) {
//                 if (i <= maxPageAllowedTwo) {
//                     numbers.push(
//                         <Pagination.Item
//                             key={i}
//                             active={i == currentPageNo}
//                             onClick={() => setCurrentPageNoTwo(i)}
//                         >
//                             {i}
//                         </Pagination.Item>
//                     )
//                 }
//             }
//         }
//         return numbers;
//     }

//     const templateSelected = (id) => {
//         if (id == alertTemplateObj.templateId) {
//             return;
//         }
//         if (alertTemplateObj.templateId != "" && isAlertTempFormEnabled) {
//             Swal.fire({
//                 title: 'Operation in Progress.',
//                 text: "You have started the operation for a template, do you want to discard any changes and continue to the other template.",
//                 icon: 'warning',
//                 showCancelButton: true,
//                 confirmButtonColor: '#027aaa',
//                 cancelButtonColor: '#d33',
//                 confirmButtonText: 'Ok!',
//                 allowOutsideClick: false
//             }).then((result) => {
//                 if (result.isConfirmed) {
//                     getAlertTemplateById(id);
//                     setDeviceTemplateList([]);
//                     setSelTempDevice(null)
//                     setDisplayButtons(true);
//                     setActivityLogShow(false);
//                 }
//             })
//         } else {
//             getAlertTemplateById(id);
//             setDeviceTemplateList([]);
//             setSelTempDevice(null)
//             setDisplayButtons(true);
//             setActivityLogShow(false);
//         }
//     }
//     const deviceTempSelected = (Id, template) => {
//         if (Id == selTempDevice?.Id) {
//             return;
//         }
//         if (alertTemplateObj.templateId !== "" && isAlertTempFormEnabled) {
//             Swal.fire({
//                 title: 'Operation in Progress.',
//                 text: "You have started the operation for a device, do you want to discard any changes and continue to the other device.",
//                 icon: 'warning',
//                 showCancelButton: true,
//                 confirmButtonColor: '#027aaa',
//                 cancelButtonColor: '#d33',
//                 confirmButtonText: 'Ok!',
//                 allowOutsideClick: false
//             }).then((result) => {
//                 if (result.isConfirmed) {
//                     //getAlertTemplateById(Id); 
//                     getAlertTemplateByDeviceId(Id);
//                     setDeviceTemplateList([]);
//                     setSelTempDevice(Id);
//                     setDisplayButtons(true);
//                     setActivityLogShow(false);
//                     setSelTempDevice(template)
//                 }
//             });
//         } else {
//             getAlertTemplateByDeviceId(Id);
//             setDeviceTemplateList([]);
//             setSelTempDevice(null);
//             setDisplayButtons(true);
//             setActivityLogShow(false);
//             setSelTempDevice(template)
//         }
//     }
//     //region function ends

//     return (
//         <>
//             <div className="SiteMainContainer">
//                 <div className="SiteLeftContainer">
//                     <div>
//                         <Tabs
//                             defaultActiveKey="Template"
//                             id="uncontrolled-tab-example"
//                             className="AlertTemplateTab"
//                             onSelect={(k) => {
//                                 if (activeTab == k) {
//                                     return;
//                                 }
//                                 if (k == "Template") {
//                                     GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, "")
//                                     setfilterText("");
//                                     setDevTemClicked(false);
//                                     setDeviceTemplateList([]);
//                                     let newAlertTemplateObj = {
//                                         templateId: "",
//                                         templateName: "",
//                                         templateClientId: "0",
//                                         templateSiteId: [],
//                                         templateDeviceTypeId: "0",
//                                         templateDeviceSubTypeId: "0",
//                                         templateDevice: [],
//                                         templateAlertType: "0",
//                                         scheduleType: "NA",
//                                         schedulerObj: {},
//                                         tempSelectedSites: [],
//                                         tempSelectedDevices: [],
//                                     }
//                                     setAlertTemplateObj(newAlertTemplateObj)
//                                     setDisplayButtons(true);
//                                     setAddNewClickedAlert(true);
//                                     setIsEmptyFormAlert(true);
//                                     setIsAlertTempFormEnabled(true);
//                                     setIsAlertTemplateEdit(true)
//                                     setIsStore(false);
//                                     setActivityLogShow(false);
//                                     if (activeTab == "Device") {
//                                         setFilterCount(0);
//                                     }
//                                 } else {
//                                     GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch, "", true);
//                                     setfilterText("");
//                                     if (activeTab == "Template") {
//                                         setFilterCount(0);
//                                     }
//                                 }
//                                 setActiveTab(k);
//                             }}
//                         >
//                             <Tab eventKey="Template" title="Template List">
//                                 <div>
//                                     <div style={{ display: "flex", alignItems: "center" }}>
//                                         <div className="m-2 HeaderSiteList withFilterBox">
//                                             <input
//                                                 id="Search_txt"
//                                                 className="SearchViewInput1"
//                                                 type="text"
//                                                 placeholder="Search"
//                                                 // autoFocus
//                                                 value={alertSearch}
//                                                 onChange={(e) => setAlertSearch(e.target.value)}
//                                             />
//                                             <div className="SearchTextDivConfigTool">
//                                                 {
//                                                     isDarkTheme ?
//                                                         <img
//                                                             className="EyeIcon"
//                                                             src={SearchIcon}
//                                                             alt="search icon"
//                                                             onClick={() => {
//                                                                 GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
//                                                             }}
//                                                         /> :
//                                                         <img
//                                                             className="EyeIcon"
//                                                             src={SearchIconWhite}
//                                                             alt="search icon" height="15"
//                                                             onClick={() => {
//                                                                 GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
//                                                             }}
//                                                         />

//                                                 }
//                                             </div>
//                                         </div>
//                                         <div>
//                                             <button className="AlertFilter"
//                                                 onClick={() => { setTemplateModalFilter(true) }}
//                                             >
//                                                 <OverlayTrigger
//                                                     placement='bottom'
//                                                     overlay={
//                                                         <Tooltip>
//                                                             filter
//                                                         </Tooltip>
//                                                     }
//                                                 >
//                                                     <>
//                                                         <div className="badge badge-pill badge-danger" style={{ top: "2px", right: "4px", minWidth: "25px" }}>
//                                                             <p className='filteractivetext'>{filterCount}</p>
//                                                         </div>
//                                                         <img src={Filter} style={{ height: "15px", float: "left" }} className="camera-icon-height" alt='Alert filter icon' />
//                                                     </>
//                                                 </OverlayTrigger>
//                                             </button>
//                                         </div>
//                                         <Popup
//                                             className='popupDiv'
//                                             position={["bottom center", "top center", "right center"]}
//                                             trigger={
//                                                 <button className="DataAccessEdit"
//                                                     style={{ height: "37px", width: "37px", display: "none" }}
//                                                 >
//                                                     <OverlayTrigger
//                                                         placement='bottom'
//                                                         overlay={
//                                                             <Tooltip>
//                                                                 filter
//                                                             </Tooltip>
//                                                         }
//                                                     >
//                                                         <>
//                                                             <div className="badge badge-pill badge-danger ml-1 mt-1" style={{ top: "3.4rem" }}>
//                                                                 <p className='filteractivetext'>88</p>
//                                                             </div>
//                                                             <img src={Filter} style={{ height: "15px" }} className="camera-icon-height" alt='playback white icon' />
//                                                         </>
//                                                     </OverlayTrigger>
//                                                 </button>
//                                             }
//                                         >
//                                             <div className='popupDiv filtermaindiv'>
//                                                 <div className='filterpopup p-0'>
//                                                     <h6>Filter by:</h6>
//                                                     <button>
//                                                         <img src={Cross} alt='cancel button' />
//                                                     </button>
//                                                 </div>
//                                                 <div className='filterinnermaindiv'>
//                                                     <div className="NotificationTextBox">
//                                                         <p className="SiteNameText">
//                                                             Severity
//                                                         </p>
//                                                         <div className="filterseverity">
//                                                             {severities &&
//                                                                 severities.map(item => {
//                                                                     return <div key={item.SeverityId} className="form-check-inline">
//                                                                         <>
//                                                                             <input
//                                                                                 style={{ left: "0", position: "relative" }}
//                                                                                 type="checkbox"
//                                                                                 disabled={!isAlertTemplateEdit} />{" "}
//                                                                             <span className="ml-2">{` ${item.SeverityTitle}`}</span>
//                                                                         </>
//                                                                     </div>
//                                                                 })}
//                                                         </div>
//                                                     </div>
//                                                 </div>

//                                                 <div style={{ textAlign: 'center' }}>
//                                                     <div className='DataAccessEdit' style={{ width: "100px" }}>
//                                                         <span className='ViewUpdate'>Apply</span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </Popup>

//                                         <Modal
//                                             size='lg'
//                                             show={templateModalFilter}
//                                             onHide={() => setTemplateModalFilter(false)}
//                                             className='filterModalMainclass'
//                                             backdrop="static"
//                                         >
//                                             <Modal.Header closeButton style={{ borderBottom: "none" }}>
//                                                 <Modal.Title className='pb-0'>
//                                                     {
//                                                         <span>Filter {activeTab} List</span>
//                                                     }
//                                                 </Modal.Title>
//                                             </Modal.Header>

//                                             <Modal.Body>
//                                                 <div className=''>
//                                                     <div className=''>
//                                                         <div className="grid-container filterContainer grid-container--fit mb-0 ">
//                                                             <div className="NotificationTextBox mt-0">
//                                                                 <p className="SiteNameText mb-0">
//                                                                     Customer Name
//                                                                 </p>
//                                                                 <div className='filterinnerboxdiv'>
//                                                                     <div>
//                                                                         <div className="SelectionListWithSelectAll">
//                                                                             <input
//                                                                                 className="SearchViewInput1"
//                                                                                 type="search"
//                                                                                 placeholder="Search"
//                                                                                 value={customerSearch}
//                                                                                 onChange={(e) => searchFilterHandler(e, "Customer")}
//                                                                             />
//                                                                             {
//                                                                                 isDarkTheme ?
//                                                                                     <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
//                                                                                     <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                     {
//                                                                         customerCount > 0
//                                                                             ?
//                                                                             <div className='dataAccessListItem mt-2'
//                                                                             >
//                                                                                 <input type="checkbox"
//                                                                                     checked={customerSelectAll}
//                                                                                     onChange={handleCustomerSelectAll}
//                                                                                 />
//                                                                                 <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
//                                                                                     onClick={handleCustomerSelectAll}>
//                                                                                     Select all <span>{customerCount}</span>
//                                                                                 </p>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     <div className='HgtFilterBoxList'>

//                                                                         {
//                                                                             filtercustomerList.map((item) => (
//                                                                                 <div key={item.Item1} className="dataAccessListItem"
//                                                                                 >
//                                                                                     <input
//                                                                                         checked={item.isChecked}
//                                                                                         type="checkbox"
//                                                                                         onChange={(e) => customerCheckHandler(e, item.Item1)}
//                                                                                     />
//                                                                                     <p className="AllTextwithValue"
//                                                                                         onClick={(e) => customerCheckHandler(e, item.Item1)}>
//                                                                                         {item.Item2}</p>
//                                                                                 </div>
//                                                                             ))
//                                                                         }
//                                                                     </div>
//                                                                 </div>


//                                                             </div>
//                                                             <div className="NotificationTextBox mt-0">
//                                                                 <p className="SiteNameText mb-0">
//                                                                     Site
//                                                                 </p>

//                                                                 <div className='filterinnerboxdiv'>
//                                                                     <div>
//                                                                         <div className="SelectionListWithSelectAll">
//                                                                             <input
//                                                                                 className="SearchViewInput1"
//                                                                                 type="search"
//                                                                                 placeholder="Search"
//                                                                                 value={siteSearch}
//                                                                                 onChange={(e) => searchFilterHandler(e, "Site")}
//                                                                             />
//                                                                             {
//                                                                                 isDarkTheme ?
//                                                                                     <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
//                                                                                     <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                     {
//                                                                         siteCount > 0
//                                                                             ?
//                                                                             <div className='dataAccessListItem mt-2'>
//                                                                                 <input type="checkbox"
//                                                                                     checked={siteSelectAll}
//                                                                                     onChange={handleSiteSelectAll}
//                                                                                 />
//                                                                                 <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
//                                                                                     onClick={handleSiteSelectAll}>
//                                                                                     Select all <span>{siteCount}</span>
//                                                                                 </p>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     <div className='HgtFilterBoxList'>

//                                                                         {
//                                                                             filterSiteList.map((item) => (
//                                                                                 <div key={item.Item1} className="dataAccessListItem">
//                                                                                     <input checked={item.isChecked}
//                                                                                         type="checkbox"
//                                                                                         onChange={(e) => siteCheckHandler(e, item.Item1)}
//                                                                                     />
//                                                                                     <p className="AllTextwithValue"
//                                                                                         onClick={(e) => siteCheckHandler(e, item.Item1)}>
//                                                                                         {item.Item2}</p>
//                                                                                 </div>
//                                                                             ))
//                                                                         }
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className="grid-container filterContainer grid-container--fit mb-0">
//                                                             <div className="NotificationTextBox">
//                                                                 <p className="SiteNameText mb-0">
//                                                                     Device Type
//                                                                 </p>
//                                                                 <div className='filterinnerboxdiv'>
//                                                                     <div>
//                                                                         <div className="SelectionListWithSelectAll">
//                                                                             <input
//                                                                                 className="SearchViewInput1"
//                                                                                 type="search"
//                                                                                 placeholder="Search"
//                                                                                 value={deviceTypeSearch}
//                                                                                 onChange={(e) => searchFilterHandler(e, "Device Type")}
//                                                                             />
//                                                                             {
//                                                                                 isDarkTheme ?
//                                                                                     <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
//                                                                                     <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                     {
//                                                                         deviceTypeCount > 0
//                                                                             ?
//                                                                             <div className='dataAccessListItem mt-2'>
//                                                                                 <input type="checkbox"
//                                                                                     checked={deviceTypeSelectAll}
//                                                                                     onChange={handleDeviceTypeSelectAll}
//                                                                                 />
//                                                                                 <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
//                                                                                     onClick={handleDeviceTypeSelectAll}>
//                                                                                     Select all <span>{deviceTypeCount}</span>
//                                                                                 </p>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     <div className='HgtFilterBoxList'>

//                                                                         {
//                                                                             filterDeviceTypeList.map((item) => (
//                                                                                 <div key={item.Item1} className="dataAccessListItem">
//                                                                                     <input checked={item.isChecked}
//                                                                                         type="checkbox"
//                                                                                         onChange={(e) => deviceTypeCheckHandler(e, item.Item1)}
//                                                                                     />
//                                                                                     <p className="AllTextwithValue"
//                                                                                         onClick={(e) => deviceTypeCheckHandler(e, item.Item1)}>
//                                                                                         {item.Item2}</p>
//                                                                                 </div>
//                                                                             ))
//                                                                         }
//                                                                     </div>
//                                                                 </div>

//                                                             </div>

//                                                             <div className="NotificationTextBox">
//                                                                 <p className="SiteNameText mb-0">
//                                                                     Alert Type
//                                                                 </p>

//                                                                 <div className='filterinnerboxdiv'>
//                                                                     <div>
//                                                                         <div className="SelectionListWithSelectAll">
//                                                                             <input
//                                                                                 className="SearchViewInput1"
//                                                                                 type="search"
//                                                                                 placeholder="Search"
//                                                                                 value={alertTypeSearch}
//                                                                                 onChange={(e) => searchFilterHandler(e, "Alert Type")}
//                                                                             />
//                                                                             {
//                                                                                 isDarkTheme ?
//                                                                                     <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
//                                                                                     <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                     {
//                                                                         alertTypeCount > 0
//                                                                             ?
//                                                                             <div className='dataAccessListItem mt-2'>
//                                                                                 <input type="checkbox"
//                                                                                     checked={alertTypeSelectAll}
//                                                                                     onChange={handleAlertTypeSelectAll}
//                                                                                 />
//                                                                                 <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
//                                                                                     onClick={handleAlertTypeSelectAll}>
//                                                                                     Select all <span>{alertTypeCount}</span>
//                                                                                 </p>
//                                                                             </div>
//                                                                             : null
//                                                                     }
//                                                                     <div className='HgtFilterBoxList'>
//                                                                         {
//                                                                             filterAlertTypeList.map((item) => (
//                                                                                 <div key={item.Item1} className="dataAccessListItem">
//                                                                                     <input checked={item.isChecked} type="checkbox"
//                                                                                         onChange={(e) => alertTypeCheckHandler(e, item.Item1)}
//                                                                                     />
//                                                                                     <p className="AllTextwithValue"
//                                                                                         onClick={(e) => alertTypeCheckHandler(e, item.Item1)}>
//                                                                                         {item.Item2}</p>
//                                                                                 </div>
//                                                                             ))
//                                                                         }
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }} className='filterbuttonsdiv'>
//                                                         <button className='DataAccessEdit' style={{ width: "100px" }} onClick={applyAll}>
//                                                             <span className='ViewUpdate'>Apply</span>
//                                                         </button>

//                                                         <button className='DataAccessEdit' style={{ width: "100px" }} onClick={filterClearAll}>
//                                                             <span className='ViewUpdate'>Reset</span>

//                                                         </button>
//                                                     </div>
//                                                 </div>
//                                             </Modal.Body>
//                                         </Modal>
//                                     </div>
//                                     <div className="AlertTemplateListDiv">
//                                         {
//                                             alertTemplateList && (
//                                                 alertTemplateList?.length > 0 ?
//                                                     alertTemplateList.map((template) => {
//                                                         return (
//                                                             <div
//                                                                 className={alertTemplateObj?.templateId == template.Id ? "OneList SelectedList" : "OneList"}
//                                                                 key={template.Id}
//                                                                 onClick={(e) => {
//                                                                     e.stopPropagation();
//                                                                     //Correct One
//                                                                     templateSelected(template.Id);
//                                                                     clearTemplateFields()
//                                                                 }}
//                                                             >
//                                                                 <p className="ListofText">
//                                                                     {template.Name}
//                                                                     <span className="SiteNameText"> - {template.Tag}</span>
//                                                                 </p>
//                                                                 <div>
//                                                                     {

//                                                                         template.IsMobileAlert == "True" ?
//                                                                             (
//                                                                                 <OverlayTrigger
//                                                                                     placement='bottom'
//                                                                                     overlay={
//                                                                                         <Tooltip>
//                                                                                             {template.SiteMode}
//                                                                                         </Tooltip>
//                                                                                     }
//                                                                                 >
//                                                                                     {
//                                                                                         isDarkTheme ?
//                                                                                             <img src={RetailStore2} alt='retailstore icon' style={{ height: "15px" }} />
//                                                                                             :
//                                                                                             <img src={RetailStore2White} alt='retailstore icon' style={{ height: "15px" }} />
//                                                                                     }
//                                                                                 </OverlayTrigger>
//                                                                             )
//                                                                             :
//                                                                             null
//                                                                     }
//                                                                 </div>
//                                                             </div>
//                                                         );
//                                                     }) : <div className="no-data-found"><p>No Templates Found.</p></div>
//                                             )
//                                         }
//                                         {!alertTemplateList &&
//                                             <div className="no-data-found">No Templates Available.</div>
//                                         }
//                                     </div>
//                                     {
//                                         totalPagesOne === 0 || totalPagesOne <= limitOne
//                                             ? null
//                                             : <div className="PaginationDiv mb-2 mt-2 mr-2">
//                                                 <h5 className='PageNumbers'>
//                                                     {
//                                                         totalPagesOne == 0 ? 0
//                                                             :
//                                                             (currentPageNoOne - 1) * parseInt(limitOne) + parseInt(1)} - {(currentPageNoOne * limitOne) > (totalPagesOne)
//                                                                 ? (totalPagesOne)
//                                                                 : currentPageNoOne * limitOne}
//                                                 </h5>
//                                                 <span>of</span>
//                                                 <h5 className="PageNumbers">{totalPagesOne}</h5>
//                                                 <Pagination>
//                                                     <Pagination.Prev onClick={prevClickOne} disabled={currentPageNoOne == 1} />
//                                                     {
//                                                         PaginationComponentOne(currentPageNoOne)
//                                                     }
//                                                     <Pagination.Next onClick={nextClickOne} disabled={currentPageNoOne == maxPageAllowedOne} />
//                                                 </Pagination>
//                                             </div>
//                                     }

//                                 </div>
//                             </Tab>

//                             <Tab eventKey="Device" title="Device List">
//                                 <div>
//                                     <div style={{ display: "flex", alignItems: "center" }}>
//                                         <div className="m-2 HeaderSiteList" style={{ width: "85%" }}>
//                                             <input
//                                                 id="Search_txt"
//                                                 className="SearchViewInput1"
//                                                 type="text"
//                                                 placeholder="Search"
//                                                 value={deviceSearch}
//                                                 onChange={(e) => setDeviceSearch(e.target.value)}
//                                             />
//                                             <div className="SearchTextDivConfigTool">
//                                                 {
//                                                     isDarkTheme ?
//                                                         <img
//                                                             className="EyeIcon"
//                                                             src={SearchIcon}
//                                                             alt="search icon"
//                                                             onClick={() => {
//                                                                 GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch, filterText);
//                                                             }}
//                                                         /> :
//                                                         <img
//                                                             className="EyeIcon"
//                                                             src={SearchIconWhite}
//                                                             alt="search icon" height="15"
//                                                             onClick={() => {
//                                                                 GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch, filterText);
//                                                             }}
//                                                         />

//                                                 }
//                                             </div>
//                                         </div>
//                                         <div>
//                                             <button className="AlertFilter"
//                                                 onClick={() => { setTemplateModalFilter(true) }}
//                                             >
//                                                 <OverlayTrigger
//                                                     placement='bottom'
//                                                     overlay={
//                                                         <Tooltip>
//                                                             filter
//                                                         </Tooltip>
//                                                     }
//                                                 >
//                                                     <>
//                                                         <div className="badge badge-pill badge-danger" style={{ top: "2px", right: "4px", minWidth: "25px" }}>
//                                                             <p className='filteractivetext'>{filterCount}</p>
//                                                         </div>
//                                                         <img src={Filter} style={{ height: "15px", float: "left" }} className="camera-icon-height" alt='Alert filter icon' />
//                                                     </>
//                                                 </OverlayTrigger>
//                                             </button>
//                                         </div>


//                                         <Popup
//                                             className='popupDiv'
//                                             position={["bottom center", "top center", "right center"]}
//                                             trigger={
//                                                 <button className="DataAccessEdit"
//                                                     style={{ height: "37px", width: "37px", display: "none" }}
//                                                 >
//                                                     <OverlayTrigger
//                                                         placement='bottom'
//                                                         overlay={
//                                                             <Tooltip>
//                                                                 filter
//                                                             </Tooltip>
//                                                         }
//                                                     >
//                                                         <>
//                                                             <div className="badge badge-pill badge-danger ml-1 mt-1" style={{ top: "3.4rem" }}>
//                                                                 <p className='filteractivetext'>88</p>
//                                                             </div>
//                                                             <img src={Filter} style={{ height: "15px" }} className="camera-icon-height" alt='playback white icon' />
//                                                         </>
//                                                     </OverlayTrigger>
//                                                 </button>
//                                             }
//                                         >
//                                             <div className='popupDiv filtermaindiv'>
//                                                 <div className='filterpopup p-0'>
//                                                     <h6>Filter by:</h6>
//                                                     <button>
//                                                         <img src={Cross} alt='cancel button' />
//                                                     </button>
//                                                 </div>
//                                                 <div className='filterinnermaindiv'>
//                                                     <div className="NotificationTextBox">
//                                                         <p className="SiteNameText">
//                                                             Severity
//                                                         </p>
//                                                         <div className="filterseverity">
//                                                             {severities &&
//                                                                 severities.map(item => {
//                                                                     return <div key={item.SeverityId} className="form-check-inline">
//                                                                         <>
//                                                                             <input
//                                                                                 style={{ left: "0", position: "relative" }}
//                                                                                 type="checkbox"
//                                                                                 disabled={!isAlertTemplateEdit} />{" "}
//                                                                             <span className="ml-2">{` ${item.SeverityTitle}`}</span>
//                                                                         </>
//                                                                     </div>
//                                                                 })}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div style={{ textAlign: 'center' }}>
//                                                     <div className='DataAccessEdit' style={{ width: "100px" }}>
//                                                         <span className='ViewUpdate'>Apply</span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </Popup>
//                                     </div>
//                                     <div className="AlertTemplateListDiv">
//                                         {
//                                             alertTemplateList && (
//                                                 alertTemplateList?.length > 0 ?
//                                                     alertTemplateList.map((template) => {
//                                                         return (
//                                                             <div
//                                                                 className={selTempDevice?.Id === template.Id ? "OneList SelectedList" : "OneList"}
//                                                                 key={template.Id}
//                                                                 onClick={(e) => {
//                                                                     e.stopPropagation();
//                                                                     deviceTempSelected(template.Id, template);
//                                                                 }}
//                                                             >
//                                                                 <p className="ListofText">
//                                                                     {template.Name}
//                                                                     <span className="SiteNameText"> - {template.Tag}</span>
//                                                                 </p>
//                                                             </div>
//                                                         );
//                                                     }) :
//                                                     <div>
//                                                         <div className="no-data-found"><p>No Devices Found.</p></div>
//                                                     </div>
//                                             )
//                                         }
//                                         {!alertTemplateList &&
//                                             <div className="no-data-found">No Devices Available.</div>
//                                         }
//                                     </div>
//                                     {
//                                         totalPagesTwo === 0 || totalPagesTwo <= limitTwo
//                                             ? null
//                                             :
//                                             <div className="PaginationDiv mb-2 mt-2 mr-2">
//                                                 <h5 className='PageNumbers'>
//                                                     {
//                                                         totalPagesTwo == 0 ? 0
//                                                             :
//                                                             (currentPageNoTwo - 1) * parseInt(limitTwo) + parseInt(1)} - {(currentPageNoTwo * limitTwo) > (totalPagesTwo)
//                                                                 ? (totalPagesTwo)
//                                                                 : currentPageNoTwo * limitTwo}
//                                                 </h5>
//                                                 <span>of</span>
//                                                 <h5 className='PageNumbers'>{totalPagesTwo}</h5>
//                                                 <Pagination>
//                                                     <Pagination.Prev onClick={prevClickTwo} disabled={currentPageNoTwo == 1} />
//                                                     {
//                                                         PaginationComponentTwo(currentPageNoTwo)
//                                                     }
//                                                     <Pagination.Next onClick={nextClickTwo} disabled={currentPageNoTwo == maxPageAllowedTwo} />
//                                                 </Pagination>
//                                             </div>
//                                     }

//                                 </div>
//                             </Tab>
//                         </Tabs>
//                     </div>
//                 </div>
//                 <div className="SiteRightContainer">
//                     <div style={{ display: devTemClicked || activityLogShow ? "none" : "block" }}>
//                         <form style={{ height: "auto" }} onSubmit={handleSubmit((e) => onSubmitTemplate(e))}>
//                             <div className='ml50 mt20' style={{ float: "left", display: deviceTemplateList.length > 0 ? "block" : "none" }}>
//                                 <OverlayTrigger
//                                     placement='bottom'
//                                     overlay={
//                                         <Tooltip>
//                                             Go to back template list
//                                         </Tooltip>
//                                     }
//                                 >
//                                     <button className="DataAccessEdit"
//                                         style={{ float: "left" }}
//                                         onClick={(e) => {
//                                             e.preventDefault();
//                                             setDevTemClicked(true)
//                                             setDisplayButtons(false);
//                                         }} >
//                                         <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
//                                     </button>
//                                 </OverlayTrigger>
//                                 <p className='templatedevicename'>{selTempDevice?.Name}</p>
//                             </div>
//                             <div className="AlertActivity-form TemplateHgtDiv" style={{ padding: "0px 50px 0px 50px", width: "100%" }}>
//                                 <div className="inputMainContainer w40dot75rem mt35">
//                                     <div className="inputTypeLabelContainer">
//                                         <label className='formFieldLabels'>Template Name*</label>
//                                         <input
//                                             className='inputType'
//                                             placeholder="Enter alert template name here"
//                                             {...register("TemplateName")}
//                                             defaultValue={selectedTemplate.TemplateName}
//                                             onKeyPress={(e) => {
//                                                 onSpecialCharacter(e);
//                                                 setTemplateNameError('')
//                                             }}
//                                             onChange={() => setTemplateNameError('')}
//                                             disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
//                                         />
//                                         <p className="Error_P" style={{ float: "left" }}><span> {templateNameError} </span></p>
//                                     </div>
//                                 </div>
//                                 <div className="inputMainContainer w40dot75rem mt35">
//                                     <div className="inputTypeLabelContainer">
//                                         <label className='formFieldLabels'>Device Type*</label>
//                                         <select
//                                             className='inputType ArrowSelectClass'
//                                             placeholder="Select Device type"
//                                             {...register("DeviceTypeId")}
//                                             defaultValue={selectedTemplate.DeviceTypeId}
//                                             onChange={e => {
//                                                 OnDeviceTypeChanges(e)
//                                                 setTemplateDeviceTypeError('')
//                                             }}
//                                             disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
//                                         >
//                                             <option key="0" disabled={false} value="0">
//                                                 Select Device type
//                                             </option>
//                                             {
//                                                 devicesmasterlist &&
//                                                 devicesmasterlist.deviceTypeList &&
//                                                 devicesmasterlist.deviceTypeList.map(devtype =>
//                                                     <option
//                                                         value={devtype.deviceTypeID}
//                                                         key={devtype.deviceTypeID}>
//                                                         {devtype.deviceType}
//                                                     </option>)
//                                             }
//                                         </select>
//                                         <p className="Error_P" style={{ float: "left" }}><span> {templateDeviceTypeError} </span></p>
//                                     </div>
//                                 </div>
//                                 <div className="inputMainContainer w40dot75rem mt35">
//                                     <div className="inputTypeLabelContainer">
//                                         <label className='formFieldLabels'>Alert Type*</label>
//                                         <select
//                                             className='inputType ArrowSelectClass'
//                                             placeholder="Select Device type"
//                                             {...register("AlertCodeId")}
//                                             defaultValue={selectedTemplate.AlertCodeId}
//                                             onChange={e => { setValue('AlertCodeId', e.target.value); setTemplateAlertError('') }}
//                                             disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
//                                         >
//                                             <option key="0" disabled={false} value="0">
//                                                 Select alert type
//                                             </option>
//                                             {
//                                                 devicesmasterlist &&
//                                                 devicesmasterlist.alertCodeList &&
//                                                 devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
//                                                     <option
//                                                         value={alertCode.alertCodeId}
//                                                         key={alertCode.alertCodeId}>
//                                                         {alertCode.combinedCode}
//                                                     </option>)
//                                             }
//                                         </select>
//                                         <p className="Error_P" style={{ float: "left" }}><span> {templateAlertError} </span></p>
//                                     </div>
//                                 </div>
//                                 <div className="inputMainContainer w40dot75rem mt35">
//                                     <div className="inputTypeLabelContainer">
//                                         <label className='formFieldLabels'>Customer Name*</label>
//                                         <select
//                                             className='inputType ArrowSelectClass'
//                                             placeholder="Select Device type"
//                                             {...register("ClientId")}
//                                             defaultValue={selectedTemplate.ClientId}
//                                             onChange={e => {
//                                                 setValue('ClientId', e.target.value)
//                                                 setDeviceList([])
//                                                 setTemplateCustomerError('')
//                                             }}
//                                             disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
//                                         >
//                                             <option key="0" disabled={false} value="0">
//                                                 Select Customer
//                                             </option>
//                                             {
//                                                 customerData.map((item) => (
//                                                     <option
//                                                         value={item.CustomerId}
//                                                         key={item.CustomerId}
//                                                     >
//                                                         {item.CustomerName}
//                                                     </option>
//                                                 ))
//                                             }
//                                         </select>
//                                         <p className="Error_P" style={{ float: "left" }}><span> {templateCustomerError} </span></p>
//                                     </div>
//                                 </div>

//                                 <div className="grid-container Notificationgrid-container grid-container--fit mb-0">
//                                     <div className="inputMainContainer w40dot75rem mt35">
//                                         <div className="inputTypeLabelContainer">
//                                             <label className='formFieldLabels'>Site*</label>
//                                             {
//                                                 issensor
//                                                 ?
//                                                 <select
//                                                 id="sites"
//                                                 className='inputType ArrowSelectClass'
//                                                 placeholder="Select Site"  
//                                                 value={siteIndivSelected}
//                                                 onChange={(e) => {
//                                                     let obj = {
//                                                         value: e.target.value,
//                                                         label: document.getElementById('sites').selectedOptions[0].textContent
//                                                     }
//                                                     let tempArr = []
//                                                     tempArr.push(obj);
//                                                     setSiteIndivSelected(e.target.value);
//                                                     setSiteSelected(tempArr);
//                                                     setTemplateSiteError('');
//                                                     // setValue('IDSPanelZoneTypeId', "0")
//                                                 }}  
//                                                 disabled={!isAlertTemplateEdit}
//                                                 >
//                                                 <option key="0" disabled={false} value="0">
//                                                     Select Site
//                                                 </option>
//                                                 {
//                                                     siteList.map((item) => (
//                                                         <option
//                                                         key={item.value}
//                                                         name={item.label}
//                                                         value={item.value}
//                                                         >
//                                                         {item.label}
//                                                         </option>
//                                                     ))
//                                                 }
//                                                 </select>
//                                                 : 
//                                                 <MultiSelect
//                                                 options={siteList}
//                                                 value={siteSelected}
//                                                 onChange={(e) => {
//                                                     setSiteSelected(e);
//                                                     setTemplateSiteError('');
//                                                 }}
//                                                 disabled={!isAlertTemplateEdit}
//                                                 overrideStrings={{
//                                                     selectSomeItems: "Select site",
//                                                     allItemsAreSelected: "All sites are selected"
//                                                 }}
//                                                 ArrowRenderer={() =>
//                                                     <DropArrow />
//                                                 }
//                                             />
//                                             }
//                                             <p className="Error_P" style={{ float: "left" }}><span> {templateSiteError} </span></p>
//                                         </div>
//                                     </div>

//                                     <div className="inputMainContainer w40dot75rem mt35">
//                                         <div className="inputTypeLabelContainer">
//                                             <label className='formFieldLabels'>Device*</label>
//                                             {
//                                                 issensor
//                                                 ?
//                                                 <select
//                                                 id="deviceList"
//                                                 className='inputType ArrowSelectClass'
//                                                 placeholder="Select Device"  
//                                                 value={deviceIndivSelected}
//                                                 onChange={(e) => {
//                                                     let obj = {
//                                                         value: e.target.value,
//                                                         label: document.getElementById('deviceList').selectedOptions[0].textContent
//                                                     }
//                                                     let tempArr = []
//                                                     tempArr.push(obj);
//                                                     setDeviceIndivSelected(e.target.value);
//                                                     setDeviceSelected(tempArr);
//                                                     setTemplateDeviceError(''); 
//                                                     // setValue('IDSPanelZoneTypeId', "0")
//                                                 }}  
//                                                 disabled={!isAlertTemplateEdit}
//                                                 >
//                                                 <option key="0" disabled={false} value="0">
//                                                     Select Device
//                                                 </option>
//                                                 {
//                                                     deviceList.map((item) => (
//                                                         <option
//                                                         key={item.value}
//                                                         name={item.label}
//                                                         value={item.value}
//                                                         >
//                                                         {item.label}
//                                                         </option>
//                                                     ))
//                                                 }
//                                                 </select>
//                                                 :
//                                                 <MultiSelect
//                                                 placeholder="Select Device"
//                                                 options={deviceList}
//                                                 value={deviceSelected}
//                                                 disabled={!isAlertTemplateEdit}
//                                                 onChange={(e) => {
//                                                     setDeviceSelected(e)
//                                                     setTemplateDeviceError('')
//                                                 }}
//                                                 overrideStrings={{
//                                                     selectSomeItems: "Select Device",
//                                                     allItemsAreSelected: "All devices are selected"
//                                                 }}
//                                                 ArrowRenderer={() =>
//                                                     <DropArrow />
//                                                 }
//                                             />
//                                             }
//                                             <p className="Error_P" style={{ float: "left" }}><span> {templateDeviceError} </span></p>
//                                         </div>
//                                     </div>

//                                     {issensor &&
//                                         <div className="inputMainContainer w40dot75rem mt35">
//                                             <div className="inputTypeLabelContainer">
//                                                 <label className='formFieldLabels'>Sensor*</label>
//                                                 <select
//                                                     className='inputType ArrowSelectClass'
//                                                     placeholder="Select Sensor"
//                                                     {...register("IDSPanelZoneTypeId")}
//                                                     defaultValue={selectedTemplate.IDSPanelZoneTypeId}
//                                                     onChange={e => {
//                                                         setValue('IDSPanelZoneTypeId', e.target.value)
//                                                         setTemplateSensorError('')
//                                                     }}
//                                                     disabled={isStore || !isAlertTemplateEdit}
//                                                 >
//                                                     <option key="0" disabled={false} value="0">
//                                                         Select Sensor
//                                                     </option>
//                                                     {
//                                                         zoneList.map(zone =>
//                                                             <option
//                                                                 value={zone.iDSPanelZoneTypeID}
//                                                                 key={zone.iDSPanelZoneTypeID}>
//                                                                 {zone.zoneType}
//                                                             </option>
//                                                         )
//                                                     }
//                                                 </select>
//                                                 <p className="Error_P" style={{ float: "left" }}><span> {templateSensorError} </span></p>
//                                             </div>
//                                         </div>
//                                     }

//                                 </div>

//                                 <Scheduler
//                                     isAlertTemplateEdit={isAlertTemplateEdit}
//                                     selectedItem={selectedItem}
//                                     setSelectedItem={setSelectedItem}
//                                     workflows={workflows}
//                                     severities={severities}
//                                     customData={customData}
//                                     setCustomData={setCustomData}
//                                     sevenDaysData={sevenDaysData}
//                                     setSevenDaysData={setSevenDaysData}
//                                     gettingData={gettingData}
//                                     setGettingData={setGettingData}
//                                     selectedSiteTab={selectedSiteTab}
//                                     setSelectedSiteTab={setSelectedSiteTab}
//                                     dateCustom={dateCustom}
//                                     setDateCustom={setDateCustom}
//                                     activityLogShow={activityLogShow}
//                                     setActivityLogShow={setActivityLogShow}
//                                     alertTemplateObj={alertTemplateObj}
//                                     isStore={isStore}
//                                 />

//                             </div>
//                         </form>
//                     </div>
//                     {
//                         deviceTemplateList.length > 0 && devTemClicked && !activityLogShow
//                             ? <AlertTemplateList list={deviceTemplateList} getAlertTemplateById={getAlertTemplateById} setDisplayButtons={setDisplayButtons} isDarkTheme={isDarkTheme} />
//                             : null
//                     }
//                     {
//                         activityLogShow
//                             ?
//                             <ActivityLogComponent
//                                 isAlertTemplateEdit={isAlertTemplateEdit}
//                                 sevenDaysData={sevenDaysData}
//                                 setSevenDaysData={setSevenDaysData}
//                                 workflows={workflows}
//                                 severities={severities}
//                                 gettingData={gettingData}
//                                 setGettingData={setGettingData}
//                                 selTempDevice={selTempDevice}
//                                 activityLogShow={activityLogShow}
//                                 setActivityLogShow={setActivityLogShow}
//                                 alertTemplateObj={alertTemplateObj}
//                             />
//                             : null
//                     }
//                 </div>
//             </div>
//         </>
//     );
// })