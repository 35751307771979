import React, { useEffect, useState, useRef } from "react"
import { useRouteMatch, useHistory } from 'react-router-dom'

import { ChatFlowWebArrowIcon, MosaicTwowayStop, MosaicTwowayStart, MosaicHooterOff, MosaicHooterOn, AssociateCamera } from '../../assets/images'

import { Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { getAlertDetailsById } from "../../services/index";
import { HooterOnOffRequest, TwoWayCommunicationRequest } from "../../services/alertAuthService"

import WFPreview from '../Workflow/WFPreview';
import ChatflowContainer from "./ChatflowContainer"
import { AddChatWindowOpenedTime, GetAlertNotAckRecord } from '../../utils/IndexedDBStorageUtil'
import { mixpanel } from '../../utils/MixPanelUtil'
import { WarningToast } from "../../CustomHooks/WarningToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { CHAT_CAM_CHANGES } from "../../config";

export default function ChatflowPageContainer(props) {
  const messagesEndRef = useRef(null);
  let alertDetRef = useRef();
  let match1 = useRouteMatch("/chatflow/:alertId") //to fetch id from url
  let alertId = match1.params['alertId']
  let history = useHistory()

  //#region useState start
  const [singleAlertDet, setSingleAlertDet] = useState(null)
  const [showworkflowscreen, setShowworkflowscreen] = useState(false)
  const [isHooterOn, setIsHooterOn] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  //#endregion useState end

  //#region useEffect start
  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (props.hootWay != null) {
      let arr = props.hootWay.filter((item) => item.alertId == alertId);
      let htStatus = arr[0]?.hooterStatus;
      let twoWayStatus = arr[0]?.twoWayStatus;
      let disabledBtn = arr[0]?.btnDisabled;
      setIsHooterOn(htStatus);
      setIsSoundOn(twoWayStatus);
      setIsBtnDisabled(disabledBtn);
    }
  }, [props])

  useEffect(() => {
    const trackChatroomDuration = async () => {
      try {
        mixpanel.time_event("Chatroom Duration");
        const resp = await getAlertDetailsById(alertId);
        if (resp.status === "Ok") {
          const message = resp.message && resp.message[0];
          const conversationId = `AlertID_${message.alertId}_UserID_${message.assignedTo}`;
          mixpanel.track("Chatroom Duration", {
            ConversationId: conversationId,
            AlertTemplate: message.alertTemplate,
            AlertId: message.alertId,
            Severity: message.severity,
          });
        } else {
          mixpanel.track("Chatroom Duration", { AlertId: alertId });
        }
      } catch (error) {
        console.error(error);
      }
    };

    trackChatroomDuration();

    return () => {
      mixpanel.track("Chatroom Duration", { AlertId: alertId });
    };
  }, [alertId]);

  /* Rupesh's Revised code useEffect async above code refactored*/
  useEffect(() => {
    const fetchAlertData = async () => {
      if (alertId && alertId != null) {
        GetAlertDetails()
      }
    }
    fetchAlertData();
  }, [alertId])

  useEffect(() => {
    console.log("rupesh checks onleaveChatFlowPageContainer",)
    let alertdetails = singleAlertDet;
    return () => {
      console.log("Exiting Chatflow", props.hootWay, alertDetRef.current);
      alertdetails = alertDetRef.current;
      let arr = props.hootWay;
      console.log("hootWayData", arr, alertdetails);
      let tempArr = arr.filter((item) => item.alertId != alertdetails?.alertId);
      console.log("hootWayData", tempArr);
      props.setHootWay(tempArr);
      onleaveChatFlowPageContainer(alertdetails);
      let isPresent = localStorage.getItem('hooterData');
      if(isPresent){
          let arr = JSON.parse(localStorage.getItem('hooterData'));
          let filteredArr = arr.filter((item) => item.alertId != alertdetails?.alertId);
          if(filteredArr.length == 0){
              localStorage.removeItem('hooterData');
            }else{
              localStorage.setItem('hooterData', JSON.stringify(filteredArr));
            }
      }
    }
  }, [])

  useEffect(() => {
    alertDetRef.current = singleAlertDet;
  }, [singleAlertDet])
  //#endregion useEffect end

  //#region function start

  function HooterClick(hooter, chat, isCloseBtn) {
    var resp = null
    if (hooter && hooter == 'on') {
      // let isAlreadySelected = props.hootWay.some((item) => item.hooterStatus === false);
      // if(isAlreadySelected){
      //     WarningToast("Hooter already enabled for another alert, close it and then start this.");
      //     return;
      // }
      setIsHooterOn(false)
      if (!isCloseBtn) {
        let tempArr = props.hootWay.map((item) => {
          let obj;
          if (item.alertId == chat?.alertId) {
            obj = {
              ...item,
              hooterStatus: false
            }
          } else {
            obj = {
              ...item
            }
          }
          return obj;
        })
        props.setHootWay(tempArr);
      }
      console.log("HooterOnOffRequest", chat?.privateIpAddress, chat?.port, 'on', chat?.panelBrand, props?.cctvTypeName, props?.camUserName, props?.camPassword, props?.brandName)
      resp = HooterOnOffReq(chat?.privateIpAddress, chat?.port, 'on', chat?.panelBrand, props?.cctvTypeName, props?.camUserName, props?.camPassword, props?.brandName)
    }
    if (hooter && hooter == 'off') {
      console.log("rupesh checks inside Hooter with OFF",)
      setIsHooterOn(true)
      if (!isCloseBtn) {
        let tempArr = props.hootWay.map((item) => {
          let obj;
          if (item.alertId == chat?.alertId) {
            obj = {
              ...item,
              hooterStatus: true
            }
          } else {
            obj = {
              ...item
            }
          }
          return obj;
        })
        props.setHootWay(tempArr);
      }
      resp = HooterOnOffReq(chat?.privateIpAddress, chat?.port, 'off', chat?.panelBrand, props?.cctvTypeName, props?.camUserName, props?.camPassword, props?.brandName)
    }
    // if (resp && resp != null) {
    //     Swal.fire({text:resp})
    // }
  }

  function TwoWayAudioClick(audio, chat, isCloseBtn) {
    var resp = null;
    var resp1 = "";
    if (audio && audio == 'start') {
      let isAlreadySelected = props.hootWay.some((item) => item.twoWayStatus === false);
      if (isAlreadySelected) {
        WarningToast("Two way talk already enabled for another alert, close it and then start this.");
        return;
      }
      setIsSoundOn(false)
      if (!isCloseBtn) {
        let tempArr = props.hootWay.map((item) => {
          let obj;
          if (item.alertId == chat?.alertId) {
            obj = {
              ...item,
              twoWayStatus: false
            }
          } else {
            obj = {
              ...item
            }
          }
          return obj;
        })
        props.setHootWay(tempArr);
      }
      resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)

      resp = TwoWayCommunicationReq(chat?.privateIpAddress, chat?.communicationPort, 'start', chat?.camUserName, chat?.camPassword, chat?.brandName)
    }
    if (audio && audio == 'stop') {
      setIsSoundOn(true)
      if (!isCloseBtn) {
        let tempArr = props.hootWay.map((item) => {
          let obj;
          if (item.alertId == chat?.alertId) {
            obj = {
              ...item,
              twoWayStatus: true
            }
          } else {
            obj = {
              ...item
            }
          }
          return obj;
        })
        props.setHootWay(tempArr);
      }
      resp = TwoWayCommunicationReq(chat?.privateIpAddress, chat?.communicationPort, 'stop', chat?.camUserName, chat?.camPassword, chat?.brandName)
    }
    // if (resp && resp != null) {
    //     Swal.fire({text:resp})
    // }
  }

  const TwoWayCommunicationReq = async (privateIpAddress, communicationPort, action, user, password, brandName) => {
    //debugger
    try {
      if (privateIpAddress && communicationPort && user && password) {
        TwoWayCommunicationRequest(privateIpAddress, communicationPort, action, user, password, brandName).then((resp) => {
          return resp
        })
      }
      else {
        TwoWayCommunicationRequest(privateIpAddress, communicationPort
          , action, user, password, brandName).then((resp) => {

            return resp
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const HooterOnOffReq = async (privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName) => {
    try {
      if (privateIpAddress) {
        HooterOnOffRequest(privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName).then((resp) => {
          return resp
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onleaveChatFlowPageContainer = (singleAlertDet) => {
    console.log("rupesh checks closing in onleaveChatFlowPageContainer function called")
    HooterClick('off', singleAlertDet, true)
    TwoWayAudioClick('stop', singleAlertDet, true)
  }

  const GetAlertDetails = async () => {
    try {
      await getAlertDetailsById(alertId).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message) {
            setSingleAlertDet(resp.message[0])
            props.setCctvTypeName(resp.message[0].cctvtype)
            props.setCamUserName(resp.message[0].camUserName)
            props.setCamPassword(resp.message[0].camPassword)
            props.setBrandName(resp.message[0].brandName)
            if (CHAT_CAM_CHANGES) {
              let secondObj = {
                alertId: alertId,
                alertDetail: resp.message[0],
                display: false,
                logText: "",
                shouldRefresh: false
              }
              props.setAssociateCam([...props.associateCam, secondObj]);
            }
            // props.setAlertDetail(resp.message[0])
            try {
              mixpanel.track("Chatroom - Alert Details", {
                'ConversationId': "AlertID_" + resp.message[0].alertId + "_UserID_" + resp.message[0].assignedTo,
                'AlertTemplate': resp.message[0].alertTemplate,
                'AlertId': resp.message[0].alertId,
                'Severity': resp.message[0].severity
              })
            } catch (error) {
              console.error(error)
            }
            // if(resp.message[0].isAckAlertStepExist == true && resp.message[0].alertStatus == "Alert"){
            //     if (LoggedInUser && LoggedInUser.UserRoleID == 3) {
            //         Setalertnotacktimeout(resp.message[0].alertId)
            //     }

            // }
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const Setalertnotacktimeout = async (id) => {
    try {
      let alertnotacktime = 2
      var res = await GetAlertNotAckRecord()
      res.onsuccess = function (event) {
        if (event.target.result != []) {
          var existingtimer = event.target.result.find(x => x.alertId == id)
          if (existingtimer) {
            return
          } else {
            var notifytimer = setTimeout(() => {
              var escallateimer = setTimeout(() => {
                alert("AlertId_" + id + " - Escallate api calling!")
                clearTimeout(escallateimer)
                //EscallateAlert(id)
              }, alertnotacktime * 60 * 1000)
              AddChatWindowOpenedTime(id, null, escallateimer)
              alert("AlertId_" + id + " - Please acknowledge your alert, if you don't this alert will get reassigned.")
              clearTimeout(notifytimer)
            }, alertnotacktime * 60 * 1000)
            AddChatWindowOpenedTime(id, notifytimer, null)
          }
        }
      };
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setShowworkflowscreen(false)
  }
  const ViewWorkflow = () => {
    if (singleAlertDet != null) {
      if (singleAlertDet.workflowName == "NA") {
        alert("Worflow not configured!")
      } else {
        setShowworkflowscreen(true)
      }
    }
    mixpanel.track("Clicks on Workflow Preview", {
      "ConversationId": "AlertID_" + singleAlertDet.alertId + "_UserID_" + singleAlertDet.assignedTo,
      "AlertTemplate": singleAlertDet.alertTemplate,
      "AlertId": singleAlertDet.alertId,
      "Severity": singleAlertDet.severity
    })
  }
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  }

  const handleDragOpen = () => {
    if (props.openButton1) {
      ErrorToast("Kindly close the existing associate camera window, to open a new one.");
    } else {
      props.setOpenButton1(true)
      props.setAlertDetail(singleAlertDet)
    }
  }

  //#endregion function end

  return (
    <div className="MainContentMargin">
      <div className="col-lg-12 pr-0 chatFlowWebMaiContainer">
        {singleAlertDet && (
          <>
            <div className="chatFlowWebHeaderTop d-flex align-items-center justify-content-center">
              {showworkflowscreen && (
                <WFPreview
                  show={showworkflowscreen}
                  onClose={handleClose}
                  workflowName={singleAlertDet.workflowName}
                  elements={singleAlertDet.metadata.elements}
                  nodesDraggable={false}
                />
              )}
              <div className="container-fluid m-0">
                <div className="row">
                  <div className="col-10 d-flex align-items-center">
                    <img
                      className="mr-3 float-left cursor-ptr"
                      src={ChatFlowWebArrowIcon}
                      alt="backarrow"
                      onClick={() => {
                        history.push("/allalerts")
                        if (CHAT_CAM_CHANGES) {
                          let arr = props.associateCam.filter((item) => item.alertId != singleAlertDet.alertId)
                          props.setAssociateCam(arr)
                        }
                      }}
                    />
                    <p className="chatFlowWebHeaderAlertText">
                      {singleAlertDet.alertType}
                    </p>
                    <div
                      className={`chatFlowWebHeaderSeverityBox ${singleAlertDet.severity.toLowerCase()}-bg`}
                    >
                      <p>{singleAlertDet.severity}</p>
                    </div>
                  </div>
                  <div className="col-2 view-workflow-btn pr-0">
                    <div
                      className="chatFlowWebViewWorkflow d-flex align-items-center justify-content-center"
                      onClick={() => ViewWorkflow()}
                    >
                      <p>View Workflow</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chatFlowWebHeaderBottom d-flex align-items-center justify-content-center">
              <>
                <>
                  <div className="col-10">
                    <p className="chatFlowWebHeaderAddress">
                      {singleAlertDet.site} | {singleAlertDet.actualTime} |
                      ID: {singleAlertDet.alertId}
                    </p>
                  </div>
                  <div className="col-2 twoWayCommunication-and-hooter">
                    {!true ? (
                      <button
                        data-title="Associate Camera"
                        className="AssociateCamera AssociateDataTitle"
                      >
                        <img
                          style={{ height: "22px" }}
                          alt="Twoway"
                          src={AssociateCamera}
                          onClick={() => handleDragOpen()}
                        />
                      </button>
                    ) : null}
                    {
                      false
                        ?
                        <>
                          <span>
                            {isSoundOn && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>TwowayStart</Tooltip>}
                              >
                                <button
                                  disabled={isBtnDisabled}
                                  className="TwoWayAudioToggle two-way-and-hooter-on-off"
                                >
                                  <img
                                    style={{ padding: "1px", height: "20px" }}
                                    src={MosaicTwowayStart}
                                    alt="twowaysound on icon"
                                    onClick={() =>
                                      TwoWayAudioClick("start", singleAlertDet)
                                    }
                                  />
                                </button>
                              </OverlayTrigger>
                            )}
                            {!isSoundOn && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>TwowayStop</Tooltip>}
                              >
                                <button
                                  disabled={isBtnDisabled}
                                  className="TwoWayAudioToggle two-way-and-hooter-on-off"
                                >
                                  <img
                                    style={{ height: "16px" }}
                                    src={MosaicTwowayStop}
                                    alt="twowaysound off icon"
                                    onClick={() =>
                                      TwoWayAudioClick("stop", singleAlertDet)
                                    }
                                  />
                                </button>
                              </OverlayTrigger>
                            )}
                          </span>
                          <span>
                            {isHooterOn && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>HooterOn</Tooltip>}
                              >
                                <button
                                  disabled={isBtnDisabled}
                                  className="TwoWayAudioToggle two-way-and-hooter-on-off"
                                >
                                  <img
                                    style={{ padding: "2px", height: "26px" }}
                                    src={MosaicHooterOn}
                                    alt="hooteroff icon"
                                    onClick={() =>
                                      HooterClick("on", singleAlertDet)
                                    }
                                  />
                                </button>
                              </OverlayTrigger>
                            )}
                            {!isHooterOn && (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>HooterOff</Tooltip>}
                              >
                                <button
                                  disabled={isBtnDisabled}
                                  className="TwoWayAudioToggle two-way-and-hooter-on-off"
                                >
                                  <img
                                    style={{ padding: "2px", height: "26px" }}
                                    src={MosaicHooterOff}
                                    alt="hooteron icon"
                                    onClick={() => HooterClick("off", singleAlertDet)}
                                  />
                                </button>
                              </OverlayTrigger>
                            )}
                          </span>
                        </>
                        : null
                    }
                  </div>
                </>
              </>
            </div>

            <div id={"Chatbotcomponentdiv:AlertID-" + singleAlertDet.alertId}></div>
            <div className="chatflow-page-container-div">
              <ChatflowContainer
                scrollToBottom={scrollToBottom}
                AlertDetails={singleAlertDet}
                ConversationId={"AlertID_" + singleAlertDet.alertId + "_UserID_" + localStorage.getItem("UserID")}
                ResetAlertList={props.ResetAlertList}
                hootWay={props.hootWay}
                setHootWay={props.setHootWay}
                isHooterOn={isHooterOn}
                associateCam={props.associateCam}
                setAssociateCam={props.setAssociateCam}
              />
              <div ref={messagesEndRef} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}