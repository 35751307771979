import React, { useState, useRef } from "react";

import { SearchIcon, Cross, EditQc, DeleteQc } from '../../../assets/images'

import Swal from "sweetalert2";

import AddSiteForm from "./SiteConfig/AddSiteForm";
import AddDevices from "./SiteDevices/AddDevices";
import AddSiteGroup from "./SiteGroup/AddSiteGroup";

export default function SiteTemplateStep({
  pageState,
  onSaveSite,
  selectedSite,
  deviceslist,
  cameradeviceslist,
  paneldeviceslist,
  onMoveNext,
  sites,
  onSelectSite,
  onDeleteSite,
  isSiteEdit,
  setIsSiteEdit,
  siteRef,
  sitesToBind,
  siteGroups,
  selectedSiteGroup,
  onSiteGroupSelection,
  selectedSites,
  setSelectedSites,
  updateSitesToSiteGroup,
  onDeleteSiteGroup,
  onAddNewSiteGroup,
  onUpdateSiteGroup,
  selectedSiteTypeList,
  onSearchChange,
  filteredSites,
  filteredSiteGroups,
  selectedDevice,
  OnSaveDevice,
  onDeviceSelection,
  getdeviceslistdata,
  onAddNewDevice,
  isDeviceEdit,
  setIsDeviceEdit,
  devicesmasterlist,
  OnAddIDSPanel,
  clients,
  getCliets,
  country,
  siteTypes,
  setSiteTypes,
  countryState,
  setCountryState,
  ertLevels,
  siteERTOnClick,
  selectedERT,
  onAddERTMemberClick,
  addNewERTMemberClick,
  emeContactList,
  onDeleteERT,
  isERTSectionVisible,
  setImportedPanelHeaders,
  onSensorSelection,
  associatedDevices,
  onAssociate,
  selectedSensor,
  associateCamSecVisibility,
  importedPanelHeaders,
  setBulkDevToUpload,
  bulkDevToUpload,
  OnAddBulkDevicesClick,
  importedFileHeaders,
  selectedHeaders,
  setImportedFileHeaders,
  onPanelBulkUpload,
  setSelectedHeaders,
  setFilteredSites,
  bulkUpload,
  setBulkUpload,
  isSingleOrBulkSelection,
  setIsSingleOrBulkSelection,
  clearImportedPanelHeaders,
  checkForAlertsAndDelDevice,
  selectedZone,
  setselectedZone,
  userOptions,
  onUserRoleChange,
  selectedUserRole,
  setSelectedUserRole,
  siteUserFieldsVisibility,
  selectedSiteTypeClick,
  selectedCamHeaders,
  setSelectedCamHeaders,
  clearImportedCamHeaders,
  deviceBulkUploadResponse,
  setImportedFileData,
  panelBulkUploadResponse,
  setPanelBulkUploadResponse,
  setPanelBulkUploadError,
  panelBulkUploadError,
  setDeviceBulkUploadResponse,
  selectedItems,
  setSelectedItems,
  isSubmitting,
  setSelectedSite,
  setParentIsEncoding,
  setParentIsPlaybackEncoding,
  parentIsEnablingTwoWayDevice,
  parentIsEncoding,
  parentIsPlaybackEncoding,
  setParentIsEnablingTwoWayDevice,
  setParentIsEnablingTwoWayInterface,
  parentIsEnablingTwoWayInterface,
  setParentIsEnablingInternalHooter,
  setParentIsEnablingExternalHooter,
  setParentPanelIsEnablingHooter,
  parentIsEnablingInternalHooter,
  setSelectedEntitySubtype,
  setParentIsEnablingPlaybackTimeStampsToUTC,
  parentIsEnablingExternalHooter,
  parentIsEnablingPlaybackTimeStampsToUTC,
  setParentIsEnablingPlaybackLongDateFormat,
  parentIsEnablingPlaybackLongDateFormat,
  parentPanelIsEnablingHooter,
  interfaceTypeData,
  interfaceSubTypeData,
  setInterfaceSubTypeData,
  interfaceDataList,
  onInterfaceFormSubmit,
  onSelectInterface,
  selectedInterface,
  setSelectedInterface,
  onDeleteInterface,
  isInterfaceEdit,
  setIsInterfaceEdit,
  getInterfaceData,
  setSelectedDevice,
  setAssociateCamSecVisibility,
  cctvTypeData,
  setInterfaceDataList,
  setcameradeviceslist,
  setpaneldeviceslist,
  rtspValueChanged,
  setRtspValueChanged,
  isDarkTheme,
  setUnknownBrandUrl,
  siteFormObj,
  setSiteFormObj,
  validationError,
  savebtnClick,
  setSavebtnClick,
  buttonDisable,
  timezone,
  setTimeZone,
  parentIsEnablingPTZ,
  setParentIsEnablingPTZ,
  parentIsEnablingUTCPlayback,
  setParentIsEnablingUTCPlayback
}) {
  const focusSearchDiv = useRef();

  //#region useState start
  const [issearch, setissearch] = useState(true);

  //#endregion useState end

  function onDeleteChange(e, siteid, name) {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: `You really want to delete ${name} site!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteSite(siteid);
      }
    });
  }

  function onDeleteInterfaceChange(e, siteid, name) {

    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting the Interface will delete all the entites associated with it. Do you really want to delete ${name} interface!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteInterface(siteid)
      }
    });
  }

  function ToggeleSearch() {
    try {
      setissearch(!issearch);
      onSearchChange("", selectedSiteTypeList);
      if (focusSearchDiv.current) focusSearchDiv.current.focus();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="col-lg-12 mt-2">
        <>
          {pageState == "Site Configuration" && (
            <>
              <AddSiteForm
                onSubmit={onSaveSite}
                site={selectedSite}
                isSiteEdit={isSiteEdit}
                setIsSiteEdit={setIsSiteEdit}
                ref={siteRef}
                clients={clients}
                getCliets={getCliets}
                country={country}
                siteTypes={siteTypes}
                setSiteTypes={setSiteTypes}
                countryState={countryState}
                setCountryState={setCountryState}
                onSelectSite={onSelectSite}
                ertLevels={ertLevels}
                siteERTOnClick={siteERTOnClick}
                selectedERT={selectedERT}
                onAddERTMemberClick={onAddERTMemberClick}
                addNewERTMemberClick={addNewERTMemberClick}
                emeContactList={emeContactList}
                onDeleteERT={onDeleteERT}
                isERTSectionVisible={isERTSectionVisible}
                userOptions={userOptions}
                onUserRoleChange={onUserRoleChange}
                selectedUserRole={selectedUserRole}
                setSelectedUserRole={setSelectedUserRole}
                siteUserFieldsVisibility={siteUserFieldsVisibility}
                filteredSites={filteredSites}
                cameradeviceslist={cameradeviceslist}
                onDeviceSelection={onDeviceSelection}
                selectedDevice={selectedDevice}
                devicesmasterlist={devicesmasterlist}
                isSubmitting={isSubmitting}
                sites={sites}
                OnAddIDSPanel={OnAddIDSPanel}
                paneldeviceslist={paneldeviceslist}
                OnSaveDevice={OnSaveDevice}
                getdeviceslistdata={getdeviceslistdata}
                onSearchChange={onSearchChange}
                setSelectedSite={setSelectedSite}
                setSelectedDevice={setSelectedDevice}
                onDeleteChange={onDeleteChange}
                onAddNewDevice={onAddNewDevice}
                checkForAlertsAndDelDevice={checkForAlertsAndDelDevice}
                interfaceTypeData={interfaceTypeData}
                interfaceSubTypeData={interfaceSubTypeData}
                setInterfaceSubTypeData={setInterfaceSubTypeData}
                interfaceDataList={interfaceDataList}
                onInterfaceFormSubmit={onInterfaceFormSubmit}
                onSelectInterface={onSelectInterface}
                interfaceDevice={selectedInterface}
                setSelectedInterface={setSelectedInterface}
                setBulkUpload={setBulkUpload}
                setBulkDevToUpload={setBulkDevToUpload}
                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                bulkUpload={bulkUpload}
                selectedCamHeaders={selectedCamHeaders}
                OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                setSelectedCamHeaders={setSelectedCamHeaders}
                clearImportedCamHeaders={clearImportedCamHeaders}
                deviceBulkUploadResponse={deviceBulkUploadResponse}
                setImportedFileData={setImportedFileData}
                setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                selectedHeaders={selectedHeaders}
                onPanelBulkUpload={onPanelBulkUpload}
                setSelectedHeaders={setSelectedHeaders}
                clearImportedPanelHeaders={clearImportedPanelHeaders}
                panelBulkUploadResponse={panelBulkUploadResponse}
                setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                panelBulkUploadError={panelBulkUploadError}
                setPanelBulkUploadError={setPanelBulkUploadError}
                // selectedInterface = {selectedInterface}
                isInterfaceEdit={isInterfaceEdit}
                setIsInterfaceEdit={setIsInterfaceEdit}
                setIsDeviceEdit={setIsDeviceEdit}
                getInterfaceData={getInterfaceData}
                onDeleteInterfaceChange={onDeleteInterfaceChange}
                isDeviceEdit={isDeviceEdit}
                cctvTypeData={cctvTypeData}
                setFilteredSites={setFilteredSites}
                importedFileHeaders={importedFileHeaders}
                setImportedFileHeaders={setImportedFileHeaders}
                importedPanelHeaders={importedPanelHeaders}
                setImportedPanelHeaders={setImportedPanelHeaders}
                selectedZone={selectedZone}
                setselectedZone={setselectedZone}
                associateCamSecVisibility={associateCamSecVisibility}
                setAssociateCamSecVisibility={setAssociateCamSecVisibility}
                onSensorSelection={onSensorSelection}
                associatedDevices={associatedDevices}
                onAssociate={onAssociate}
                selectedSensor={selectedSensor}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setInterfaceDataList={setInterfaceDataList}
                setcameradeviceslist={setcameradeviceslist}
                setpaneldeviceslist={setpaneldeviceslist}
                parentIsEncoding={parentIsEncoding}
                parentIsPlaybackEncoding={parentIsPlaybackEncoding}
                setParentIsEncoding={setParentIsEncoding}
                setParentIsPlaybackEncoding={setParentIsPlaybackEncoding}
                setParentIsEnablingTwoWayDevice={setParentIsEnablingTwoWayDevice}
                setParentIsEnablingTwoWayInterface={setParentIsEnablingTwoWayInterface}
                parentIsEnablingTwoWayInterface={parentIsEnablingTwoWayInterface}
                parentIsEnablingTwoWayDevice={parentIsEnablingTwoWayDevice}
                setParentIsEnablingInternalHooter={setParentIsEnablingInternalHooter}
                setParentIsEnablingExternalHooter={setParentIsEnablingExternalHooter}
                setParentPanelIsEnablingHooter={setParentPanelIsEnablingHooter}
                setSelectedEntitySubtype={setSelectedEntitySubtype}
                setParentIsEnablingPlaybackTimeStampsToUTC={setParentIsEnablingPlaybackTimeStampsToUTC}
                parentIsEnablingPlaybackTimeStampsToUTC={parentIsEnablingPlaybackTimeStampsToUTC}
                parentIsEnablingExternalHooter={parentIsEnablingExternalHooter}
                parentIsEnablingInternalHooter={parentIsEnablingInternalHooter}
                setParentIsEnablingPlaybackLongDateFormat={setParentIsEnablingPlaybackLongDateFormat}
                parentIsEnablingPlaybackLongDateFormat={parentIsEnablingPlaybackLongDateFormat}
                parentPanelIsEnablingHooter={parentPanelIsEnablingHooter}
                rtspValueChanged={rtspValueChanged}
                setRtspValueChanged={setRtspValueChanged}
                isDarkTheme={isDarkTheme}
                setUnknownBrandUrl = {setUnknownBrandUrl}
                siteFormObj={siteFormObj}
                setSiteFormObj={setSiteFormObj}
                validationError={validationError}
                savebtnClick={savebtnClick}
                setSavebtnClick={setSavebtnClick}
                buttonDisable={buttonDisable}
                timezone={timezone}
                setTimeZone={setTimeZone}
                parentIsEnablingPTZ={parentIsEnablingPTZ}
                setParentIsEnablingPTZ={setParentIsEnablingPTZ}
                parentIsEnablingUTCPlayback={parentIsEnablingUTCPlayback}
                setParentIsEnablingUTCPlayback={setParentIsEnablingUTCPlayback}
              />
            </>
          )}
          {pageState == "Manage Devices" && (
            <>
              {
                // deviceslist &&
                <AddDevices
                  deviceslist={deviceslist}
                  cameradeviceslist={cameradeviceslist}
                  paneldeviceslist={paneldeviceslist}
                  onFinish={onMoveNext}
                  sites={sites}
                  selectedDevice={selectedDevice}
                  OnSaveDevice={OnSaveDevice}
                  onDeviceSelection={onDeviceSelection}
                  onAddNewDevice={onAddNewDevice}
                  isDeviceEdit={isDeviceEdit}
                  setIsDeviceEdit={setIsDeviceEdit}
                  devicesmasterlist={devicesmasterlist}
                  OnAddIDSPanel={OnAddIDSPanel}
                  onSensorSelection={onSensorSelection}
                  associatedDevices={associatedDevices}
                  onAssociate={onAssociate}
                  selectedSensor={selectedSensor}
                  associateCamSecVisibility={associateCamSecVisibility}
                  setBulkDevToUpload={setBulkDevToUpload}
                  bulkDevToUpload={bulkDevToUpload}
                  OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                  selectedHeaders={selectedHeaders}
                  onPanelBulkUpload={onPanelBulkUpload}
                  setSelectedHeaders={setSelectedHeaders}
                  getdeviceslistdata={getdeviceslistdata}
                  bulkUpload={bulkUpload}
                  setBulkUpload={setBulkUpload}
                  isSingleOrBulkSelection={isSingleOrBulkSelection}
                  setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                  checkForAlertsAndDelDevice={checkForAlertsAndDelDevice}
                  selectedZone={selectedZone}
                  setselectedZone={setselectedZone}
                  clearImportedPanelHeaders={clearImportedPanelHeaders}
                  selectedCamHeaders={selectedCamHeaders}
                  setSelectedCamHeaders={setSelectedCamHeaders}
                  clearImportedCamHeaders={clearImportedCamHeaders}
                  deviceBulkUploadResponse={deviceBulkUploadResponse}
                  setImportedFileData={setImportedFileData}
                  panelBulkUploadResponse={panelBulkUploadResponse}
                  setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                  panelBulkUploadError={panelBulkUploadError}
                  setPanelBulkUploadError={setPanelBulkUploadError}
                  setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isSubmitting={isSubmitting}
                  importedFileHeaders={importedFileHeaders}
                  setImportedFileHeaders={setImportedFileHeaders}
                />
              }
            </>
          )}
          {pageState == "Manage Site Groups" && (
            <>
              {
                <AddSiteGroup
                  sites={sites}
                  siteGroups={siteGroups}
                  sitesToBind={sitesToBind}
                  onFinish={onMoveNext}
                  selectedSiteGroup={selectedSiteGroup}
                  onSiteGroupSelection={onSiteGroupSelection}
                  selectedSites={selectedSites}
                  setSelectedSites={setSelectedSites}
                  onAddSelectedSites={updateSitesToSiteGroup}
                  onDeleteSiteGroup={onDeleteSiteGroup}
                  onAddNewSiteGroup={onAddNewSiteGroup}
                  onUpdateSiteGroup={onUpdateSiteGroup}
                />
              }

              <div className="RightListSide">
                <div className="m-2 HeaderSiteList">
                  {issearch && (
                    <>
                      <p
                        className={
                          selectedSiteTypeList == "Site List"
                            ? "active-all-light active-block active-text button-common-margin"
                            : "inactive-text button-common-margin"
                        }
                        onClick={() => selectedSiteTypeClick("Site List")}
                      >
                        Site List
                      </p>
                      <p
                        className={
                          selectedSiteTypeList == "Site Group"
                            ? "active-all-light active-block active-text button-common-margin"
                            : "inactive-text button-common-margin"
                        }
                        onClick={() => selectedSiteTypeClick("Site Group")}
                      >
                        Site Group
                      </p>
                    </>
                  )}
                  {!issearch && selectedSiteTypeList == "Site List" && (
                    <input
                      id="Search_txt"
                      className="SearchViewInput1"
                      ref={focusSearchDiv}
                      type="text"
                      onChange={(e) =>
                        onSearchChange(e.target.value, selectedSiteTypeList)
                      }
                      placeholder="Search Site"
                      autoFocus
                    />
                  )}
                  {!issearch && selectedSiteTypeList == "Site Group" && (
                    <input
                      id="Search_txt"
                      className="SearchViewInput1"
                      ref={focusSearchDiv}
                      type="text"
                      onChange={(e) =>
                        onSearchChange(e.target.value, selectedSiteTypeList)
                      }
                      placeholder="Search Site Group"
                      autoFocus
                    />
                  )}

                  <div className="SearchTextDivConfigTool">
                    {issearch && (
                      <img
                        className="EyeIcon"
                        alt="search icon"
                        src={SearchIcon}
                        onClick={() => ToggeleSearch()}
                      />
                    )}
                    {!issearch && (
                      <img
                        className="EyeIcon"
                        alt="cancel icon"
                        src={Cross}
                        onClick={() => ToggeleSearch()}
                      />
                    )}
                  </div>
                </div>
                <div className="SiteListMain">
                  {selectedSiteTypeList &&
                    selectedSiteTypeList == "Site List" &&
                    filteredSites &&
                    filteredSites.map((site) => {
                      return (
                        <div
                          className="OneList"
                          key={site.SiteID}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectSite(site.SiteID, true);
                          }}
                        >
                          <p className="ListofText">
                            {site.SiteName}
                            <span className="SiteNameText"> - {site.BranchID}</span>
                          </p>
                          <div>
                            <img className="m-2" src={EditQc} alt="edit icon" />
                            <img
                              src={DeleteQc}
                              alt="delete icon"
                              onClick={(e) =>
                                onDeleteChange(e, site.SiteID, site.SiteName)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  {!filteredSites && selectedSiteTypeList == "Site List" && (
                     <div class="no-report-found"><p class="noRecordFoundText">No Sites Available.</p></div>
                  )}

                  {selectedSiteTypeList &&
                    selectedSiteTypeList == "Site Group" &&
                    filteredSiteGroups &&
                    filteredSiteGroups.map((siteGroup) => {
                      return (
                        <div
                          className="OneList"
                          key={siteGroup.siteGroupID}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectSite(siteGroup.siteGroupID);
                          }}
                        >
                          <p className="ListofText">{siteGroup.groupTitle}</p>
                          <div>
                            <img
                              src={DeleteQc}
                              id={siteGroup.siteGroupID}
                              title="Delete site group"
                              alt="Delete"
                              onClick={(e) => onDeleteSiteGroup(e, siteGroup)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {!filteredSiteGroups && selectedSiteTypeList == "Site Group" && (
                    <div className="no-data-found">No Site Groups Available.</div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
}