import { useState, useEffect } from 'react';
import { GetUserProfileInfo, GetUserProfileInfoDotNet } from '../services/userManagementservice';
import { LogoutDotNet } from '../../src/services/userAuthServices'
import { useHistory } from 'react-router-dom'
import { logOutMain } from '../utils/utils';

export const useProfile = () => {
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    const [profile, setProfile] = useState([]);
    let history = useHistory()

    useEffect(() => {
        console.log("Getting Called");
         getprofiledata();
    }, []);

    const getprofiledata = async () => {
        await GetUserProfileInfoDotNet().then((resp) => {
            console.log("resp", resp);
            if(resp?.message == "Unauthorized"){
                let tempUser = JSON.parse(localStorage.getItem('user'))
                localStorage.setItem('user', JSON.stringify(tempUser))
                LogoutDotNet().then((res) => {
                    console.log("Logged Out")
                    logOutMain()
                    // localStorage.removeItem('authToken');
                    // localStorage.removeItem('authCode');
                    // localStorage.removeItem('user');
                    // localStorage.removeItem('userPassword');
                    // localStorage.removeItem('userID');
                    // localStorage.removeItem('UserID');
                    // localStorage.removeItem('sessionExpired');
                    // localStorage.removeItem('hootWayData');
                    // sessionStorage.removeItem('Page_Refresh_State');
                    // localStorage.removeItem('PlaySound');
                    history.push('/login');
                })
            }else{
                setProfile(resp?.Data);
                localStorage.setItem('user', JSON.stringify(resp.Data))
            }
        })
    }

    return { profile, getprofiledata }
}

