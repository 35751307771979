import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../ProvideAuth'
import { loginDotNet, updateFCMDetails } from '../../../services/userAuthServices'
import '../../../app.css'
import { IsEmailValid } from '../../../utils/validations'
import { mixpanel } from '../../../utils/MixPanelUtil'
import { ABOUTTERMS, HOMEPAGE, TERMSANDCONDITION } from '../../CONSTANTS'
import { ConvertDate } from '../../../utils/utils'
import { Eye, LoginImage, EyeMask, MonitoringHubLogo } from '../../../assets/images'
import { ToastContainer } from 'react-toastify'
import { GetUserProfileInfoDotNetTemp } from '../../../services/userManagementservice'

export const Login = ({ match }) => {
    //#region useState start
    const [email, setEmail] = useState('') // to remove in the end
    const [password, setPassword] = useState('')// to remove in the end
    const [passwordShown, setPasswordShown] = useState(false);
    const [greetingMessage, setGreetingMessage] = useState('Hello')
    const [error, setError] = useState(null)
    const [commonError, setCommonError] = useState(null)
    //#endregion useState end

    //#region react Hooks 
    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();


    //#endregion react Hooks

    const onForgotPassword = () => {

        history.push(`/forgetpassword/${JSON.stringify(email.trim())}`)
    }

    const SetEvents = (user) => {
        try {
            var obj = { "UserRoleID": user.UserRoleID }
            mixpanel.people.set(JSON.stringify(obj));

            mixpanel.identify(user.UserID.toString());
            mixpanel.track("Clicks on Sign In")
        } catch (error) {
            console.error(error)
        }
    }
    const login = async () => {
        if (email.trim() === '' && password.trim() === '') {
            setError(null)
            setCommonError(null)
            setCommonError('Please enter email and password')
            return
        }

        if (email.trim() === '') {
            setCommonError(null)
            setError('Please enter email')
            return
        }

        if (!IsEmailValid(email)) {
            setCommonError(null)
            setError('Please enter valid Email Id.')
            return
        }

        if (password.trim() === '') {
            setCommonError(null)
            setError('Please enter password')
            return
        }

        let userId;
        let token;
        let myCurrentDatetime;

        loginDotNet(email, password)
            .then(async (resp) => {
                if (resp.Status === "ok") {
                    console.log("Login Response", resp);
                    localStorage.setItem('authToken', resp.Message.split("##")[0]);
                    localStorage.setItem('authCode', resp.Message.split("##")[1]);
                    localStorage.setItem('UserID', resp.Message.split("##")[2]);
                    localStorage.setItem('userPassword', password);
                    
                    let themeRes = await GetUserProfileInfoDotNetTemp(resp.Message.split("##")[1], resp.Message.split("##")[0])

                    localStorage.setItem('theme', themeRes.Data.BackgroundTheme == 0 ? 'darkTheme' : 'lightTheme');
                    localStorage.setItem('PlaySound', true);

                    sessionStorage.removeItem('Page_Refresh_State');
                    userId = parseInt(resp.Message.split("##")[2]);

                    // const termsAndCondtionData = {
                    //     termsContainerAfteLogin: "termsContainerAfteLogin",
                    //     height: "100vh",
                    //     ReportAccordionMainContainer: false,
                    //     NameHeading1: false
                    // };
                    const { EulaAcceptanceFlag } = themeRes?.Data;
                    let { from } = location.state || { from: { pathname: EulaAcceptanceFlag === null || !EulaAcceptanceFlag ? TERMSANDCONDITION : HOMEPAGE } }  // location.state || { from: { pathname: HOMEPAGE } };

                    history.replace(from);

                    token = await auth.getFCMToken()
                    console.log("Firebase Login token", token);
                    myCurrentDatetime = new Date();
                    let datetime = ConvertDate(myCurrentDatetime)
                    console.log("Firebase Login Datetime", datetime);

                    /* -------------------- Block for hitting FCM Details API ------------------- */
                    console.log("Firebase Login", userId, token, myCurrentDatetime);
                    updateFCMDetails(userId, token, myCurrentDatetime).then((res) => {
                        if (res.status === "Ok") {
                            console.log("Firebase Login", res.message);
                        } else {
                            console.log("Firebase Login", res.message);
                        }
                    })
                } else if (resp.Status === "failed") {
                    setCommonError(null)
                    if (resp.Message === "email not verified") {
                        history.push(`/verification/${JSON.stringify(email.trim())}`)
                    } else {
                        setError(resp.Message);
                    }
                }
            })
            .catch((err) => {
                setCommonError("Unable to Login.")
                return;
            });
    };

    //#region useEffect start
    useEffect(() => {
        // auth.user != null && history.push(DASHBOARD) 
        getGreetingTime()
    }, [])

    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        if (loggedInUser && window.location.pathname === '/login') {
            // history.push(ABOUTTERMS);
            history.push(HOMEPAGE);
        }
    }, [loggedInUser, history]);

    //#endregion useEffect end
    /**
     * *
     * Refer for the method logic
     * http://jsfiddle.net/DerekL/we8Ty/
     */
    const getGreetingTime = () => {
        try {
            let data = [
                [0, 11, "Good morning"],
                [12, 16, "Good afternoon"],
                [17, 24, "Good evening"]
            ]
            let hr = new Date().getHours();

            for (var i = 0; i < data.length; i++) {
                if (hr >= data[i][0] && hr <= data[i][1]) {
                    setGreetingMessage(data[i][2])
                    break;
                }
            }
        } catch (error) {
            setGreetingMessage('Hello')
            console.error(error)
        }

    }
    return (
        <>
            <div className="container-fluid forgot-pass-container" style={{ backgroundColor: "#fff" }}>
                <div className="row forgot-pass-row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: "#F6F8FC" }}>
                        <div className="imageWhiteBg">
                            <MonitoringHubLogo height={"70px"} />
                            {/* <img src={logo} className="mt-4" /> */}
                        </div>
                        <div className="BigImage">
                            <img src={LoginImage} />
                        </div>
                    </div>
                    <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2" style={{ backgroundColor: "#fff" }}>
                        <div className="WhiteFormSignIn">
                            <div className="Content">
                                <p className="GM_msg">{greetingMessage} !<br />
                                    Hope you are having a good day</p>
                                <div className="row" hidden>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Operator</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Admin</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Root</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                </div>

                                <div className="inputMainContainer w25dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                        <label className="loginLabels" htmlFor="email">Email ID</label>
                                        <input
                                            className="inputLoginType"
                                            id="email"
                                            type="email"
                                            placeholder="Ex. info@intellve.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyUp={(e) => { if (e.code === 'Enter') login() }}
                                        />
                                    </div>
                                </div>

                                <div className="inputMainContainer w25dot75rem mt30">
                                    <div className="inputTypeLabelContainer">
                                        <label className="loginLabels" htmlFor="password">Password</label>
                                        {/* <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}> */}
                                        <input
                                            className="inputTypePassword"
                                            id="password"
                                            type={passwordShown ? "text" : "password"}
                                            placeholder="Ex. abc@123"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyUp={(e) => { if (e.code === 'Enter') login() }}
                                        />
                                        {/* </OverlayTrigger> */}

                                        <div className="EyeIconDiv">
                                            {!passwordShown ? (
                                                <img
                                                    className="EyeIcon"
                                                    src={Eye}
                                                    onClick={() => setPasswordShown(!passwordShown)}
                                                />
                                            ) : (
                                                <EyeMask
                                                    height={18}
                                                    width={18}
                                                    onClick={() => setPasswordShown(!passwordShown)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ height: "10px" }}>
                                    <p className="Error_P">
                                        {error && (
                                            <>
                                                <span>{error}</span>
                                            </>
                                        )}

                                        {commonError && (
                                            <>
                                                <span>{commonError}</span>
                                            </>
                                        )}
                                    </p>
                                </div>

                                <p className="ForgotPassword"
                                    onClick={onForgotPassword}
                                >
                                    Forgot Password?
                                </p>
                                <button className="ResetPasswordButton w25dot75rem" onClick={login}>
                                    <span className="Manage-Configuration Sign-In">Sign In</span>
                                </button>

                                <p className="Intellve_customer">
                                    By continuing, you agree to the Intellve customer{" "}
                                    <a href=""> agreement </a> and the{" "}
                                    <a href=""> privacy notice</a>.
                                </p>
                                <p className="Account" hidden>Don't have an account yet?
                                    <span className="Sign-up"><a href="/signup">Sign up</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
        </>
    )
}
