import { BASE_CORE_URL, BASE_URL } from './../config/index'

//#region  to get the Alert Report Data
export const getAlertActivityReport = async (obj) => {
    let url = `${BASE_URL}alertreport`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion 

//#region  to get the Disarm Alert Report Data
export const getAlertArmDisarmReport = async (obj) => {

    let url = `${BASE_URL}alertreport`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region  to get the Even report Data 
export const getStatusChangeReport = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "Report/GetReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region  to get the Health Event report to export
export const getExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "Report/GetReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region

export const getEventReport = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "EventReport/GetEventReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}


export const getEventExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "EventReport/GetEventReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" + searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion