import React from 'react'

export default function ActivityReportTable({ props }) {
    return <>
    <tr>
      <td>{props.alertId}</td>
      <td className="ReportTableFontBold">{props.siteName}</td>
      <td>{props.alertSource}</td>
      <td>{props.alertType}</td>
      <td className={`ReportTableAlertSeverity ${props.severityTitle.toLowerCase()}`}>{props.severityTitle}</td>
      <td>{props.actualTime} {props.abbreviation? "("+props.abbreviation+")":""}</td>
      <td>{props.ackOn} {props.abbreviation?"("+props.abbreviation+")":""}</td>
      <td>{props.operatorName}</td>
      <td className="ReportTableFontBold">{props.workflowName}</td>
      <td>{props.closedOn} {props.abbreviation?"("+props.abbreviation+")":""}</td>
      <td className="ReportTableFontBold">{props.timeToCloseAlert && props.timeToCloseAlert == 'NA' ? 'NA' : props.timeToCloseAlert} </td>
    </tr>
  </>
}
