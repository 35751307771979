import React from 'react'

export default function AlertTable({ props, onAddAlertChat }) {
    return (
        // Dashboard alert table
        <tr onClick={(e) => {
            if (e.detail != 1) {
                return;
            } else {
                onAddAlertChat(props.alertId).then((res) => {
                })
            }
        }}
            style={{ cursor: 'pointer' }}
        >
            {<td>{props.alertId}</td>}
            {<td>{props.customerName}</td>}
            {<td>{props.siteName}</td>}
            {<td>{props.deviceName}</td>}
            {<td>{props.ackbyName ? props.ackbyName : "--"}</td>}
            {/* {<td>{props.ackOn ? props.ackOn : "--"}</td>} */}
            {<td> {props.ackOn ? props.ackOn + (props.abbreviation ? " (" + props.abbreviation + ")" : "") : "--" }</td>}
            {/* {<td>{props.closedOn ? props.closedOn : "--"}</td>} */}
            {<td> {props.closedOn ? props.closedOn + (props.abbreviation ? " (" + props.abbreviation + ")" : "") : "--" }</td>}
            {props.status && props.status == "Open" &&
                <td><span className="StatusBtn StatusBtn_open">{props.status}</span></td>
            }
            {props.status && props.status == "Closed" &&
                <td><span className="StatusBtn StatusBtn_closed">{props.status}</span></td>
            }

            {props.severity && props.severity == "Extreme" &&
                <td><span className="StatusBtn extreme_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "High" &&
                <td><span className="StatusBtn high_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "Moderate" &&
                <td><span className="StatusBtn medium_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "Low" &&
                <td><span className="StatusBtn low_colour">{props.severity}</span></td>
            }
            {<td>{props.alertType}</td>}
        </tr>
    )
}
