import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

import { BackArrowDevice } from '../../../../assets/images'

import { Row, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Papa from 'papaparse';
import { CSVLink } from "react-csv";

import SelectHeaderCommon from './SelectHeaderCommon';
import SelectHeaderEmpty from './SelectHeaderEmpty';
import { MULTI_TIME_ZONE } from '../../../../config';

const PanelBulkUpload = React.forwardRef((props, ref) => {
    let {
        selectedHeaders,
        onPanelBulkUpload,
        setSelectedHeaders,
        clearImportedPanelHeaders,
        setImportedFileData,
        panelBulkUploadResponse,
        setPanelBulkUploadResponse,
        panelBulkUploadError,
        setPanelBulkUploadError,
        setBulkUpload,
        setIsSingleOrBulkSelection,
        importedPanelHeaders,
        setImportedPanelHeaders
    } = { ...props };

    const { register, handleSubmit } = useForm({
        defaultValue: { ...selectedHeaders },
    });

    const hiddenPanelFileInput = React.useRef(null);

    //#region useState start
    const [defaultBrand, setDefaultBrand] = useState(null);
    const [defaultIp, setDefaultIp] = useState(null);
    const [defaultBranch, setDefaultBranch] = useState(null);
    const [defaultPanelName, setDefaultPanelName] = useState(null);
    const [defaultZoneType, setDefaultZoneType] = useState(null);
    const [defaultZoneNumber, setDefaultZoneNumber] = useState(null);
    const [defaultZoneName, setDefaultZoneName] = useState(null);
    const [defaultPortNumber, setDefaultPortNumber] = useState(null);
    const [defaultInterfaceUsername, setDefaultInterfaceUsername] = useState(null);
    const [defaultInterfacePassword, setDefaultInterfacePassword] = useState(null);
    const [defaultTimezone, setDefaultTimezone] = useState(null);
    const [totalDevices, setTotalDevices] = useState(null);
    const [failedDevices, setFailedDevices] = useState(null);
    const [successDevices, setSuccessDevices] = useState(null);
    const [panelImportedFileName, setPanelImportedFileName] = useState(null)
    const [faildListToDownload, setFaildListToDownload] = useState(null)
    const [sucessListToDownload, setSucessListToDownload] = useState(null)
    const [errorListToDownload, setErrorListToDownload] = useState(null)

    //#endregion 

    //#region useEffect start
    useEffect(() => {
        if (importedPanelHeaders) {
            var brand = importedPanelHeaders.find((x) => x == "brand");
            if (brand) {
                setDefaultBrand(brand);
            } else {
                brand = null;
            }

            var ip = importedPanelHeaders.find((x) => x == "ipaddress");
            if (ip) {
                setDefaultIp(ip);
            } else {
                ip = null;
            }

            var branch = importedPanelHeaders.find((x) => x == "branch");
            if (branch) {
                setDefaultBranch(branch);
            } else {
                branch = null;
            }

            var name = importedPanelHeaders.find((x) => x == "name");
            if (branch) {
                setDefaultPanelName(name);
            } else {
                name = null;
            }

            var zonetype = importedPanelHeaders.find((x) => x == "zonetype");
            if (zonetype) {
                setDefaultZoneType(zonetype);
            } else {
                zonetype = null;
            }

            var zonenumber = importedPanelHeaders.find((x) => x == "zonenumber");
            if (zonenumber) {
                setDefaultZoneNumber(zonenumber);
            } else {
                zonenumber = null;
            }

            var zonename = importedPanelHeaders.find((x) => x == "zonename");
            if (zonename) {
                setDefaultZoneName(zonename);
            } else {
                zonename = null;
            }
            var portnumber = importedPanelHeaders.find((x) => x == "portnumber");
            if (portnumber) {
                setDefaultPortNumber(portnumber);
            } else {
                portnumber = null;
            }

            var interfaceusername = importedPanelHeaders.find((x) => x == "interfaceusername");
            if (interfaceusername) {
                setDefaultInterfaceUsername(interfaceusername);
            } else {
                interfaceusername = null;
            }

            var interfacepassword = importedPanelHeaders.find((x) => x == "interfacepassword");
            if (interfacepassword) {
                setDefaultInterfacePassword(interfacepassword);
            } else {
                interfacepassword = null;
            }

            var timezone = importedPanelHeaders.find((x) => x == "timezone");
            if(timezone){
                setDefaultTimezone(timezone);
            }else{
                timezone = null;
            }

            let panelHeadObj = {
                brand: brand,
                ipaddress: ip,
                branch: branch,
                name: name,
                zonetype: zonetype,
                zonenumber: zonenumber,
                zonename: zonename,
                portnumber: portnumber,
                interfaceusername: interfaceusername,
                interfacepassword: interfacepassword,
            };
            if(MULTI_TIME_ZONE){
                panelHeadObj.timezone = timezone
            }
            setSelectedHeaders(panelHeadObj);
        }
    }, [importedPanelHeaders]);

    useEffect(() => {
        setImportedPanelHeaders(null);
        hiddenPanelFileInput.current.value = null;
    }, [clearImportedPanelHeaders]);

    useEffect(() => {
        if (panelBulkUploadResponse) {
            const panelFailedLst = panelBulkUploadResponse.CameraFailedList;

            if (panelFailedLst && panelFailedLst.length > 0) {
                let failedList = []
                // if (sensorFailedLst.length > 0) failedList = failedList.concat(sensorFailedLst)
                if (panelFailedLst.length > 0) {
                    for (let i = 0; i < panelFailedLst.length; i++) {
                        // for (let j = 0; j < panelFailedLst[i].sensorLst.length; j++) {
                        let temp = {};

                        temp['branch'] = panelFailedLst[i]['branch']
                        temp['brand'] = panelFailedLst[i]['brand']
                        temp['deviceID'] = panelFailedLst[i]['deviceID']
                        temp['message'] = panelFailedLst[i]['message']
                        // temp['panelIp'] = panelFailedLst[i]['panelIp']  
                        temp['panelIp'] = panelFailedLst[i]['ipAddress']
                        // temp['panelName'] = panelFailedLst[i]['panelName']
                        temp['panelName'] = panelFailedLst[i]['name']
                        temp['status'] = panelFailedLst[i]['status']
                        /*  temp['sensorLst'] = {
                           'zoneName': panelFailedLst[i].sensorLst[j]['zoneName'],
                           'zoneNumber': panelFailedLst[i].sensorLst[j]['zoneNumber'],
                           'zoneType': panelFailedLst[i].sensorLst[j]['zoneType']
                         } */
                        temp['zoneName'] = panelFailedLst[i]['zoneName']
                        temp['zoneNumber'] = panelFailedLst[i]['zoneNumber']
                        temp['zoneType'] = panelFailedLst[i]['zoneType']
                        // temp['interfaceName'] = panelFailedLst[i]['interfaceName']
                        temp['portNumber'] = panelFailedLst[i]['portNumber']
                        temp['interfaceUsername'] = panelFailedLst[i]['interfaceUsername']
                        temp['interfacePassword'] = panelFailedLst[i]['interfacePassword']
                        if(MULTI_TIME_ZONE){
                            temp['timezone'] = panelFailedLst[i]['timezone']
                        }
                        failedList.push(temp)
                        // }
                    }
                }
                var failedDevList = failedList.length;
                if (failedDevList && failedDevList !== undefined) {
                    setFailedDevices(failedDevList)
                }

                const newList = []
                if(MULTI_TIME_ZONE){
                    newList.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", "portnumber", "interfaceusername", "interfacepassword", "timezone",
                    /* "interfacename",  */
                    "errorcause"])
                }else{
                    newList.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", "portnumber", "interfaceusername", "interfacepassword", 
                    /* "interfacename",  */
                    "errorcause"])
                }
                for (let i = 0; i < failedList.length; i++) {
                    const element = failedList[i];
                    // newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element['sensorLst']["zoneType"], element['sensorLst']["zoneName"], element['sensorLst']["zoneNumber"], element['interfaceName'], element["message"]])
                    if(MULTI_TIME_ZONE){
                        newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'], */
                        element["portNumber"], element["interfaceuserName"], element["interfacePassword"],element["timezone"],
                        element["message"]])    
                    }else{
                        newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'], */
                        element["portNumber"], element["interfaceuserName"], element["interfacePassword"],
                        element["message"]])

                    }
                }

                if (newList && newList.length > 1) {
                    setFaildListToDownload(newList)
                }
            } //end

            /* success starts */

            // RUPESH PANEL BULK UPLOAD SUCCESS RESPONSE

            /* -------------- To be commented for bulk upload success data -------------- */

            // const sensorSuccessList = panelBulkUploadResponse['sensorSuccessList']
            // const panelSuccessList = panelBulkUploadResponse['panelSuccessList']
            // let successListTemp = [];

            // if ((sensorSuccessList && sensorSuccessList.length > 0) || (panelSuccessList && panelSuccessList.length > 0)) {
            //   if(sensorSuccessList.length > 0){
            //     successListTemp = successListTemp.concat(sensorSuccessList);
            //   }
            //   if(panelSuccessList.length > 0){
            //     for(let i=0; i<panelSuccessList.length; i++){
            //       for(let j=0; j<panelSuccessList[i].sensorLst.length; i++){
            //         let temp = {}
            //         temp['branch'] = panelSuccessList[i]['branch']
            //         temp['brand'] = panelSuccessList[i]['brand']
            //         temp['deviceID'] = panelSuccessList[i]['deviceID']
            //         temp['message'] = panelSuccessList[i]['message']
            //         temp['panelIp'] = panelSuccessList[i]['panelIp']
            //         temp['panelName'] = panelSuccessList[i]['panelName']
            //         temp['status'] = panelSuccessList[i]['status']
            //         temp['sensorLst'] = {
            //           'zoneName': panelSuccessList[i].sensorLst[j]['zoneName'],
            //           'zoneNumber': panelSuccessList[i].sensorLst[j]['zoneNumber'],
            //           'zoneType': panelSuccessList[i].sensorLst[j]['zoneType']
            //         }
            //         successListTemp.push(temp)
            //       }
            //     }
            //   }
            // }
            // let successDevList = successListTemp.length;
            // var successDevList = panelBulkUploadResponse && panelBulkUploadResponse['sensorSuccessList'] && panelBulkUploadResponse['sensorSuccessList'].length


            const panelSuccessList = panelBulkUploadResponse.CameraSuccessList

            if (panelSuccessList && panelSuccessList.length > 0) {
                let successList = []

                if (panelSuccessList.length > 0) {
                    for (let i = 0; i < panelSuccessList.length; i++) {
                        let temp = {}
                        temp['branch'] = panelSuccessList[i]['branch']
                        temp['brand'] = panelSuccessList[i]['brand']
                        temp['deviceID'] = panelSuccessList[i]['deviceID']
                        temp['message'] = panelSuccessList[i]['message']
                        temp['panelIp'] = panelSuccessList[i]['ipAddress']
                        temp['panelName'] = panelSuccessList[i]['name']
                        temp['status'] = panelSuccessList[i]['status']
                        temp['zoneName'] = panelSuccessList[i]['zoneName']
                        temp['zoneNumber'] = panelSuccessList[i]['zoneNumber']
                        temp['zoneType'] = panelSuccessList[i]['zoneType']
                        // temp['interfaceName'] = panelSuccessList[i]['interfaceName']
                        temp['portNumber'] = panelSuccessList[i]['portNumber']
                        temp['interfaceUsername'] = panelSuccessList[i]['interfaceUsername']
                        temp['interfacePassword'] = panelSuccessList[i]['interfacePassword']
                        temp['timezone'] = panelSuccessList[i]['timezone']
                        successList.push(temp);
                    }
                }
                var successDevList = successList.length;

                if (successDevList && successDevList !== undefined) {
                    setSuccessDevices(successDevList)
                }

                const newList2 = []
                if(MULTI_TIME_ZONE){
                    newList2.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "timezone", "reason"])
                }else{
                    newList2.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "reason"])
                }
                for (let i = 0; i < panelSuccessList.length; i++) {
                    const element = panelSuccessList[i];
                    if(MULTI_TIME_ZONE){
                        newList2.push([element["brand"], element["ipAddress"], element["branch"], element["name"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'],  */
                        element["portNumber"], element["interfaceUsername"], element["interfacePassword"], element["timezone"],
                        element["message"]])
                    }else{
                        newList2.push([element["brand"], element["ipAddress"], element["branch"], element["name"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'],  */
                        element["portNumber"], element["interfaceUsername"], element["interfacePassword"],
                        element["message"]])    
                    }
                }

                if (newList2 && newList2.length > 1) {
                    setSucessListToDownload(newList2)
                }
            }

            /* success ends */
        }
    }, [panelBulkUploadResponse]);

    useEffect(() => {
        const fetchData = async () => {
            if (panelBulkUploadError.length > 0) {
                let newList;
                if(MULTI_TIME_ZONE){
                    newList = [["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "timezone", "errorcause"]];
                }else{
                    newList = [["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "errorcause"]];
                }
                for (let i = 0; i < panelBulkUploadError.length; i++) {
                    const element = panelBulkUploadError[i];
                    newList.push(element);
                }
                if (newList && newList.length >= 1) {
                    setErrorListToDownload(newList);
                }
            }
        };

        fetchData();
    }, [panelBulkUploadError]);
    //#endregion useEffect end

    const handleUploadCSVClick = (event) => {
        event.preventDefault()
        setErrorListToDownload(null)
        hiddenPanelFileInput.current.click();
    };

    const handleUploaderChange = (event) => {
        event.preventDefault()
        setImportedFileData(null);

        Array.from(event.target.files)
            .filter(
                (file) =>
                    file.type === "text/csv" || file.type === "application/vnd.ms-excel"
            )
            .forEach(async (file) => {
                let panelHeadObj = {
                    brand: null,
                    ipaddress: null,
                    branch: null,
                    zonetype: null,
                    zonenumber: null,
                    zonename: null,
                    // interfacename: null
                    portnumber: null,
                    interfaceusername: null,
                    interfacepassword: null,
                };
                if(MULTI_TIME_ZONE){
                    panelHeadObj.timezone= null;
                }
                setSelectedHeaders(panelHeadObj);
                const text = await file.text();
                //*********tej 27-02-2023********* */
                // parse the CSV data into an array of arrays using PapaParse
                const parsedData = Papa.parse(text).data;
                // filter out empty or comma-separated rows from the parsed data
                const result = parsedData.filter(row => {
                    return row.join('').trim() !== '';
                });
                // format the filtered data back into CSV format using PapaParse
                // const filteredCsv = Papa.unparse(filteredData);
                //****************** */
                setTotalDevices(null)
                setPanelImportedFileName(null)
                setImportedPanelHeaders(null)
                setImportedFileData(null)
                setFailedDevices(null)
                setSuccessDevices(null)

                // const text = await file.text();
                // const result = parse(text, { header: true });
                const actualData = result.slice(1, result.length);
                setTotalDevices(actualData.length)
                setPanelImportedFileName(file['name'])
                setPanelBulkUploadResponse([])
                setPanelBulkUploadError([])
                if (result && actualData) {
                    setImportedPanelHeaders(result[0]);
                    const dataToUpload = actualData;
                    setImportedFileData(dataToUpload);
                }
            });
    };

    const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");

    return (
        <>
            <div>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Go to back
                        </Tooltip>
                    }
                >
                    <button className="DataAccessEdit" onClick={() => {
                        setBulkUpload(false);
                        setIsSingleOrBulkSelection("single_device");
                    }}>
                        <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
                    </button>
                </OverlayTrigger>
            </div>
            <div style={{ width: "75%", margin: "0 auto" }}>
                <div className="bulk-upload-msg-text" style={{ textAlign: "right", margin: "0px" }}>Click <a
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/PanelBulkUploadSample.csv"
                    href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Panel-Bulk-Upload-SampleFile.csv"
                    download
                >
                    here
                </a> to download template</div>
                <div className="BulkUploadBrowseDiv">
                    <input
                        className="BulkUploadInput"
                        value={panelImportedFileName}
                        disabled={true}
                    />
                    <button className="DataAccessEdit" style={{ width: "100px" }} onClick={handleUploadCSVClick}>
                        <span className="ViewUpdate">Browse</span>
                    </button>
                </div>
            </div>
            <div style={{ marginTop: "15px" }}>
                <div className="DeviceList">
                    <form
                        id="hook-form"
                        onSubmit={handleSubmit((e) =>
                            onPanelBulkUpload(e, importedPanelHeaders)
                        )}
                    >
                        <div className="DeviceTextBox DeviceListBox">
                            <Row className="header-width" style={{ margin: "0 auto" }}>
                                <div className="Add_Devices add-devices-heading">
                                    <p>Match the headers</p>
                                </div>
                            </Row>
                            <div className="lst-section-width">
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Brand Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultBrand && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultBrand={setDefaultBrand}
                                                label="brand"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultBrand && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultBrand}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="brand"
                                                setDefaultBrand={setDefaultBrand}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            IP Address<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultIp && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultIp={setDefaultIp}
                                                label="ipaddress"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultIp && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultIp}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="ipaddress"
                                                setDefaultIp={setDefaultIp}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Branch<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultBranch && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultBranch={setDefaultBranch}
                                                label="branch"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultBranch && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultBranch}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="branch"
                                                setDefaultBranch={setDefaultBranch}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Panel Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultPanelName && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultPanelName={setDefaultPanelName}
                                                label="branch"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultPanelName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultPanelName}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="branch"
                                                setDefaultPanelName={setDefaultPanelName}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Zone Type<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultZoneType && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultZoneType={setDefaultZoneType}
                                                label="zonetype"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultZoneType && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultZoneType}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="zonetype"
                                                setDefaultZoneType={setDefaultZoneType}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Zone Number<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultZoneNumber && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultZoneNumber={setDefaultZoneNumber}
                                                label="zonenumber"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultZoneNumber && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultZoneNumber}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="zonenumber"
                                                setDefaultZoneNumber={setDefaultZoneNumber}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Zone Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultZoneName && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultZoneName={setDefaultZoneName}
                                                label="zonename"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultZoneName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultZoneName}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="zonename"
                                                setDefaultZoneName={setDefaultZoneName}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Port Number<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultPortNumber && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultPortNumber={setDefaultPortNumber}
                                                label="portnumber"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultZoneName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultPortNumber}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="portnumber"
                                                setDefaultPortNumber={setDefaultPortNumber}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Interface Username<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultInterfaceUsername && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultInterfaceUsername={setDefaultInterfaceUsername}
                                                label="interfaceusername"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultInterfaceUsername && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultInterfaceUsername}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="interfaceusername"
                                                setDefaultInterfaceUsername={setDefaultInterfaceUsername}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Interface Password<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultInterfacePassword && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultInterfacePassword={setDefaultInterfacePassword}
                                                label="interfacepassowrd"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultInterfacePassword && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultInterfacePassword}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="interfacepassowrd"
                                                setDefaultInterfacePassword={setDefaultInterfacePassword}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                {
                                    MULTI_TIME_ZONE
                                    ?
                                    <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Timezone<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultTimezone && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultTimezone={setDefaultTimezone}
                                                label="timezone"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultTimezone && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultTimezone}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="timezone"
                                                setDefaultTimezone={setDefaultTimezone}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                    </Row>
                                    : null
                                }

                            </div>
                            <div
                                className="BulkText1 AlignBulk"
                            >
                                <input
                                    type="file"
                                    multiple={false}
                                    ref={hiddenPanelFileInput}
                                    onChange={(e) => handleUploaderChange(e)}
                                    style={{ display: "none" }}
                                    value=""
                                />
                            </div>

                            <p style={{ textAlign: "center" }}>

                                {

                                    panelImportedFileName && totalDevices && panelBulkUploadResponse &&
                                    !(panelBulkUploadResponse['CameraSuccessList'] || panelBulkUploadResponse['CameraFailedList']) &&
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip>{panelImportedFileName}</Tooltip>}>
                                        <span className="bulk-upload-msg-text">({totalDevices} Devices)</span>
                                    </OverlayTrigger>
                                }

                                {panelBulkUploadResponse && failedDevices && failedDevices != 0 && totalDevices &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Panels Failed List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={faildListToDownload}>{failedDevices} /
                                            {totalDevices} Failed Uploads</CSVLink> </span>

                                }


                                {panelBulkUploadError && panelImportedFileName && errorListToDownload &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Panels Error List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={errorListToDownload}>Error List</CSVLink> </span>
                                }

                                {panelBulkUploadResponse && successDevices && successDevices != 0 && totalDevices &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Panels Success List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={sucessListToDownload}>{successDevices} /
                                            {totalDevices} Uploads Successful</CSVLink> </span>
                                }

                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
});

export default PanelBulkUpload;